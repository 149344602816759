import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:['pa-0', { darkmode: _vm.isDarkMode }],attrs:{"fluid":""}},[_c(VRow,{staticClass:"header ma-0 px-8 py-4",attrs:{"justify":"end"}},[_c('custom-button',{attrs:{"label":"Enviar"},on:{"click":function($event){return _vm.openModalsFn()}}})],1),_c(VDataTable,{staticClass:"pa-8",attrs:{"show-select":"","disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","items":_vm.items},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(group){return [_c('tr',{key:group.description,staticClass:"group-row"},[_c('td',{staticClass:"text-left pr-0"},[_c('div',{staticClass:"m-checkbox-inline"},[_c('label',{staticClass:"m-checkbox m-checkbox--success",staticStyle:{"margin-bottom":"0px","color":"white"}},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedGroups.indexOf(group) > -1},on:{"change":function($event){return _vm.checkGroup(group)}}}),_c('strong',[_vm._v(_vm._s(group.description))]),_c('span',{staticStyle:{"height":"15px","width":"15px","background-color":"white"}})])])]),_c('td',{staticClass:"text-right text-no-wrap pl-0"},[_c('strong',[_vm._v(_vm._s(_vm.selectedGroupValue(group.description)))])]),_c('td',[_c('strong',[_vm._v("Vencimento")])]),_c('td',[_c('strong',[_vm._v("Portador")])]),_c('td',[_c('strong',[_vm._v("Status")])]),_c('td',[_c('strong',[_vm._v("Ações")])])]),_vm._l((group.items),function(item){return _c('tr',{key:item.description},[_c('td',{staticClass:"text-left pr-0"},[_c('div',{staticClass:"m-checkbox-inline"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({class:[
                        'm-checkbox',
                        'm-checkbox--success',
                        { 'label-disabled': _vm.isDisabled(item) } ],staticStyle:{"margin-bottom":"0px"}},'label',attrs,false),on),[_c('input',{attrs:{"type":"checkbox","disabled":_vm.isDisabled(item)},domProps:{"checked":_vm.isItemSelected(item, group)},on:{"change":function($event){return _vm.checkItem(item, group)}}}),_vm._v(" "+_vm._s(item.fantasyName)+" - "+_vm._s(item.companyName)+" - "+_vm._s(item.prefixCode)+" - "+_vm._s(item.documentNumber)+" "+_vm._s(item.quoteNumber ? (" - " + (item.quoteNumber)) : '')+" - "+_vm._s(item.documentType)+" "),_c('span',{staticStyle:{"height":"15px","width":"15px","background-color":"white"}})])]}}],null,true)},[(_vm.billingApproval && item.isLiberated === 'false')?_c('span',[_vm._v(" Esse item exige liberação prévia para ser enviado! ")]):_c('span',[_vm._v(" Razão Social: "+_vm._s(item.companyName)+" - CNPJ: "+_vm._s(_vm._f("VMask")(item.registrationNumber,_vm.$getConst('MASK_CNPJ')))+" ")])])],1)]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.balanceValue)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.fluxDate)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.bankCode))]),_c('td',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":_vm.iconValue(item.bankingSituationDescription, 'color')},on:{"click":function($event){return _vm.showOccurrencesModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.iconValue(item.bankingSituationDescription, 'icon'))+" ")]),(_vm.showOccurrencesIcon(item))?_c(VIcon,_vm._g(_vm._b({staticClass:"occurrencesIcon",attrs:{"color":"warning"},on:{"click":function($event){return _vm.showOccurrencesModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.iconValue(item.bankingSituationDescription, 'title')))])])],1),_c('td',[_c('item-menu',{attrs:{"item":item,"showOccurrencesModal":_vm.showOccurrencesModal},on:{"showDetailsModal":function($event){return _vm.showDetailsModal(item)}}})],1)])})]}),_c('tr',{staticClass:"group-row"},[_c('td',{staticClass:"text-left"},[_vm._v(" Total Geral ("+_vm._s(_vm.totalItems)+" título"+_vm._s(_vm.checkPlural(_vm.totalItems))+") ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.totalItemsValue)))]),_c('td',{attrs:{"colspan":"4"}})]),_c('tr',{staticClass:"group-row"},[_c('td',{staticClass:"text-left"},[_vm._v(" Total Selecionado ("+_vm._s(_vm.selectedItems.length)+" título"+_vm._s(_vm.checkPlural(_vm.selectedItems.length))+") ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.totalSelectedItemsValue)))]),_c('td',{attrs:{"colspan":"4"}})])],2)]}}])}),_c('generate-cnab',{ref:"GenerateCnab",on:{"success":_vm.onSuccess}}),_c('occurrences-modal',{attrs:{"show":_vm.occurrencesModalVisibility,"item":_vm.selectedItem},on:{"close":_vm.closeOccurrencesModal}}),_c('view-information',{ref:"ViewInformation"}),_c('details-modal',{attrs:{"show":_vm.detailsModalVisibility,"item":_vm.selectedItem},on:{"close":_vm.closeDetailsModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }