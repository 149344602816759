import moment from 'moment';

class GenerateXlsTemplateCashFlowRealized {

    showTotalizer = false;

    create (dates, rowsValues, typeOfDate, showTotalizer) {
        this.showTotalizer = showTotalizer;

        const xlsFileTitle = this.setFileNameWithDates(dates, typeOfDate);
        const tableTemplateString = this.generateTableStringTemplate(rowsValues, dates, showTotalizer);
        
        const dataType = 'data:application/vnd.ms-excel;';
        const aLink = document.createElement('a');

        aLink.href = `${dataType},${encodeURIComponent(tableTemplateString)}`;
        aLink.download = xlsFileTitle;
        aLink.click();
    }

    generateTableStringTemplate(rowsValues, dates) {
        return `<!DOCTYPE html>
            <html>
                <head>
                    <meta charset="UTF-8">
                    <style>
                        td.number-mode {
                            mso-number-format:"0\.000";
                        }

                        td.text-mode {
                            mso-number-format:"\@";
                        }
                    </style>
                </head>
                <body>
                    <table>
                        ${this.generateTableHeader(dates)}
                        ${this.generateTableBody(rowsValues)}
                    </table>
                </body>
            </html>
        `;
    }

    generateTableHeader(headerValues) {
        let headerTemplate = '<thead>';

        headerTemplate += '<tr>';
        headerTemplate += '<td style="text-align: center" align="CENTER"><b>Código</b></td>';
        headerTemplate += '<td><b>Descrição</b></td>';


        if (this.showTotalizer) {
            headerTemplate += '<td style="text-align: center" align="CENTER"><b>Total</b></td>';
        }

        for (let i = 0; i < headerValues.length; i += 1) {
            const { date, description } = headerValues[i];
            
            headerTemplate += '<td class="text-mode" style="text-align: center" align="CENTER">';
            headerTemplate += `${date} <br> ${description}`;
            headerTemplate += '</td>';
        }

        headerTemplate += '</tr>';
        headerTemplate += '</thead>';

        return headerTemplate;
    }

    generateTableBody(bodyValues) {
        let bodyTemplate = '<tbody>';

        bodyTemplate += this.traverseMainLevel(bodyValues);
        bodyTemplate += '</tbody>';

        return bodyTemplate;
    }

    traverseMainLevel(mainLevelValues) {
        let mainLevelRowsTemplate = '';

        for (let i = 0; i < mainLevelValues.length; i += 1) {
            const { data, data_values, description } = mainLevelValues[i];

            mainLevelRowsTemplate += this.generateBodyRow(0, '', description, data_values, true);

            if (data) {
                mainLevelRowsTemplate += this.traverseFirstLevel(data);
            }
        }

        return mainLevelRowsTemplate;
    }

    traverseFirstLevel(firstLevelValues) {
        let firstLevelRowsTemplate = '';

        for (let i = 0; i < firstLevelValues.length; i += 1) {
            const { level_one, level_two } = firstLevelValues[i];
            const {
                erp_code,
                description,
                data_values,
            } = level_one;
            
            firstLevelRowsTemplate += this.generateBodyRow(1, erp_code, description, data_values);

            if (level_two) {
                firstLevelRowsTemplate += this.traverseSecondLevel(level_two);
            }
        }

        return firstLevelRowsTemplate;
    }

    traverseSecondLevel(secondLevelValues) {
        let secondLevelRowsTemplate = '';

        for (let i = 0; i < secondLevelValues.length; i += 1) {
            const {
                erp_code,
                description,
                data_values,
                level_three
            } = secondLevelValues[i];

            secondLevelRowsTemplate += this.generateBodyRow(2, erp_code, description, data_values);

            if (level_three) {
                secondLevelRowsTemplate += this.traverseThirdLevel(erp_code, level_three);
            }
        }

        return secondLevelRowsTemplate;
    }

    traverseThirdLevel(erp_code, ThirdLevelValues) {
        let thirdLevelRowsTemplate = '';

        for (let i = 0; i < ThirdLevelValues.length; i += 1) {
            const { description, data_values, level_four, identifyValues } = ThirdLevelValues[i];
            const level = identifyValues === 'BC' ? '4' : '3';

            thirdLevelRowsTemplate += this.generateBodyRow(level, erp_code, description, data_values);

            if (level_four) {
                thirdLevelRowsTemplate += this.traverseFourthLevel(erp_code, level_four);
            }
        }

        return thirdLevelRowsTemplate;
    }

    traverseFourthLevel(erp_code, fourthLevelValues) {
        let fourthLevelRowsTemplate = '';

        for (let i = 0; i < fourthLevelValues.length; i += 1) {
            const { description, data_values } = fourthLevelValues[i];

            fourthLevelRowsTemplate += this.generateBodyRow(4, erp_code, description, data_values);
        }

        return fourthLevelRowsTemplate;
    }

    generateBodyRow(level, natureCode = '', description  = '', datePriceValues) {
        const levelIsBold = [0, 1, 2].includes(level);
        const levelColorIsWhite = level === 0;
        const backgroundColor = this.getLevelBackgroundColor(level);

        const options = {
            bold: levelIsBold,
            color: levelColorIsWhite ? 'white' : 'black'
        }

        let bodyRowTemplate = `<tr style='background-color: ${backgroundColor}'>`;

        bodyRowTemplate += '<td class="text-mode" style="text-align: center" align="CENTER" sdnum="1046;0;@">';
        bodyRowTemplate += this.generateBodyCell({...options, ...{value: natureCode}});
        bodyRowTemplate += '</td>';

        bodyRowTemplate += '<td class="text-mode" sdnum="1046;0;@">';
        bodyRowTemplate += this.generateBodyCell({...options, ...{value: description}});
        bodyRowTemplate += '</td>';

        if (this.showTotalizer) {
            const value = numeral(this.sumTotal(datePriceValues)).format("0,0.00");
            const totalizerColor = this.getLevelBackgroundTotalizerColor(level);
            const totalizerOptions = {
                bold: true,
                color: levelColorIsWhite ? 'white' : 'black'
            };

            bodyRowTemplate += `<td style="text-align: center; background-color: ${totalizerColor};" align="CENTER">`;
            bodyRowTemplate += this.generateBodyCell({...totalizerOptions, ...{value: value}});
            bodyRowTemplate += '</td>';
        }

        for (let i = 0; i < datePriceValues.length; i += 1) {
            const { value } = datePriceValues[i];
            
            bodyRowTemplate += '<td style="text-align: center;" align="CENTER">';
            bodyRowTemplate += this.generateBodyCell({...options, ...{value: numeral(value).format("0,0.00")}});
            bodyRowTemplate += '</td>';
        }

        bodyRowTemplate += '</tr>';

        return bodyRowTemplate;
    }

    generateBodyCell(options) {
        const value = `<span style='color: ${options.color}'>
            ${options.value}
        </span>`;

        if (options.bold) {
            return `<b>${value}</b>`;
        }

        return value;
    }

    getLevelBackgroundColor(level) {
        const colors = {
            0: '#498cc5',
            1: '#9bbfd8',
            2: '#bbd1e0',
            3: '#f0f4f7'
        };

        return colors[level] ? colors[level] : 'inherit';
    }

    getLevelBackgroundTotalizerColor(level) {
        const colors = {
            0: '#498cc5',
            1: '#9bbfd8',
            2: '#bbd1e0',
            3: '#f0f4f7',
            4: '#bbd1e0',
        };

        return colors[level] ? colors[level] : 'inherit';
    }

    setFileNameWithDates(dates, typeOfDate) {
        let fileTitle = `fluxorealizado`;

        if (dates.length === 1) {
            const formattedSingleDate = dates[0].date.replaceAll('/', '');

            if (typeOfDate === 'monthly') {
                const startOfMonth = moment(formattedSingleDate, 'MMYYYY').startOf('month').format('DDMMYYYY');
                const endOfMonth = moment(formattedSingleDate, 'MMYYYY').endOf('month').format('DDMMYYYY');

                return `${fileTitle}_${startOfMonth}_a_${endOfMonth}`;
            }

            return `${fileTitle}_${formattedSingleDate}`;
        }

        const {
            lowerDate,
            greaterDate,
        } = this.getLowerAndGreaterDate(dates);


        return `${fileTitle}_${lowerDate}_a_${greaterDate}`;
    }

    getLowerAndGreaterDate(dates) {
        const parsedDates = dates
            .map(({ dateHidden }) => parseInt(dateHidden.replaceAll('-', '')));

        const lowerDate = Math.min.apply(Math, parsedDates);
        const greaterDate = Math.max.apply(Math, parsedDates);

        return {
            lowerDate: moment(lowerDate, 'YYYYMMDD').format('DDMMYYYY'),
            greaterDate: moment(greaterDate, 'YYYYMMDD').format('DDMMYYYY'),
        }
    }


    sumTotal(totalizer) {
        const value =  totalizer.reduce((a, b) => {
            return a + parseFloat(b.value);
          }, 0);

        return numeral(value).format("0,0.00");
    }
}

export default new GenerateXlsTemplateCashFlowRealized();
