<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Cadastro de Acesso do Tipo de Usuário"/>
			<v-card class="elevation-0">
				<v-card-title> 
					<v-col cols="12" lg="12" md="12" sm="12" xs="12">
						<v-text-field
							filled
							dense
							hide-details
							prepend-inner-icon="mdi-magnify"
							placeholder="Pesquisar"
							v-model="configs.filterTerm"
						/>
					</v-col>
				</v-card-title>
				<v-card-text class="pa-6 pt-0">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Grupo Empresa</th>
								<th>Descrição</th>
							</tr>
						</thead>
						<tbody>
							<!-- eslint-disable-next-line -->
							<tr v-for="userGroup in listData"  style="cursor:pointer;">
								<td style="vertical-align: middle;">{{ userGroup.description_company_group }}</td>
								<td style="vertical-align: middle;">{{ userGroup.description }}</td>
								<td class="m--align-right">
									<table-edit-button @click="edit(userGroup)"/>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash'
	import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
	import tableEditButton from '@/components/buttons/tableEditButton.vue';


	export default {
		components: {
			Breadcrumbs,
			tableEditButton,
		},
		data () {
			return {
				userGroups: [],
				configs: {
					filterTerm: ''
				}
			}
		},

		computed: {
			listData() {
				const self = this;
				const filterTerm = self.configs.filterTerm.toLowerCase();
				var dataRet = this.userGroups;

				if (!_.isEmpty(filterTerm)) {
					dataRet = _.filter(dataRet, list => list.description.toLowerCase().indexOf(filterTerm) >= 0 || list.description_company_group.toLowerCase().indexOf(filterTerm) >= 0)
				}
				return dataRet
			}
		},

		mounted: function () {
			this.loadUserGroups();
		},

		methods: {
			loadUserGroups: function () {
				blockPage('Carregando os dados...');

				this.$http.get(this.$store.state.API_BASE+'usergroup/listAllAndAccess', {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						this.userGroups = result.body.data
						unblockPage();
					},
					function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				).bind(this)
			},

			openAccess: function (user_group_id) {
				this.$router.push({name: 'editaccessusergroup', params: {user_group_id: user_group_id}});
			},

			edit(userGroup) {
				const params = {
					user_group_id: userGroup.id,
				}
				this.$router.push({name: 'editaccessusergroup',params});
			},
		}
	}
</script>
