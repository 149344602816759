<template>
  <v-card class="notification-menu" width="440">
    <v-list style="cursor: pointer; background-color: rgb(44, 46, 62)">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="white--text">
            Notificações
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-progress-linear v-if="!notifications.length" indeterminate color="teal" />

    <v-list class="notification-area">
      <v-list-item
        v-for="notification in notifications"
        :key="notification.id"
        :style="{ background: getBackground(notification) }"
      >
        <v-list-item-icon>
          <v-icon :color="getColor(notification)">
            {{ getIcon(notification) }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            <span>{{ getMessage(notification) }}</span>
          </v-list-item-title>

          <v-list-item-subtitle>
            <span>Início: <b>{{ filterDate(notification.init_datetime) }}</b></span>
          </v-list-item-subtitle>

          <v-list-item-subtitle v-if="notification.finish_datetime">
             <span>Término:<b>{{ filterDate(notification.finish_datetime) }}</b></span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="item-action">
          <v-chip
            :color="getLabelColor(notification)"
            text-color="white"
            class="item-action-label"
            >{{ getLabelText(notification) }}</v-chip
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon';
import { getters } from './notification.state';

export default {
  name: 'NotificationArea',
  data() {
    const remittanceConfig = {
      background: '',
      label: 'Remessa',
      labelColor: 'orange',
      icon: 'mdi-file-upload-outline',
    };

    const conciliationConfig = {
      background: '',
      label: 'Conciliação',
      labelColor: 'purple',
      icon: 'mdi-compare-horizontal',
    };

    return {
      color: {
        0: 'green',
        1: 'red',
        2: 'grey',
        4: 'yellow',
        5: 'red',
      },
      icon: {
        0: 'mdi-check',
        1: 'mdi-sync',
        2: 'mdi-sync',
        4: 'mdi-help-network',
        5: 'mdi-sync-alert',
      },
      actionConfigs: {
        remittance_start: { ...remittanceConfig },
        remittance_stage: { ...remittanceConfig, color: 'grey' },
        remittance_finish: { ...remittanceConfig },
        conciliation_start: { ...conciliationConfig },
        conciliation_stage: { ...conciliationConfig, color: 'grey' },
        conciliation_finish: { ...conciliationConfig },
      },
      message: {
        0: 'Importação concluída com sucesso',
        1: 'Ocorreu um erro na importação dos dados',
        2: 'Importação em andamento',
        4: 'O servidor não está respondendo no momento, por favor tente novamente mais tarde',
        5: 'Ocorreu um erro de comunicação com o sistema, por favor tente novamente mais tarde',
      },
      dateFormat: 'dd/MM/yyyy TT',
    };
  },
  computed: {
    notifications() {
      const gettedNotifications = getters.notifications();

      return gettedNotifications;
    },
  },
  methods: {
    filterDate(rawDate) {
      let filteredDate = '';

      if (rawDate) {
        const luxonDate = DateTime.fromFormat(rawDate, 'yyyy-MM-dd TT');

        filteredDate = luxonDate.toFormat(this.dateFormat);
      }

      return filteredDate;
    },
    getMessage(notification) {
      if (notification.message) {
        return notification.message;
      }
      if (!notification.error && !notification.finish_datetime) {
        const workingOnImportingStatus = 2;

        return this.message[workingOnImportingStatus];
      }

      return this.message[notification.error];
    },
    getIcon(notification) {
      if (!notification.error && !notification.finish_datetime) {
        const workingOnImportingStatus = 2;

        return this.icon[workingOnImportingStatus];
      }

      if (notification.action) {
        return this.actionConfigs[notification.action].icon;
      }

      return this.icon[notification.error];
    },
    getColor(notification) {
      if (!notification.error && !notification.finish_datetime) {
        const workingOnImportingStatus = 2;

        return this.color[workingOnImportingStatus];
      }

      if (notification.action) {
        const configs = this.actionConfigs[notification.action];
        if (configs.color) return configs.color;

        switch (notification.type) {
          case 'danger':
            return 'red';
          case 'success':
            return 'green';
          case 'warning':
            return 'amber';
          default:
            return configs.labelColor;
        }
      }

      return this.color[notification.error];
    },
    getLabelColor(notification) {
      if (!notification.action) {
        return 'primary';
      }
      return this.actionConfigs[notification.action].labelColor;
    },
    getLabelText(notification) {
      if (!notification.action) {
        return 'Importação';
      }
      return this.actionConfigs[notification.action].label;
    },
    getBackground(notification) {
      if (!notification.action) {
        return '';
      }
      return this.actionConfigs[notification.action].background;
    },
  },
};
</script>

<style scoped lang="sass">
.notification-area
	max-height: 300px
	overflow: auto

	&::-webkit-scrollbar
		width: 0.4em

	&::-webkit-scrollbar-track
		background-color: rgba(0, 0, 0, 0.1)

	&::-webkit-scrollbar-thumb
		background-color: rgba(0, 0, 0, 0.2)
		border-radius: 24px

.item-action
	align-self: start

.item-action-label
	font-size: 13px
	height: 22px
	padding: 6px

.v-list-item__title, .v-list-item__subtitle
	white-space: break-spaces
</style>
