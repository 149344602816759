<template>
	<v-menu v-if="this.isNewImporter() === false">
		<template v-slot:activator="{ on, attrs }" >
			<v-btn
				icon
				large
				v-on="on"
				v-bind="attrs"
				:class="isImporting ? 'sync' : ''"
			>
				<v-icon>
					mdi-sync
				</v-icon>
			</v-btn>
		</template>
		<importer-dialog/>
	</v-menu>
</template>

<script>
import { getters } from '@/components/notifications/notification.state';

import ImporterDialog from './ImporterDialog.vue';

export default {
	name: 'ImporterMenu',
	components: {
		ImporterDialog,
	},
	computed: {
		isImporting() {
			return getters.importing();
		},
	},
	methods: {
		isNewImporter() {
			return (this.$session.get('is_new_importer') == 'true');
		},
	},
};
</script>

<style lang="scss" scoped>

.v-menu__content {
	top: 50px !important;
	max-width: 440px;
}

.sync {
    animation: spin 6s linear infinite;
}

@keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		transform:rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform:rotate(360deg);
	}
}
</style>
