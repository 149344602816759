<template>
  <v-data-table :headers="files.headers" :items="items">
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        v-if="item.status == 'T'"
        dense
        small
        color="green"
        text-color="white">
        Ativo
      </v-chip>
      <v-chip
        v-else
        dense
        small>
        Inativo
      </v-chip>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <v-chip
        v-if="item.type == 'RECEBER'"
        dense
        small
        color="green"
        text-color="white">
        Receber
      </v-chip>
      <v-chip
        v-else
        dense
        small
        color="error">
        Pagar
      </v-chip>
    </template>
    <template v-slot:[`item.date_record`]="{ item }">
      <td>{{ item.date_record | formatDate }}</td>
    </template>
    <template v-slot:[`item.situation`]="{ item }">
      <v-chip
        v-show="item.situation == 0"
        dense
        small>
        Recém importado
      </v-chip>
      <v-chip
        v-show="item.situation == 1"
        dense
        small
        color="warning">
        Em importação
      </v-chip>
       <v-chip
        v-show="item.situation == 2"
        dense
        small
        color="success">
        Enviado para o ERP
      </v-chip>
    </template>
    <template #[`item.download`]="{item}">
      <inn-icon-btn icon="mdi-download" @click="handleDownload(item.id)"/>
    </template>
    
    
  </v-data-table>
</template>

<script>
export default {
  name: "CnabReturnsUploadListTable",
  data() {
    return {
      files: {
        headers: [
          {
            text: "Status",
            value: "status",
            sortable: false,
          },
          {
            text: "Tipo",
            value: "type",
            sortable: false,
          },
          {
            text: "Data da Importação",
            value: "date_record",
            sortable: false,
          },          
          {
            text: "Empresa",
            value: "name",
            sortable: false,
          },
          {
            text: "Arquivo",
            value: "filename",
            sortable: false,
          },          
          {
            text: "Situação",
            value: "situation",
            sortable: false,
          },
          {
            text:"Download",
            value:"download",
            sortable: false,
          }       
        ]
      }
    };
  },

  props: {
    items: { type: Array, require: true }
  },
  methods: {
    handleDownload(value) {
      this.$emit('downloadFile', value);
    },
  }  
};
</script>
