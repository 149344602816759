<template>
  <login-form
    @login="login"
    :logging-in="loggingIn"
    logo-url="@/assets/images/logo_verovia_horizontal.svg"
    disable-signup
    disable-forgot
  >
  </login-form>
</template>

<script>
// eslint-disable-next-line
import AuthService from '@/services/auth.service';

import LoginForm from './LoginForm.vue';

export default {
	name: 'Login',
	components: {
		LoginForm,
	},
	data() {
		return {
			loggingIn: false,
		};
	},
	mounted() {
		this.$vuetify.theme.dark = false;
	},
	methods: {
		async login({ email, password }) {
			try {
				this.loggingIn = true;

				const thirdtoken = null;
				const authService = new AuthService();
				const response = await authService.send({ email, password, thirdtoken });

				// caso especifico para o login
				if (response.error) {
					throw new Error(response.message);
				}

				this.saveSession(response.data);

				this.$router.replace({ name: 'dashboard' });
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loggingIn = false;
			}
		},
		signup() {
			console.log('login/signup() - notImplemented');
		},
		forgotPassword() {
			console.log('login/forgotPassword() - notImplemented');
		},
		saveSession(data) {
			this.$session.start();
			this.$session.set('user_id', data.id);
			this.$session.set('token', data.token);
			this.$session.set('name', data.name);
			this.$session.set('email', data.email);
			this.$session.set('status', data.status);
			this.$session.set('is_admin', data.is_admin);
			this.$session.set('user_group_id', data.user_group_id);
			this.$session.set('user_group_name', data.user_group_name);
			this.$session.set('user_type', data.user_type);
			this.$session.set('company_group_id', data.company_group_id);
			this.$session.set('erp_company_group_code', data.erp_company_group_code);
			this.$session.set('company_group_name', data.company_group_name);
			this.$session.set('company_group_image', data.company_group_image);
			this.$session.set('user_gravatar', data.user_gravatar);
			this.$session.set('data_block', data.block);
			this.$session.set('host_address_api_client', data.host_address_api_client);
			this.$session.set('url_powerbi_dashboard', data.url_powerbi_dashboard);
			this.$session.set('url_powerbi_dashboard_darkmode', data.url_powerbi_dashboard_darkmode);
			this.$session.set('url_powerbi', data.url_powerbi);
			this.$session.set('use_ncc', data.use_ncc);
			this.$session.set('is_new_importer', data.is_new_importer);

			this.$session.set('program_access-user_group', data.user_access.user_group);
			this.$session.set('program_access-advancepayments', data.user_access.advancepayments);
			this.$session.set('program_access-company', data.user_access.company);
			this.$session.set('program_access-user_group_access', data.user_access.user_group_access);
			this.$session.set('program_access-user', data.user_access.user);
			this.$session.set('program_access-block', data.user_access.block);
			this.$session.set('program_access-grouper', data.user_access.grouper);

			this.$session.set('user_access-change_date_tr', data.user_permission.change_date_tr);
			this.$session.set('user_access-change_date_tp', data.user_permission.change_date_tp);
			this.$session.set('user_access-change_date_pe', data.user_permission.change_date_pe);
			this.$session.set('user_access-change_date_ad', data.user_permission.change_date_ad);
			this.$session.set('user_access-register_addition_tr', data.user_permission.register_addition_tr);
			this.$session.set('user_access-register_addition_tp', data.user_permission.register_addition_tp);
			this.$session.set('user_access-register_decrease_tr', data.user_permission.register_decrease_tr);
			this.$session.set('user_access-register_decrease_tp', data.user_permission.register_decrease_tp);
			this.$session.set('user_access-change_payment_condition_tr', data.user_permission.change_payment_condition_tr);
			this.$session.set('user_access-change_payment_condition_tp', data.user_permission.change_payment_condition_tp);
			this.$session.set('user_access-change_exchange_rate_tr', data.user_permission.change_exchange_rate_tr);
			this.$session.set('user_access-change_exchange_rate_tp', data.user_permission.change_exchange_rate_tp);
			this.$session.set('user_access-change_interest_rate_tr', data.user_permission.change_interest_rate_tr);
			this.$session.set('user_access-change_interest_rate_tp', data.user_permission.change_interest_rate_tp);
			this.$session.set('user_access-change_amount_tr', data.user_permission.change_amount_tr);
			this.$session.set('user_access-change_amount_tp', data.user_permission.change_amount_tp);
			this.$session.set('user_access-change_amount_pe', data.user_permission.change_amount_pe);
			this.$session.set('user_access-change_amount_sb', data.user_permission.change_amount_sb);
			this.$session.set('user_access-include_comment_tr', data.user_permission.include_comment_tr);
			this.$session.set('user_access-include_comment_tp', data.user_permission.include_comment_tp);
			this.$session.set('user_access-include_comment_pe', data.user_permission.include_comment_pe);
			this.$session.set('user_access-include_comment_sb', data.user_permission.include_comment_sb);
			this.$session.set('user_access-include_comment_ad', data.user_permission.include_comment_ad);
			this.$session.set('user_access-approve_tr', data.user_permission.approve_tr);
			this.$session.set('user_access-approve_tp', data.user_permission.approve_tp);
			this.$session.set('user_access-approve_pe', data.user_permission.approve_pe);
			this.$session.set('user_access-approve_sb', data.user_permission.approve_sb);
			this.$session.set('user_access-receive_message_box_closing', data.user_permission.receive_message_box_closing);
			this.$session.set('user_access-configure_dynamic_groupers', data.user_permission.configure_dynamic_groupers);
			this.$session.set('user_access-include_simulation', data.user_permission.include_simulation);
			this.$session.set('user_access-view_dashboard_power_bi', data.user_permission.view_dashboard_power_bi);
			this.$session.set('user_access-view_billing_on_realized', data.user_permission.view_billing_on_realized);
			this.$session.set('user_access-reconcile', data.user_permission.reconcile);
			this.$session.set('user_access-include_pay_movement', data.user_permission.include_pay_movement);
			this.$session.set('user_access-include_receive_movement', data.user_permission.include_receive_movement);
			this.$session.set('user_access-write_off_receivables', data.user_permission.write_off_receivables);

			this.$session.set('user_config_autotoggle_paramns', data.user_config.autotoggle_paramns);
			this.$session.set('user_config_autotoggle_remittance_confirm_message', data.user_config.autotoggle_remittance_confirm_message);

			const payload = {
				user_id: data.id,
				name: data.name,
				token: data.token,
				email: data.email,
				status: data.status,
				user_group_id: data.user_group_id,
				user_group_name: data.user_group_name,
				user_type: data.user_type,
				company_group_id: data.company_group_id,
				company_group_name: data.company_group_name,
				user_gravatar: data.user_gravatar,
				user_config: data.user_config,
			};

			this.$store.dispatch('changeUser', payload);
		},
	},
};
</script>
