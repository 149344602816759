<template>
	<div id="params" class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'conciliationactionconfig' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success" v-on:click="save">Salvar</button>
					</div>
	    </div>
	    <div class="m-portlet__body">
	        <div class="m-form m-form--label-align-right m--margin-bottom-30">

				<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link active" data-toggle="tab" href="#filter_geral" role="tab" aria-expanded="true">
							Dados Gerais
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#conciliacaoautomatica" role="tab" aria-expanded="true">
							Conciliação Automática
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#conciliacaomanual" role="tab" aria-expanded="true">
							Conciliação Manual
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#filter_cnab" role="tab" aria-expanded="true">
							Filtros Banco
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#filter_erp" role="tab" aria-expanded="true">
							Filtros ERP
						</a>
					</li>
				</ul>


				<div class="tab-content" id="frmUserGroup">

					<div class="tab-pane active" id="filter_geral" role="tabpanel">

						<div class="row" id="frmGrouper">
							<div class="col-md-9">
								<div class="form-group">
									<label>Configuração Conciliação Bancária</label>
									<select class="form-control select2 hide" field-name="Configuração Conciliação Bancária" id="conciliation_config_id" name="conciliation_config_id" style="width: 100%;" required>
										<option value="">Selecione...</option>
										<option v-for="config in configs" :value="config.id" :key="config.id">{{ getDescriptionConfig(config)}}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Status</label>
									<select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;">
										<option value="1">Ativo</option>
										<option value="0">Inativo</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Descrição</label>
									<input type="text" class="form-control" field-name="Descrição" id="description" name="description" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Ação no ERP</label>
									<select class="form-control select2 hide" field-name="Ação ERP" id="actionerp" name="actionerp" style="width: 100%;" required>
										<option value="">Selecione...</option>
										<option value="1">Apenas Concilia</option>
										<option value="2">Ignora o Lançamento</option>
										<option value="3">Não Concilia</option>
										<option value="4">Inclui Movimento Bancário</option>
										<option value="5">Gerar tranferência bancária</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Aglutina Banco</label>
									<select class="form-control select2 hide" field-name="Aglutina" id="in_agglutine" name="in_agglutine" style="width: 100%;" required>
										<option value="0">Não</option>
										<option value="1">Sim</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Aglutina ERP</label>
									<select class="form-control select2 hide" field-name="Aglutina no ERP" id="erp_agglutine" name="erp_agglutine" style="width: 100%" required>
										<option value="0">Não</option>
										<option value="1">Sim</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-show="(actionerp == 4 || actionerp == 5) && (!conciliationConfig[0] || conciliationConfig[0].in_use_financial_nature == 1)">
								<div class="form-group">
									<label>Natureza Financeira</label>
									<select class="form-control select2 hide" field-name="Natureza Financeira" id="ed_codigo" name="ed_codigo" style="width: 100%;">
										<option v-for="financialnature in listFinancialNature" :value="financialnature.ed_codigo" :key="financialnature.id">{{ financialnature.ed_codigo  }} - {{financialnature.ed_descric}}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-show="(actionerp == 4 || actionerp == 5) && (!conciliationConfig[0] || conciliationConfig[0].in_use_cost_center == 1)">
								<div class="form-group">
									<label>Centro de Custo</label>
									<select class="form-control select2 hide" field-name="Centro de Custo" id="ctt_custo" name="ctt_custo" style="width: 100%;">
										<option v-for="costcenter in listCostCenter" :value="costcenter.ctt_custo" :key="costcenter.id">{{ costcenter.ctt_custo  }} - {{costcenter.ctt_desc01}}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-show="(actionerp == 4 || actionerp == 5) && (!conciliationConfig[0] || conciliationConfig[0].in_use_accounting_item == 1)">
								<div class="form-group">
									<label>Item Contabil</label>
									<select class="form-control select2 hide" field-name="Item Contabil" id="ctd_item" name="ctd_item" style="width: 100%;">
										<option v-for="accountingItem in listAccountingItem" :value="accountingItem.ctd_item" :key="accountingItem.id">{{ accountingItem.ctd_item  }} - {{accountingItem.ctd_desc01}}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-show="(actionerp == 4 || actionerp == 5) && (!conciliationConfig[0] || conciliationConfig[0].in_use_value_class== 1)">
								<div class="form-group">
									<label>Classe de Valor</label>
									<select class="form-control select2 hide" field-name="Classe de Valor" id="cth_clvl" name="cth_clvl" style="width: 100%;">
										<option v-for="valueClass in listValueClass" :value="valueClass.cth_clvl" :key="valueClass.id">{{ valueClass.cth_clvl  }} - {{valueClass.cth_desc01}}</option>
									</select>
								</div>
							</div>
							<!-- prefixo para contabilidade (mostrado apenas se actionerp = 4) -->
							<div class="col-md-3" v-show="actionerp == 4 && conciliationConfig[0] && conciliationConfig[0].in_use_prefix == 1">
								<div class="form-group">
									<label>Prefixo</label>
									<input type="text" class="form-control" field-name="prefixo" id="prefix" name="prefix"/>
								</div>
							</div>

							<div class="col-md-3" v-show="actionerp == 5">
								<div class="form-group">
									<label>Banco</label>
									<select class="form-control select2 hide" field-name="Banco" id="bank" name="bank" style="width: 100%;" :required="actionerp == 5">
										<option value="">Selecione...</option>
										<option v-for="bank in banks" :value="JSON.stringify({code: bank.a6_cod, agency: bank.a6_agencia, account: bank.a6_conta})" :key="JSON.stringify({code: bank.a6_cod, agency: bank.a6_agencia, account: bank.a6_conta})">
											{{bank.a6_cod}} {{ bank.a6_agencia}} {{ bank.a6_conta}} - {{ bank.a6_nome}}
										</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Histórico</label>
									<select class="form-control select2 hide" field-name="Histórico no ERP" id="tp_historic" name="tp_historic" style="width: 100%;" required>
										<option value="1">Usar histórico do arquivo</option>
										<option value="2">Texto Fixo</option>
										<option value="3">Descrição da natureza</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Descrição do Histórico</label>
									<input type="text" class="form-control" field-name="Descrição" id="historic" name="historic" disabled>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Atualiza data de disponibilidade</label>
									<select class="form-control select2 hide" field-name="Atualiza data de disponibilidade" id="update_availability_date" name="update_availability_date" style="width: 100%;" required>
										<option value="0">Não</option>
										<option value="1">Sim</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Utilizar data</label>
									<select class="form-control select2 hide" field-name="Utiliza data" id="use_date" name="use_date" style="width: 100%;" required>
										<option value="0">Data contabil</option>
										<option value="1">Data movimento</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Considera o primeiro registro</label>
									<select class="form-control select2 hide" field-name="Considera o primeiro registro" id="considered_first" name="considered_first" style="width: 100%;" required>
										<option value="0">Não</option>
										<option value="1">Sim</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="conciliacaoautomatica" role="tabpanel">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Valor</label>
									<input type="text" class="form-control" field-name="Tolerância de Valor" id="value_tolerance" name="value_tolerance" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Data</label>
									<input type="text" class="form-control" field-name="Tolerância de Data" id="date_tolerance" name="date_tolerance" required>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="conciliacaomanual" role="tabpanel">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Valor</label>
									<input type="text" class="form-control" field-name="Tolerância de Valor" id="manual_value_tolerance" name="manual_value_tolerance" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Data</label>
									<input type="text" class="form-control"  field-name="Tolerância de Data" id="manual_date_tolerance" name="manual_date_tolerance" required>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="filter_cnab" role="tabpanel">
						<div class="row">
							<div class="col-md-4">
								<input type="hidden" name="editing_cnab" id="editing_cnab" value="">
								<div class="form-group">
									<label>Campo Banco</label>
									<select class="form-control select2 hide" field-name="Campo" id="cnab_field" name="cnab_field" style="width: 100%;">
										<option value="">Selecione...</option>
										<option v-for="filterField in filterFields" :key="filterField.fieldName" :value="filterField.fieldName">{{ filterField.fieldDescription }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Operador</label>
									<select class="form-control select2 hide" field-name="Operador" id="cnab_operator" name="cnab_operator" style="width: 100%;">
										<option value="">Selecione...</option>
										<option value="=">Igual a</option>
										<option value="<">Menor que</option>
										<option value="<=">Menor ou igual a</option>
										<option value=">">Maior que</option>
										<option value=">=">Maior ou igual a</option>
										<option value="LIKE">Contém a expressão</option>
										<option value="NOT LIKE">Não contém</option>
										<option value="<>">Diferente de</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expressão</label>
									<input type="text" class="form-control" field-name="Expressão" id="cnab_expression" name="cnab_expression">
								</div>
							</div>
							<div class="col-md-2" style="padding-top: 25px;text-align: right;">
								<button type="button" id="addFilterCnab" class="btn m-btn--pill btn-success"><i class="la la-plus"></i></button>
							</div>
							<div class="col-md-3" style="margin-bottom:20px;padding-top: 6px;">
								<h5>Outros operadores:</h5>
							</div>
							<div class="col-md-9" style="margin-bottom:20px;">
								<button type="button" class="addOperatorsCnab btn m-btn--pill btn-success" data-operator="(">(</button>
								<button type="button" class="addOperatorsCnab btn m-btn--pill btn-success" data-operator=")">)</button>
								<button type="button" class="addOperatorsCnab btn m-btn--pill btn-success" data-operator="AND">E</button>
								<button type="button" class="addOperatorsCnab btn m-btn--pill btn-success" data-operator="OR">OU</button>
							</div>
							<div id="filters-iten-cnab" style="width: 100%;"></div>
						</div>
					</div>
					<div class="tab-pane" id="filter_erp" role="tabpanel">
						<div class="row">
							<div class="col-md-4">
								<input type="hidden" name="editing_erp" id="editing_erp" value="">
								<div class="form-group">
									<label>Campo ERP</label>
									<select class="form-control select2 hide" field-name="Campo" id="erp_field" name="erp_field" style="width: 100%;">
										<option value="">Selecione...</option>
										<option v-for="filterField in filterFieldsErp" :key="filterField.innova_field" :value="filterField.innova_field">{{ filterField.field_description }} ({{filterField.client_field}})</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Operador</label>
									<select class="form-control select2 hide" field-name="Operador" id="erp_operator" name="erp_operator" style="width: 100%;">
										<option value="">Selecione...</option>
										<option value="=">Igual a</option>
										<option value="<">Menor que</option>
										<option value="<=">Menor ou igual a</option>
										<option value=">">Maior que</option>
										<option value=">=">Maior ou igual a</option>
										<option value="LIKE">Contém a expressão</option>
										<option value="NOT LIKE">Não contém</option>
										<option value="<>">Diferente de</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expressão</label>
									<input type="text" class="form-control" field-name="Expressão" id="erp_expression" name="erp_expression">
								</div>
							</div>
							<div class="col-md-2" style="padding-top: 25px;text-align: right;">
								<button type="button" id="addFilterErp" class="btn m-btn--pill btn-success"><i class="la la-plus"></i></button>
							</div>
							<div class="col-md-3" style="margin-bottom:20px;padding-top: 6px;">
								<h5>Outros operadores:</h5>
							</div>
							<div class="col-md-9" style="margin-bottom:20px;">
								<button type="button" class="addOperatorsErp btn m-btn--pill btn-success" data-operator="(">(</button>
								<button type="button" class="addOperatorsErp btn m-btn--pill btn-success" data-operator=")">)</button>
								<button type="button" class="addOperatorsErp btn m-btn--pill btn-success" data-operator="AND">E</button>
								<button type="button" class="addOperatorsErp btn m-btn--pill btn-success" data-operator="OR">OU</button>
							</div>
							<div id="filters-iten-erp" style="width: 100%;"></div>
						</div>
					</div>
				</div>
	        </div>
	    </div>
	</div>
</template>

<script>
    import _ from 'lodash'

    export default {
        data() {
            return {
				conciliationConfig:{},
				action: [],
				actionerp: null,
                configs: [],
                groupers: [],
				whereShow:[],
                grouper: {},
				filterFields: [],
				filterFieldsErp: [],
				company_group_erp: this.$session.get('erp_company_group_code'),
				banks: [],
				bank: [],
				conciliation_action_config_id: this.$route.params.conciliation_action_config_id,
				listFinancialNature: [],
				listCostCenter:[],
				listAccountingItem: [],
				listValueClass: [],
				companys: [],
            }
        },

        mounted: async function () {
			blockPage('Carregando os dados...');

			$("#tp_historic").change(() =>{
				$( "#historic" ).prop( "disabled", $("#tp_historic").val() != 2);
			});

			$("#actionerp").change(async () => {
				this.actionerp = $("#actionerp").val();
			});

			$("#bank").change(async () => {
				this.bank = $("#bank").val();
			});

			var $this = this;
			$("#conciliation_config_id").change(async () => {
				var conciliation_config_id = $("#conciliation_config_id").val();
				$this.loadConciliationConfig(conciliation_config_id);
			});

			$("#date_tolerance").val("0");
			$("#date_tolerance").mask("000");

			$("#manual_date_tolerance").val("0");
			$("#manual_date_tolerance").mask("000");

			$('#value_tolerance').mask('00.000.000.000,00', {reverse: true, watchDataMask: true});
			$('#manual_value_tolerance').mask('00.000.000.000,00', {reverse: true, watchDataMask: true});

			$('#value_tolerance').val(numeral(0).format('0,0.00'));
			$('#manual_value_tolerance').val(numeral(0).format('0,0.00'));

			$('#in_agglutine').val("0");
			$("#in_agglutine").trigger('change');

			$('#erp_agglutine').val("0");
			$('#erp_agglutine').trigger('change');

			$('#update_availability_date').val("0");
			$('#update_availability_date').trigger('change');

			$('#use_date').val("1");
			$('#use_date').trigger('change');

			$('#considered_first').val("0");
			$('#considered_first').trigger('change');

			$('#tp_historic').val("1");
			$('#tp_historic').trigger('change');

            await this.loadConfigs();
			await this.loadMethod();
			await this.loadFilterFields();
			await this.loadFilterFieldsErp();
			await this.loadCompanys();
			await this.loadBanks();

			if(this.conciliation_action_config_id != 'new') await this.loadData();

			unblockPage();
        },

        methods: {
            loadConfigs: async function () {
                await this.$http.get(`${this.$store.state.API_BASE}conciliation/config/listAll`, {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
						this.configs = result.body.data;
                    },
                    (error) => {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
            },
            loadFilterFields: function (tableName, companyErpCode) {
				this.filterFields = [
					{
						fieldDescription : 'Valor',
						fieldName : 'valor',
					},
					{
						fieldDescription : 'Data de Movimento',
						fieldName : 'data_movimento',
					},
					{
						fieldDescription : 'Data de Processamento',
						fieldName : 'data_processamento',
					},
					{
						fieldDescription : 'Número do Documento',
						fieldName : 'numero_documento',
					},
					{
						fieldDescription : 'Tipo Lançamento',
						fieldName : 'tp_lancamento',
					},
					{
						fieldDescription : 'Histórico',
						fieldName : 'historico',
					},
					{
						fieldDescription : 'Natureza Lançamento',
						fieldName : 'natureza_lancamento',
					},
					{
						fieldDescription : 'Categoria do Lançamento',
						fieldName : 'categoria_lancamento',
					},
					{
						fieldDescription : 'Código Histórico no Banco',
						fieldName : 'codigo_historico_banco',
					},
					{
						fieldDescription : 'Banco',
						fieldName : 'banco',
					},
					{
						fieldDescription : 'Agência',
						fieldName : 'agencia',
					},
					{
						fieldDescription : 'Número da Conta',
						fieldName : 'numcon',
					},
					{
						fieldDescription : 'Código da Empresa',
						fieldName : 'company_id',
					}
				];

				this.filterFields = _.sortBy(this.filterFields, "fieldDescription");
			},
            loadFinancialNature: async function (ed_codigo) {
				if (!ed_codigo) return;

				await this.$http.get(this.$store.state.API_BASE+'financialnature/listOne/' + ed_codigo, { headers: { 'token': this.$session.get('token')}}).then(
                	(result) =>{
						this.listFinancialNature = result.body.data;

						setTimeout(() => {
							$("#ed_codigo").val(ed_codigo);
							$("#ed_codigo").trigger('change');
						}, 0);
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
			},
            loadCostCenter: async function (ctt_custo) {
				if (!ctt_custo) return;

				await this.$http.get(this.$store.state.API_BASE+'costcenter/listOne/' + ctt_custo, { headers: { 'token': this.$session.get('token')}}).then(
                	(result) =>{
						this.listCostCenter = result.body.data;

						setTimeout(() => {
							$("#ctt_custo").val(ctt_custo);
							$("#ctt_custo").trigger('change');
						}, 0);
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
			},
            loadAccountingItem: async function (ctd_item) {
				if (!ctd_item) return;

				await this.$http.get(this.$store.state.API_BASE+'accountingitem/listOne/' + ctd_item, { headers: { 'token': this.$session.get('token')}}).then(
                	(result) =>{
						this.listAccountingItem = result.body.data;

						setTimeout(() => {
							$("#ctd_item").val(ctd_item);
							$("#ctd_item").trigger('change');
						}, 0);
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
			},
            loadValueClass: async function (cth_clvl) {
				if (!cth_clvl) return;

				await this.$http.get(this.$store.state.API_BASE+'valueclass/listOne/' + cth_clvl, { headers: { 'token': this.$session.get('token')}}).then(
                	(result) =>{
						this.listValueClass = result.body.data;

						setTimeout(() => {
							$("#cth_clvl").val(cth_clvl);
							$("#cth_clvl").trigger('change');
						}, 0);
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
			},
			loadFilterFieldsErp: function (tableName, companyErpCode) {
				const url = `${this.$store.state.API_BASE}fields`;
				const company = $("#conciliation_config_id option:selected").attr("company");

				this.filterFieldsErp = [];
 				$.ajax({
					type: "GET",
          url,
          headers: {
						'token': this.$session.get('token')
          },
          data: {
						table: 'movimento_bancario',
						company: company || '',
          },
        })
          .done((result) => {
					if (result.error) {
						showNotification('Atenção', result.message, 'danger');
						return false;
					}

					if (result.payload && result.payload[0]){
						this.filterFieldsErp = result.payload;

						this.filterFieldsErp = _.sortBy(this.filterFieldsErp, "field_description");
					}
				}).fail((error) => {
					console.log('error');
					console.log(error);
					showNotification('Atenção', 'Opa! Ocorreu um problema ao estabelecer a conexão com o ERP, por favor, tente novamente mais tarde.', 'danger');
					unblockPage();
				});
			},
			loadCompanys: async function() {
                await this.$http.get(this.$store.state.API_BASE + 'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
						this.companys = result.body.data;
						console.log(this.companys);
                    },
                   (error) =>{
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
            },
			loadBanks: async function () {
				let companysSelected = [];

				this.companys.forEach(element => {
					companysSelected.push(element.id);
				});

				let params = {
					companys: JSON.stringify(companysSelected),
					fluxcai: null
                };

				await this.$http.get(this.$store.state.API_BASE + 'bank/listAllByGroup', {headers: {'token': this.$session.get('token')}, params: params}).then(
				    (result) => {
						this.banks = result.body.data && result.body && result.body.data ? result.body.data : [];
                    },
                    (error) => {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
			},
			loadData: async function () {
				this.action = {};

                await this.$http.get(this.$store.state.API_BASE + 'conciliation/action/listOne/' + this.conciliation_action_config_id, {headers: {'token': this.$session.get('token')}}).then(
                    async (result) => {
						if (result && result.body.data.conciliation_config_action[0] ){
							console.log(result);
							this.action = result.body.data.conciliation_config_action[0];

							$('#conciliation_config_id').val(this.action.conciliation_config_id);
							$("#conciliation_config_id").trigger('change');

							$('#description').val(this.action.description);
							$('#prefix').val(this.action.prefix);

							$('#actionerp').val(this.action.actionerp);
							$("#actionerp").trigger('change');

							if(this.action.bank_code){
								$("#bank").val(JSON.stringify({code: this.action.bank_code, agency: this.action.agency, account: this.action.account}));
								$("#bank").trigger('change');
							}

							$('#in_agglutine').val(this.action.in_agglutine);
							$("#in_agglutine").trigger('change');

							$('#erp_agglutine').val(this.action.erp_agglutine);
							$('#erp_agglutine').trigger('change');

							$('#update_availability_date').val(this.action.update_availability_date);
							$("#update_availability_date").trigger('change');

							$('#use_date').val(this.action.use_date.toString());
							$("#use_date").trigger('change');

							$('#considered_first').val(this.action.considered_first.toString());
							$("#considered_first").trigger('change');

							$('#date_tolerance').val(this.action.date_tolerance);
							$('#manual_date_tolerance').val(this.action.manual_date_tolerance);

							$('#value_tolerance').val(numeral(this.action.value_tolerance).format('0,0.00'));
							$('#manual_value_tolerance').val(numeral(this.action.manual_value_tolerance).format('0,0.00'));

							if(this.action.ed_codigo){
								await this.loadFinancialNature(this.action.ed_codigo);
							}

							if(this.action.ctt_custo){
								await this.loadCostCenter(this.action.ctt_custo);
							}

							if(this.action.ctd_item){
								await this.loadAccountingItem(this.action.ctd_item);
							}

							if(this.action.cth_clvl){
								await this.loadValueClass(this.action.cth_clvl);
							}

							if(this.action.id_value_class){
								$('#id_value_class').val(this.action.id_value_class);
								$("#id_value_class").trigger('change');
							}

							if(this.action.tp_historic){
								$('#tp_historic').val(this.action.tp_historic);
								$("#tp_historic").trigger('change');
							}

							$('#historic').val(this.action.historic);
							$("#historic").trigger('change');

							$('#status').val(this.action.status);
							$("#status").trigger('change');

							var totFilters = 0;

							$.each(result.body.data.conciliation_config_action_filter, function(){
								totFilters++;

								var html = "";

								if (this.type == 'E' || $.trim(this.type) == '') {
									html = '<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="m-badge m-badge--primary m-badge--wide ui-draggable-handle" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-cnab_field="'+this.field+'" data-cnab_field_description="'+this.field_description+'" data-cnab_operator="'+this.operator+'" data-cnab_operator_description="'+this.operator_description+'" data-cnab_expression="'+this.expression+'">';
									html += '<span class="text text-cnab">'+this.field_description+' '+this.operator_description+' "'+this.expression+'"'+'</span>';
									html += '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>';
									html += '</span>';
								} else if (this.type == 'O') {
									html = '<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="operator m-badge m-badge--primary m-badge--wide ui-draggable-handle" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-operator="'+this.operator+'">';
									html += '<span class="text">'+this.operator_description+'</span>';
									html += '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>';
									html += '</span>';
								}

								$("#filters-iten-cnab").append(html);
							});

							$.each(result.body.data.conciliation_config_action_filter_erp, function(){
								totFilters++;

								var html = "";

								if (this.type == 'E' || $.trim(this.type) == '') {
									html = '<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="m-badge m-badge--primary m-badge--wide ui-draggable-handle" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-erp_field="'+this.field+'" data-erp_field_description="'+this.field_description+'" data-erp_operator="'+this.operator+'" data-erp_operator_description="'+this.operator_description+'" data-erp_expression="'+this.expression+'">';
									html += '<span class="text text-erp">'+this.field_description+' '+this.operator_description+' "'+this.expression+'"'+'</span>';
									html += '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>';
									html += '</span>';
								} else if (this.type == 'O') {
									html = '<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="operator m-badge m-badge--primary m-badge--wide ui-draggable-handle" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-operator="'+this.operator+'">';
									html += '<span class="text">'+this.operator_description+'</span>';
									html += '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>';
									html += '</span>';
								}

								$("#filters-iten-erp").append(html);
							});
						}
                    },
                    async (error) => {
						console.error(error);
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
				);
			},
			loadConciliationConfig: async function(conciliationConfigId){
				blockPage('Carregando os dados...');

				await this.$http.get(this.$store.state.API_BASE + 'conciliation/config/listOne/'+conciliationConfigId, {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
						this.conciliationConfig = result.body.data && result.body && result.body.data ? result.body.data : [];
						unblockPage();
                    },
                    (error) => {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );

			},
            loadMethod: function () {
                var self = this;

                numeral.language('pt-br');

                $(".select2").select2();

				$("#ed_codigo").select2({
					placeholder: "Selecione",
					language: 'pt-BR',
					dropdownParent: $('#params') ,
					allowClear: true,
					ajax: {
						url: this.$store.state.API_BASE + 'financialnature/listAll',
						dataType: 'json',
						width: 'resolve',
						type: 'GET',
						delay: 500,
						headers: {
							'token': this.$session.get('token')
						},
						/*data: function (param) {
							return {
								search: param.term || '',
								limit: 100
							};
						},*/
						data: function (param) {
							const company = $("#conciliation_config_id option:selected").attr("company");

							return {
								search: param.term || '',
								limit: 100,
								company_id: (company ? company : '')
							};
						},

						processResults: (result) => {
							let resultado = [];
							for (let i = 0; i < result.data.length; i++) {
								resultado.push({
									id: result.data[i].ed_codigo,
									text: (result.data[i].ed_codigo || '')  + ' - ' + (result.data[i].ed_descric || '')
								});
							}
							return {
								results: resultado
							};
						},
						cache: true
					}
				});

				$("#ctt_custo").select2({
					placeholder: "Selecione",
					language: 'pt-BR',
					dropdownParent: $('#params') ,
					allowClear: true,
					ajax: {
						url: this.$store.state.API_BASE + 'costcenter/listAll',
						dataType: 'json',
						width: 'resolve',
						type: 'GET',
						delay: 500,
						headers: {
							'token': this.$session.get('token')
						},
						/*data: function (param) {
							return {
								search: param.term || '',
								limit: 100
							};
						},*/
						data: function (param) {
							const company = $("#conciliation_config_id option:selected").attr("company");

							return {
								search: param.term || '',
								limit: 100,
								company_id: (company ? company : '')
							};
						},
						processResults: (result) => {
							let resultado = [];
							for (let i = 0; i < result.data.length; i++) {
								resultado.push({
									id: result.data[i].ctt_custo,
									text: (result.data[i].ctt_custo || '')  + ' - ' + (result.data[i].ctt_desc01 || '')
								});
							}
							return {
								results: resultado
							};
						},
						cache: true
					}
				});

				$("#ctd_item").select2({
					placeholder: "Selecione",
					language: 'pt-BR',
					dropdownParent: $('#params'),
					allowClear: true,
					ajax: {
						url: this.$store.state.API_BASE + 'accountingitem/listAll',
						dataType: 'json',
						width: 'resolve',
						type: 'GET',
						delay: 500,
						headers: {
							'token': this.$session.get('token')
						},
						data: function (param) {
							return {
								search: param.term || '',
								limit: 100
							};
						},
						processResults: (result) => {
							let resultado = [];
							for (let i = 0; i < result.data.length; i++) {
								resultado.push({
									id: result.data[i].ctd_item,
									text: (result.data[i].ctd_item || '')  + ' - ' + (result.data[i].ctd_desc01 || '')
								});
							}
							return {
								results: resultado
							};
						},
						cache: true
					}
				});

				$("#cth_clvl").select2({
					placeholder: "Selecione",
					language: 'pt-BR',
					dropdownParent: $('#params') ,
					allowClear: true,
					ajax: {
						url: this.$store.state.API_BASE + 'valueclass/listAll',
						dataType: 'json',
						width: 'resolve',
						type: 'GET',
						delay: 500,
						headers: {
							'token': this.$session.get('token')
						},
						/*data: function (param) {
							return {
								search: param.term || '',
								limit: 100
							};
						},*/
						data: function (param) {
							const company = $("#conciliation_config_id option:selected").attr("company");

							return {
								search: param.term || '',
								limit: 100,
								company_id: (company ? company : '')
							};
						},
						processResults: (result) => {
							let resultado = [];
							for (let i = 0; i < result.data.length; i++) {
								resultado.push({
									id: result.data[i].cth_clvl,
									text: (result.data[i].cth_clvl || '')  + ' - ' + (result.data[i].cth_desc01 || '')
								});
							}
							return {
								results: resultado
							};
						},
						cache: true
					}
				});

				$( "#filters-iten-cnab").sortable({
					opacity: 0.5,
					cursor: "-webkit-grabbing",
					update: function(event, ui){
						//self.saveGroupersOrder();
					}
				});

				$( "#filters-iten-erp").sortable({
					opacity: 0.5,
					cursor: "-webkit-grabbing",
					update: function(event, ui){
						//self.saveGroupersOrder();
					}
				});

				$(".addOperatorsCnab").click(function(){
					var totFilters = 0;
					var operator = $(this).data('operator');
					var description_operator = $(this).text();

					$.each($('span', '.m-badge'),function(){
						if(!$(this).hasClass('text')){
							totFilters++;
						}
					});
					totFilters++;

					$("#filters-iten-cnab").append(
						'<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="operator m-badge m-badge--primary m-badge--wide" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-operator="'+operator+'">'+
							'<span class="text text-cnab">'+description_operator+'</span>'+
							'<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>'+
						'</span>'
					);
				});


				$(".addOperatorsErp").click(function(){
					var totFilters = 0;
					var operator = $(this).data('operator');
					var description_operator = $(this).text();

					$.each($('span', '.m-badge'),function(){
						if(!$(this).hasClass('text')){
							totFilters++;
						}
					});
					totFilters++;

					$("#filters-iten-erp").append(
						'<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="operator m-badge m-badge--primary m-badge--wide" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-operator="'+operator+'">'+
							'<span class="text text-erp">'+description_operator+'</span>'+
							'<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>'+
						'</span>'
					);
				});

				$("#addFilterCnab").click(function(){
					var totFilters = 0, exit = false;

                    if ($.trim($("#cnab_field option:selected").text()) == 'Selecione...' || $.trim($("#cnab_operator option:selected").text()) == 'Selecione...') {
                        exit = true;
                    }

                    if (exit) {
                        showNotification('Atenção', 'Todos os campos do filtro devem ser preenchidos!', 'warning');
                        return false;
                    }

					$.each($('span', '.m-badge'),function(){
						if(!$(this).hasClass('text')){
							totFilters++;
						}
					});
					totFilters++;

                    if (totFilters >= 1) {
                        $('#grouper_id').attr('disabled', true);
					}

                    if ($.trim($("#editing_cnab").val()) != '') {
                        var itemId = $("#editing_cnab").val();
                        var $div = $('#iten_' + itemId);

						$div.data("cnab_field", $("#cnab_field option:selected").val());
						$div.data("cnab_field_description", $("#cnab_field option:selected").text());
						$div.data("cnab_operator", $("#cnab_operator option:selected").val());
						$div.data("cnab_operator_description", $("#cnab_operator option:selected").text());
						$div.data("cnab_expression", $("#cnab_expression").val());

						$div.find('.text').text($("#cnab_field option:selected").text()+' '+$("#cnab_operator option:selected").text()+' "'+$("#cnab_expression").val()+'"');
					} else {
						$("#filters-iten-cnab").append(
							'<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="m-badge m-badge--primary m-badge--wide" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-cnab_field="'+$("#cnab_field option:selected").val()+'" data-cnab_field_description="'+$("#cnab_field option:selected").text()+'" data-cnab_operator="'+$("#cnab_operator option:selected").val()+'" data-cnab_operator_description="'+$("#cnab_operator option:selected").text()+'" data-cnab_expression="'+$("#cnab_expression").val()+'">'+
								'<span class="text text-cnab">'+$("#cnab_field option:selected").text()+' '+$("#cnab_operator option:selected").text()+' "'+$("#cnab_expression").val()+'"'+'</span>'+
								'<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>'+
							'</span>'
						);
					}

                    $("#cnab_field").val("");
                    $('#select2-cnab_field-container').attr('title', 'Selecione...');
                    $('#select2-cnab_field-container').html('Selecione...');
                    $("#cnab_operator").val("");
                    $('#select2-cnab_operator-container').attr('title', 'Selecione...');
                    $('#select2-cnab_operator-container').html('Selecione...');
                    $("#cnab_expression").val("");
                    $("#editing_cnab").val("");
                });

				$("#addFilterErp").click(function(){
					var totFilters = 0, exit = false;

                    if ($.trim($("#erp_field option:selected").text()) == 'Selecione...' || $.trim($("#erp_operator option:selected").text()) == 'Selecione...') {
                        exit = true;
                    }

                    if (exit) {
                        showNotification('Atenção', 'Todos os campos do filtro devem ser preenchidos!', 'warning');
                        return false;
                    }

					$.each($('span', '.m-badge'),function(){
						if(!$(this).hasClass('text')){
							totFilters++;
						}
					});
					totFilters++;

                    if (totFilters >= 1) {
                        $('#grouper_id').attr('disabled', true);
                    }

                    if ($.trim($("#editing_erp").val()) != '') {
                        var itemId = $("#editing_erp").val();
                        var $div = $('#iten_' + itemId);

						$div.data("erp_field", $("#erp_field option:selected").val());
						$div.data("erp_field_description", $("#erp_field option:selected").text());
						$div.data("erp_operator", $("#erp_operator option:selected").val());
						$div.data("erp_operator_description", $("#erp_operator option:selected").text());
						$div.data("erp_expression", $("#erp_expression").val());

						$div.find('.text').text($("#erp_field option:selected").text()+' '+$("#erp_operator option:selected").text()+' "'+$("#erp_expression").val()+'"');
					} else {
						$("#filters-iten-erp").append(
							'<span id="iten_'+totFilters+'" itemId="'+totFilters+'" class="m-badge m-badge--primary m-badge--wide" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-erp_field="'+$("#erp_field option:selected").val()+'" data-erp_field_description="'+$("#erp_field option:selected").text()+'" data-erp_operator="'+$("#erp_operator option:selected").val()+'" data-erp_operator_description="'+$("#erp_operator option:selected").text()+'" data-erp_expression="'+$("#erp_expression").val()+'">'+
								'<span class="text text-erp">'+$("#erp_field option:selected").text()+' '+$("#erp_operator option:selected").text()+' "'+$("#erp_expression").val()+'"'+'</span>'+
								'<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="'+totFilters+'"></i></span>'+
							'</span>'
						);
					}

                    $("#erp_field").val("");
                    $('#select2-erp_field-container').attr('title', 'Selecione...');
                    $('#select2-erp_field-container').html('Selecione...');
                    $("#erp_operator").val("");
                    $('#select2-erp_operator-container').attr('title', 'Selecione...');
                    $('#select2-erp_operator-container').html('Selecione...');
                    $("#erp_expression").val("");
                    $("#editing_erp").val("");
                });

                $(document).on('click', "i.fa", function () {
                    var $this = $(this);
                    var itemId = $this.attr('itemId');
                    var $div = $('#iten_' + itemId);

                    if ($this.hasClass('remove')) {
                        $("#iten_" + itemId).remove();

						var totFilters = 0;
						$.each($('span', '.m-badge'),function(){
							if(!$(this).hasClass('text')){
								totFilters++;
							}
						});
						totFilters++;

						if (totFilters <= 0) {
							$('#grouper_id').attr('disabled', false);
						}
					}
				});

				$(document).on('click', "span.text", function() {
					var $this = $(this);
					var itemId = $this.parent().attr('itemId');
					var $div = $('#iten_'+itemId);

					if ($this.parent().hasClass('m-badge') && !$this.parent().hasClass('operator') && $this.hasClass('text-cnab')) {
						$("#editing_cnab").val(itemId);

						$("#cnab_field").val($div.data('cnab_field'));
						$('#select2-cnab_field-container').attr('title', $div.data('cnab_field_description'));
						$('#select2-cnab_field-container').html($div.data('cnab_field_description'));

						$("#cnab_operator").val($div.data('cnab_operator'));
						$('#select2-cnab_operator-container').attr('title', $div.data('cnab_operator_description'));
						$('#select2-cnab_operator-container').html($div.data('cnab_operator_description'));

						$("#cnab_expression").val($div.data('cnab_expression'));
					}

					if ($this.parent().hasClass('m-badge') && !$this.parent().hasClass('operator') && $this.hasClass('text-erp')) {
						$("#editing_erp").val(itemId);

						$("#erp_field").val($div.data('erp_field'));
						$('#select2-erp_field-container').attr('title', $div.data('erp_field_description'));
						$('#select2-erp_field-container').html($div.data('erp_field_description'));

						$("#erp_operator").val($div.data('erp_operator'));
						$('#select2-erp_operator-container').attr('title', $div.data('erp_operator_description'));
						$('#select2-erp_operator-container').html($div.data('erp_operator_description'));

						$("#erp_expression").val($div.data('erp_expression'));
					}
				});

                $('input').keyup(function () {
                    if ($("#" + this.id).hasClass('input-error')) {
                        $("#" + this.id).removeClass('input-error');
                        $("#" + this.id).css('border-color', '#ebedf2');
                        $(".form-group:has(#" + this.id + ")").removeClass('has-danger');
                    }
                });
			},
			getDescriptionConfig: function (config) {
				return 'Banco: ' + config.code_bank +  ' - Empresa: ' + (config.name || 'Todas');
			},
			save: function () {
				var arrayData = [], arrayDataFilter = [], arrayDataFilterErp = [], exitButton = true, fieldName = '', i = 0, post = '', id = '', totExpression = 0, totOperator = 0, totOperatorCap = 0;
				var btn = $(this);

				btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

				$.each($('input, select', '#frmGrouper, #conciliacaoautomatica, #conciliacaomanual'), function () {
					var $this = $(this);
					var name = $this.attr('name');
					id = $this.attr('id');

					if ($.trim(this.value) == '' && $this.attr('required') == 'required') {
						exitButton = true;
						fieldName = $this.attr('field-name');

						$(".form-group:has(#" + this.id + ")").addClass('has-danger');
						$("#" + this.id).addClass('input-error');
						$("#" + this.id).css('border-color', 'red');
						return false;
					} else {
						exitButton = false;
						arrayData[i] = {};

						if(name != "where_show"){
							arrayData[i][this.id] = this.value;
						}else{
							arrayData[i][this.id] = $this.val().join(";");
						}

						if(this.id == 'value_tolerance'){
							arrayData[i][this.id] = numeral($('#value_tolerance').val()).value();
						}
						if(this.id == 'manual_value_tolerance'){
							arrayData[i][this.id] = numeral($('#manual_value_tolerance').val()).value();
						}
					}
					i++;
				});

				if (exitButton) {
					btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					showNotification('Atenção', 'O campo <strong>' + fieldName + '</strong> não foi informado!', 'warning');
					return false;
				}

				i++;
				arrayData[i] = {};

				i = 0;
				$.each($('span.text', '#filters-iten-cnab'),function(){
					var $this = $(this);
					var type = "expression";
					var order = i + 1;

					if ($this.parent().hasClass('operator')) {
						if ($this.parent().data('operator') == 'AND' || $this.parent().data('operator') == 'OR') {
							totOperator++;
						} else {
							totOperatorCap++;
						}

						type = "operator";
					} else if (!$this.parent().hasClass('operator')) {
						totExpression++;
					}

					arrayDataFilter[i] = {};
					arrayDataFilter[i]['type']                 = type;
					arrayDataFilter[i]['order']                = order;
					arrayDataFilter[i]['field']                = (type=='expression' ? $this.parent().data('cnab_field') : "");
					arrayDataFilter[i]['field_description']    = (type=='expression' ? $this.parent().data('cnab_field_description') : "");
					arrayDataFilter[i]['operator']             = (type=='expression' ? $this.parent().data('cnab_operator') : $this.parent().data('operator'));
					arrayDataFilter[i]['operator_description'] = (type=='expression' ? $this.parent().data('cnab_operator_description') : $this.text());
					arrayDataFilter[i]['expression']           = (type=='expression' ? $this.parent().data('cnab_expression') : "");

					i++;
				});

				if (i > 0) {
					if (totExpression > 1 && (totExpression != totOperator+1 || totOperatorCap%2 != 0)) {
						btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						showNotification('Atenção', 'A quantidade de operadores informada está incorreta!', 'warning');
						return false;
					}
				}

				i = 0;
				totOperator = 0;
				totExpression = 0;
				totOperatorCap = 0;

				$.each($('span.text', '#filters-iten-erp'),function(){
					var $this = $(this);
					var type = "expression";
					var order = i + 1;

					if ($this.parent().hasClass('operator')) {
						if ($this.parent().data('operator') == 'AND' || $this.parent().data('operator') == 'OR') {
							totOperator++;
						} else {
							totOperatorCap++;
						}

						type = "operator";
					} else if (!$this.parent().hasClass('operator')) {
						totExpression++;
					}

					arrayDataFilterErp[i] = {};
					arrayDataFilterErp[i]['type']                 = type;
					arrayDataFilterErp[i]['order']                = order;
					arrayDataFilterErp[i]['field']                = (type=='expression' ? $this.parent().data('erp_field') : "");
					arrayDataFilterErp[i]['field_description']    = (type=='expression' ? $this.parent().data('erp_field_description') : "");
					arrayDataFilterErp[i]['operator']             = (type=='expression' ? $this.parent().data('erp_operator') : $this.parent().data('operator'));
					arrayDataFilterErp[i]['operator_description'] = (type=='expression' ? $this.parent().data('erp_operator_description') : $this.text());
					arrayDataFilterErp[i]['expression']           = (type=='expression' ? $this.parent().data('erp_expression') : "");

					i++;
				});

				if (i > 0) {
					if (totExpression > 1 && (totExpression != totOperator+1 || totOperatorCap%2 != 0)) {
						btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						showNotification('Atenção', 'A quantidade de operadores informada está incorreta!', 'warning');
						return false;
					}
				}
				if(this.conciliation_action_config_id != 'new') arrayData.push({id: this.conciliation_action_config_id});

				$.ajax({
					method: "POST",
					url: this.$store.state.API_BASE + 'conciliation/action/' + (this.conciliation_action_config_id == 'new' ? 'new' : 'edit'),
					headers: {
						'token': this.$session.get('token')
					},
					data: {
						arrayData: JSON.stringify(arrayData),
						arrayDataFilter: JSON.stringify(arrayDataFilter),
						arrayDataFilterErp: JSON.stringify(arrayDataFilterErp)
					}
				}).done((result) => {
					btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

					if (!result.error) {
						showNotification('Sucesso', 'Nova ação da conciliação registrada!', 'success');

						$.each($('input', '#frmGrouper'), () => {
							this.value = '';
						});

						this.$router.replace({name: 'conciliationactionconfig'});
					} else {
						showNotification('Atenção', result.error, 'danger');
						return false;
					}
				}).fail((error)  =>{
					btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					console.log(error.responseText);
				});
			}
        }
    }
</script>
