<template>
	<v-app-bar class="noPrint" style="z-index:1049" dense dark fixed clipped-left color="#2c2e3e" app>
		<!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

		<v-toolbar-title>
			<v-img src="@/assets/inncash-logo-white.webp" width="104"></v-img>
		</v-toolbar-title>

		<v-spacer></v-spacer>

		<night-switch/>

		<importer-menu/>

		<notifications-menu/>

		<user-profile-menu/>
	</v-app-bar>
</template>

<script>

import UserProfileMenu from '@/components/userProfile/UserProfileMenu.vue';
import ImporterMenu from '@/components/importer/ImporterMenu.vue';
import NightSwitch from '@/components/night_switch/NightSwitch.vue';

import NotificationsMenu from './NotificationsMenu.vue';

export default {
	name: 'Toolbar',
	data: () => ({
		buttons: {
			importer: {
				title: 'Importação de Arquivos', icon: 'mdi-sync', component: 'ImporterMenu', class: '', props: false,
			},
		},
		user: {},
		avatar: null,
	}),
	components: {
		NotificationsMenu,
		UserProfileMenu,
		ImporterMenu,
		NightSwitch,
	},
};
</script>
