import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Ocorrências"}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VSelect,{attrs:{"filled":"","dense":"","hide-details":"","items":_vm.filterStatusItems},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"5","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"filled":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"Pesquisar"},model:{value:(_vm.filter.term),callback:function ($$v) {_vm.$set(_vm.filter, "term", $$v)},expression:"filter.term"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c('list-add-button',{on:{"click":_vm.add}})],1)],1),_c(VCardText,{staticClass:"pa-6 pt-0"},[_c('occurrences-table',{attrs:{"occurrences":_vm.occurrences,"filter":_vm.filter}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }