<template>
		<v-row>
			<v-col>
				<v-card class="elevation-0 pa-9">
					<template v-if="userAccessDashboadPowerBi">
						<iframe
							:src="biUrl"
							name="Innova Connect Sistemas"
							id="iframe"
							style='height: calc(120vh - 142px); width: auto; min-width: 100%; overflow: scroll;'
							frameborder="0"
							marginheight="20"
							marginwidth="35"/>
						<div 
							class="hidden-share-btns" 
							v-if="showPowerBi"/>
					</template>
					<template v-else>
						<dashboard-welcome/>
					</template>
				</v-card>
			</v-col>
		</v-row>
</template>

<script>
import PageContainer from '../../../src/components/defaults/PageContainer.vue';
import DashboardWelcome from './components/dashboard_welcome';

export default {
	data() {
		return {
			showPrograms: false,
			accessPrograms: [],
			userAccessDashboadPowerBi: false,
			urlPowerBi: "",
			showPowerBi: false,
		}
	},

	components: {
		DashboardWelcome,
		PageContainer,
	},

	mounted: function () {
		let self = this;

		self.userAccessDashboadPowerBi = self.$session.get('user_access-view_dashboard_power_bi') == "1" ? true : false;
		
		//Carrega rotinas apenas se o usuário não tem permissão de visualização do dashboard.
		if (!self.userAccessDashboadPowerBi) {
			self.loadPrograms();
			return;
		}


		setTimeout(() => {
			this.showPowerBi = true;
		}, 1000);
	},
	computed: {
		biUrl() {
			let self = this;

			self.userAccessDashboadPowerBi = self.$session.get('user_access-view_dashboard_power_bi') == "1" ? true : false;
			
			//Carrega rotinas apenas se o usuário não tem permissão de visualização do dashboard.
			if (!self.userAccessDashboadPowerBi) {
					return '';
			}
			
			if (self.$session.get('url_powerbi')) {
					return self.$session.get('url_powerbi');
			} 
					
			return this.$vuetify.theme.dark && self.$session.get('url_powerbi_dashboard_darkmode') ? self.$session.get('url_powerbi_dashboard_darkmode') : self.$session.get('url_powerbi_dashboard');
		}
	},

	methods: {
		loadPrograms: function (userGroupId) {
			if (!this.userDefined()) {
					return;
			}

			var self = this;
			var userGroupId = self.$session.get('user_group_id');

			if (userGroupId) {
				blockPage('Carregando os dados...');

				self.$http.get(self.$store.state.API_BASE + 'access/listAllAccessProgram/' + userGroupId, {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						self.accessPrograms = result.body.data;

						self.showPrograms = true;

						unblockPage();
					},
					function (error) {
						console.log('dashboard  - ', error);
						unblockPage();
					}
				).bind(self)
			}
		},
		userDefined() {
				return this.$session && this.$session.get('token');
		},
	}
}
</script>

<style scoped>
.hidden-share-btns {
	width: 240px;
	background-color: rgb(233 233 233);
	z-index: 1;
	position: relative;
	height: 34px;
	display: block;
	float: right;
	margin: 0 0 0 auto;
	margin-bottom: 64px;
	transform: translate(0px, -100%);
}
</style>