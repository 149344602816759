import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSubheader,{staticClass:"mb-6 mt-2"},[(_vm.returnPage == true)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""},on:{"click":_vm.returnToPreviousRoute}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-arrow-left ")])],1)]}}],null,false,763241943)},[_c('span',[_vm._v("Voltar")])])],1):_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.goToHomePage}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-home-outline ")])],1)]}}])},[_c('span',[_vm._v("Página inicial")])])],1),_c('span',{staticClass:"text-h6 font-weight-bold ml-3"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }