<template>
  <div v-if="show">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ScrollingHideComponent',
  props: {
    show: Boolean,
    scrollTo: Number,
  },
  methods: {
    onClose() {
      setTimeout(() => {
        console.log(this.scrollTo);
        window.scrollTo({
          top: this.scrollTo,
          left: 0,
          behavior: 'smooth',
        });
      }, 200);
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.onClose();
      }
    },
  },
};
</script>
