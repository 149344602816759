import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Retorno Bancário"}}),_c(VCard,{staticClass:"elevation-0 mb-5"},[_c(VCardTitle,[_c(VCol,{attrs:{"lg":"6","md":"6","sm":"12","xs":"12"}},[_c('cnab-return-select-client-folder',{on:{"change":_vm.setDirectory},model:{value:(_vm.directory),callback:function ($$v) {_vm.directory=$$v},expression:"directory"}})],1),_c(VSpacer),_c(VCol,{attrs:{"lg":"3","md":"4","sm":"12","xs":"12"}},[_c(VBtn,{attrs:{"color":"info","block":"","large":"","depressed":""},on:{"click":function($event){return _vm.toFiles()}}},[_vm._v(" ARQUIVOS ENVIADOS ")])],1)],1)],1),_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"VueDropZone","options":_vm.dropzoneOptions,"use-custom-slot":true},on:{"vdropzone-error":_vm.onError,"vdropzone-success":_vm.onSuccess}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('img',{attrs:{"src":"/assets/img/1031498-128.png"}}),_c('br'),_c('br'),_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v("Solte os arquivos aqui")]),_c('div',{staticClass:"subtitle"},[_vm._v("...ou clique para selecionar um arquivo.")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }