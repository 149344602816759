<template>
    <div class="modal fade" id="modalViewInformation" tabindex="-1" role="dialog" aria-labelledby="modalViewInformation" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="title_data_view_information">
                        {{getTitle()}}
                    </h5>
                </div>
                <form id="form_data_view_information" class="m-form m-form--fit m-form--label-align-right">
                    <div id="data_view_information" style="padding: 0px 25px 0px 25px;" class="modal-body"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn m-btn--air btn-primary" id="btnModalClose" data-dismiss="modal">Fechar</button>
                    </div>
                </form>
            </div>          
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {}
            }
        },
        methods: {
            openModal(config){
                this.config = {};

                config.grouperTwoId = config.grouperTwoId || 0;

                this.config = config;

                blockPage('Carregando os dados...');

                let url = this.$store.state.API_BASE;;

                switch (config.type) {
                    case 'PV':
                        url += `view/pedidovenda/${config.id}/${config.date}`;
                        break;
                    case 'CR':
                        url += `view/tituloreceber/${config.id}/${config.date}/${config.grouperTwoId}/0`;
                        break;
                    case 'CP':
                        url += `view/titulopagar/${config.id}/${config.date}/${config.grouperTwoId}/0`;
                        break;
                }

                this.$http.get(url, {headers: {'token': this.$session.get('token')}, params: { 'origin': config.origin }}).then(
                    (result) => {
                        switch (config.type) {
                            case 'PV':
                                $('#data_view_information').html(this.getViewPedidoVenda(result.data.data));
                                break;
                            case 'CR':
                                $('#data_view_information').html(this.getViewTituloReceber(result.data.data));
                                break;
                            case 'CP':
                                $('#data_view_information').html(this.getViewTituloPagar(result.data.data));
                                break;
                        }

                        $('#modalViewInformation').modal('show');

                        unblockPage();
                    },
                    (error) => {
                        showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                )        
            },
            getTitle(){
                let title;

                switch (this.config.type) {
                    case 'PV':
                        title = 'Dados do Pedido de Venda';
                        break;
                    case 'CR':
                        title = 'Dados de Títulos a Receber';
                        break;
                    case 'CP':
                        title = 'Dados de Títulos a Pagar';
                        break;
                }
                
                return title;
            },   
            getViewPedidoVenda: function (data) {
                let html = '';

                html += '<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">';
                html += '<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">';
                html += '<li class="nav-item m-tabs__item">';
                html += '<a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab" aria-expanded="true">Geral</a>';
                html += '</li>';
                html += '<li class="nav-item m-tabs__item">';
                html += '<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab" aria-expanded="false">Log</a>';
                html += '</li>';
                html += '</ul>';
                html += '<div class="tab-content">';
                html += '<div class="tab-pane active" id="tab1" role="tabgeneral">';
                html += '<div class="row">';
                //$.each(data, function (index) {
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Empresa/Filial:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].filial + (data[0].branchName ? ' (' + data[0].branchName + ')' : '') + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Número:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].numero + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Razão:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].razao_cliente + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Nome:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].nome_cliente + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">CNPJ:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].cnpj_cliente + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Valor original:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-2">';
                    html += '		<label class="control-label">' + data[0].valor_original + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-3">';
                    html += '		<label class="control-label">Valor alterado:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-3">';
                    html += '		<label class="control-label">' + data[0].valor_alterado + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Desconto:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].desconto + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Condição de pagamento:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].condicao_pagamento + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Quantidade vendida:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].quantidade + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Quantidade faturada:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].quantidade_faturada + '</label>';
                    html += '	</div>';
                //});
                html += '</div>';
                html += '</div>';
                html += '<div class="tab-pane" id="tab2" role="tablog">';
                $.each(data, function () {
                    $.each(this.data_log, function () {
                        html += '<div class="row"><div class="col-md-12">';
                        html += '[' + this.date_time + '] ' + this.user_name + ' - ' + this.description;
                        html += '</div></div>';
                    });
                });
                html += '</div>';
                html += '</div>';
                html += '</div>';

                return html;
            },
            getViewTituloReceber: function (data) {
                let html = '';
                if(data.length > 0) {
                    html += '<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">';
                    html += '<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">';
                    html += '<li class="nav-item m-tabs__item">';
                    html += '<a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab" aria-expanded="true">Geral</a>';
                    html += '</li>';
                    html += '<li class="nav-item m-tabs__item">';
                    html += '<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab" aria-expanded="false">Log</a>';
                    html += '</li>';
                    html += '</ul>';
                    html += '<div class="tab-content">';
                    html += '<div class="tab-pane active" id="tab1" role="tabgeneral">';
                    html += '<div class="row">';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Empresa/Filial:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + (data[0].filial != '' ? data[0].filial + '/' : data[0].table_company_code + '/') + data[0].filial_origem + (data[0].branchName != '' ? ' (' + data[0].branchName + ')' : '') + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Título:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].prefixo + ' ' + data[0].numero + ' ' + data[0].parcela + ' ' + data[0].tipo + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Razão social:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].razao_cliente + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Nome fantasia:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].nome_cliente + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">CNPJ:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].cnpj_cliente + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Valor original:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-2">';
                    html += '		<label class="control-label">' + data[0].valor_original + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-3">';
                    html += '		<label class="control-label">Valor alterado:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-3">';
                    html += '		<label class="control-label">' + data[0].valor_alterado + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Acréscimo:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-2">';
                    html += '		<label class="control-label">' + data[0].acrescimo + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-3">';
                    html += '		<label class="control-label">Valor de juros:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-3">';
                    html += '		<label class="control-label">' + data[0].valor_juros + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Decréscimo:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].decrescimo + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Valor total:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].valor_total + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Saldo:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].saldo + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Natureza de Operação:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].codigo_natureza + ' - ' + data[0].descricao_natureza + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Data de emissão:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].data_emissao + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Data de vencimento:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].data_vencimento + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Data de vencimento real:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].data_vencimento_real + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Data do fluxo:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].data_fluxo + '</label>';
                    html += '	</div>';                          
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Observação:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].observacao + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Portador:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].portador + '</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-4">';
                    html += '		<label class="control-label">Borderô:</label>';
                    html += '	</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<label class="control-label">' + data[0].bordero + '</label>';
                    html += '	</div>';
                    html += '</div>';
                    html += '</div>';
                    html += '<div class="tab-pane" id="tab2" role="tablog">';
                    $.each(data, function () {
                        $.each(this.data_log, function () {
                            html += '<div class="row"><div class="col-md-12">';
                            html += '[' + this.date_time + '] ' + this.user_name + ' - ' + this.description;
                            html += '</div></div>';
                        });
                    });
                    html += '</div>';
                    html += '</div>';
                    html += '</div>';
                } else {
                    html += '<div class="row">';
                    html += '	<div class="col-md-12" style="text-align:center">';
                    html += '		<label style="padding:30px; color: #999999" class="control-label"> Não há informações disponíveis </label>';
                    html += '	</div>';
                    html += '</div>';
                }
                return html;
            },            
            getViewTituloPagar: function (data) {
                let html = '';
                let dadosBancarios = '';
                let origem = '';

                html += '<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">';
                html += '<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">';
                html += '<li class="nav-item m-tabs__item">';
                html += '<a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab" aria-expanded="true">Geral</a>';
                html += '</li>';

                if (origem && origem.substring(0, 4) == "MATA" && data[0].total_data_nota > 0) {
                    html += '<li class="nav-item m-tabs__item">';
                    html += '<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab" aria-expanded="true">Dados NF</a>';
                    html += '</li>';
                }

                if (data[0].advancePaymentProducts && data[0].advancePaymentProducts.length > 0) {
                    html += '<li class="nav-item m-tabs__item">';
                    html += '<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab4" role="tabpedido" aria-expanded="true">Dados Pedido</a>';
                    html += '</li>';
                }

                html += '<li class="nav-item m-tabs__item">';
                html += '<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab3" role="tab" aria-expanded="false">Log</a>';
                html += '</li>';
                html += '</ul>';
                html += '<div class="tab-content">';
                html += '<div class="tab-pane active" id="tab1" role="tabgeneral">';
                html += '<div class="row">';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Empresa/Filial:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + (data[0].filial != '' ? data[0].filial + '/' : data[0].table_company_code + '/') + data[0].filial_origem + (data[0].branchName != '' ? ' (' + data[0].branchName + ')' : '') + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Título:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].prefixo + ' ' + data[0].numero + ' ' + data[0].parcela + ' ' + data[0].tipo + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Razão social:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].razao_fornecedor + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Nome fantasia:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].nome_fornecedor + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">CNPJ:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].cnpj_fornecedor + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Valor original:</label>';
                html += '	</div>';
                html += '	<div class="col-md-2">';
                html += '		<label class="control-label">' + data[0].valor_original + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-3"';
                html += '		<label class="control-label">Valor alterado:</label>';
                html += '	</div>';
                html += '	<div class="col-md-3">';
                html += '		<label class="control-label">' + data[0].valor_alterado + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Acréscimo:</label>';
                html += '	</div>';
                html += '	<div class="col-md-2">';
                html += '		<label class="control-label">' + data[0].acrescimo + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-3">';
                html += '		<label class="control-label">Valor de juros:</label>';
                html += '	</div>';
                html += '	<div class="col-md-3">';
                html += '		<label class="control-label">' + data[0].valor_juros + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Decréscimo:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].decrescimo + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Valor total:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].valor_total + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Abatimento:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].abatimento + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Retenção PCC:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].retencao_pcc + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Saldo:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].saldo + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Natureza de Operação:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].codigo_natureza + ' - ' + data[0].descricao_natureza + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Data de emissão:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].data_emissao + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Data de vencimento:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].data_vencimento + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Data de vencimento real:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].data_vencimento_real + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Data do fluxo:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].data_fluxo + '</label>';
                html += '	</div>';                
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Observação:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].observacao + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Forma de pagamento:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                if (data[0].e2_codbar !== '') {
                    html += '<div class="row">';
                    html += '<div class="col-md-1">';
                    html += '<i class="la la-barcode text-red" style="margin-right: 5px;font-size: 27px;" title="Codigo de barras preenchido"></i>';
                    html += '</div>';
                    html += '<div class="col-md-11" style="padding-top: 3px;">';
                    html += '		<label class="control-label">' + data[0].forma_pagamento + '</label>';
                    html += '</div>';
                    html += '</div>';
                } else {
                    html += '		<label class="control-label">' + data[0].forma_pagamento + '</label>';
                }
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Dados bancários:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].dados_bancarios + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Portador:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].e2_portado + '</label>';
                html += '	</div>';
                html += '	<div class="col-md-4">';
                html += '		<label class="control-label">Borderô:</label>';
                html += '	</div>';
                html += '	<div class="col-md-8">';
                html += '		<label class="control-label">' + data[0].E2_NUMBOR + '</label>';
                html += '	</div>';
                html += '</div>';
                html += '</div>';
                
                if (origem && origem.substring(0, 4) == "MATA" && data[0].total_data_nota > 0) {
                    html += '<div class="tab-pane" id="tab2" role="tabnf">';
                    html += '<div class="row">';
                    html += '	<div class="col-md-12">';
                    html += '		<label class="control-label">Mensagem NF: ' + data[0].mensagem_nota + '</label>';
                    html += '	</div>';
                    html += '   <table class="table table-striped m-table" style="margin-top:15px;">';
                    html += '       <thead>';
                    html += '           <tr>';
                    html += '               <th width="10%">Item</th>';
                    html += '               <th>Produto</th>';
                    html += '               <th width="15%" class="text-right">Qtd.</th>';
                    html += '               <th width="15%" class="text-right">Vlr.</th>';
                    html += '               <th width="15%" class="text-right">Total</th>';
                    html += '           </tr>';
                    html += '       </thead>';
                    html += '       <tbody>';
                    $.each(data[0].data_nota, function () {
                        html += '       <tr>';
                        html += '           <td>' + this.d1_item + '</td>';
                        html += '           <td>' + this.d1_cod + ' - ' + this.d1_descri + '</td>';
                        html += '           <td class="text-right">' + numeral(this.d1_quant).format('0,0.00') + '</td>';
                        html += '           <td class="text-right">' + numeral(this.d1_vunit).format('0,0.00') + '</td>';
                        html += '           <td class="text-right">' + numeral(this.d1_total).format('0,0.00') + '</td>';
                        html += '       </tr>';
                    });
                    html += '       </tbody>';
                    html += '   </table>';
                    html += '</div>';
                    html += '</div>';
                }

                if (data[0].advancePaymentProducts && data[0].advancePaymentProducts.length > 0) {
                    html += '<div class="tab-pane" id="tab4" role="tabpedido">';
                    html += '<div class="row">';
                    html += '   <table class="table table-striped m-table" style="margin-top:15px;">';
                    html += '       <thead>';
                    html += '           <tr>';
                    html += '               <th width="10%">Item</th>';
                    html += '               <th>Produto</th>';
                    html += '               <th width="15%" class="text-right">Qtd.</th>';
                    html += '               <th width="15%" class="text-right">Vlr.</th>';
                    html += '               <th width="15%" class="text-right">Total</th>';
                    html += '           </tr>';
                    html += '       </thead>';
                    html += '       <tbody>';

                    $.each(data[0].advancePaymentProducts, function () {
                        html += '       <tr>';
                        html += '           <td>' + this.item + '</td>';
                        html += '           <td>' + this.product + ' - ' + this.product_description + '</td>';
                        html += '           <td class="text-right">' + numeral(this.quantity).format('0,0.00') + '</td>';
                        html += '           <td class="text-right">' + numeral(this.price).format('0,0.00') + '</td>';
                        html += '           <td class="text-right">' + numeral(this.total).format('0,0.00') + '</td>';
                        html += '       </tr>';
                    });

                    html += '       </tbody>';
                    html += '   </table>';
                    html += '</div>';
                    html += '</div>';
                }

                html += '<div class="tab-pane" id="tab3" role="tablog">';
                $.each(data, function () {
                    $.each(this.data_log, function () {
                        html += '<div class="row"><div class="col-md-12">';
                        html += '[' + this.date_time + '] ' + this.user_name + ' - ' + this.description;
                        html += '</div></div>';
                    });
                });
                html += '</div>';
                html += '</div>';
                html += '</div>';

                return html;
            }       
        }        
    }
</script>


<style scoped>

</style>
