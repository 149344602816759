<template>
  <v-text-field
    v-if="enabled"
    v-model="time"
    v-mask="timeMask"
    filled
    prepend-inner-icon="mdi-clock"
    v-bind="$attrs"
    :rules="rules"
    dense
    @blur="handleBlur"
  ></v-text-field>
</template>

<script>
export default {
  name: 'InnTimeField',
  props: {
    value: String,
    required: {
      type: Boolean,
      default: false,
    },
    shouldBeGreaterDate: String,
    shouldBeGreaterTime: String,
  },
  data() {
    return {
      enabled: true,
      time: this.value,
    };
  },
  watch: {
    value() {
      this.time = this.value;
      this.enabled = false;

      const vm = this;
      this.$nextTick(() => {
        vm.enabled = true;
      });
    },
  },
  computed: {
    rules() {
      const rules = [];
      const vm = this;

      if (this.required) rules.push((v) => !!v || 'Esse campo é obrigatório');

      if (this.$moment(this.shouldBeGreaterDate).isValid()) {
        const now = this.$moment();
        const daySelected = this.$moment(this.shouldBeGreaterDate);

        // if is today, apply rule to be greater then actual hour
        if (daySelected.diff(now, 'days') == 0 && daySelected.date() == now.date()) {
          rules.push(
            (v) => vm.$moment(v, 'HH:mm').diff(now, 'minutes') > 0 || 'Não é permitido horário passado',
          );
        }
      }

      if (this.$moment(this.shouldBeGreaterTime, 'HH:mm').isValid()) {
        const timeSelected = this.$moment(this.shouldBeGreaterTime, 'HH:mm');

        rules.push(
          (v) => vm.$moment(v, 'HH:mm').diff(timeSelected, 'minutes') > 0
            || 'Escolha um intervalo maior',
        );
      }

      return rules;
    },
  },
  methods: {
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ':', ...minutes] : hours;
    },
    handleBlur() {
      this.$emit('input', this.time);
    },
  },
};
</script>

<style></style>
