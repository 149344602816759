<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Cadastro de Bloqueios"/>
			<v-card class="elevation-0">
				<v-card-title> 
					<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
							v-model="configs.filterStatus"
							filled
							dense
							hide-details
							:items="filterStatusItems"/>
					</v-col>
					<v-col cols="12" lg="6" md="5" sm="12" xs="12">
						<v-text-field
							filled
							dense
							hide-details
							prepend-inner-icon="mdi-magnify"
							placeholder="Pesquisar"
							v-model="configs.filterTerm"
						/>
					</v-col>
					<v-spacer/>
					<v-col cols="12" lg="2" md="3" sm="12" xs="12">
						<list-add-button @click="add"/>
					</v-col>
				</v-card-title>
				<v-card-text class="pa-6 pt-0">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Grupo</th>
								<th>Tipo</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<!-- eslint-disable-next-line -->
							<tr v-for="block in listData">
								<td style="vertical-align: middle;">
										<v-chip
										v-if="block.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td style="vertical-align: middle;">{{ block.user_group_name }}</td>
								<td style="vertical-align: middle;">{{ block.type_description }}</td>
								<td class="m--align-right">
									<table-edit-button @click="edit(block)"/>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash';
	import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
	import ListAddButton from '@/components/buttons/ListAddButton.vue';
	import tableEditButton from '@/components/buttons/tableEditButton.vue';

	export default {
		components: {
			Breadcrumbs,
			ListAddButton,
			tableEditButton,
		},
		data () {
			return {
				blocks: [],
				configs: {
					filterTerm: '',
					filterStatus: ''
				},
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				]
			}
		},

		computed: {
			listData() {
				const self = this;
				const filterStatus = self.configs.filterStatus;
				const filterTerm = self.configs.filterTerm.toLowerCase();
				var dataRet = this.blocks;

				if (!_.isEmpty(filterStatus)) {
					dataRet = _.filter(dataRet, function(data) { return data.status == filterStatus; })
				}

				if (!_.isEmpty(filterTerm)) {
					dataRet = _.filter(dataRet, list => list.user_group_name.toLowerCase().indexOf(filterTerm) >= 0 || list.type_description.toLowerCase().indexOf(filterTerm) >= 0)
				}
				return dataRet
			}
		},

		mounted: function () {
			this.loadBlocks();
		},

		methods: {
			loadBlocks: function () {
				blockPage('Carregando os dados...');

				this.$http.get(this.$store.state.API_BASE+'block/listAll', {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						this.blocks = result.body.data
						unblockPage();
					},
					function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				).bind(this)
			},
			add() {
				this.$router.push('/block/new');
			},	
			edit(block) {
				const params = {
					block_id: block.id,
				};
				this.$router.push({name: 'editblock',params});
			},
		}
	}
</script>
