import Rest from './base/Rest';

export default class JobService extends Rest {
	static resource = 'job';

	import() {
		this.relationship = 'callErp';

		return this;
	}
}
