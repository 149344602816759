<template>
  <span class=" d-flex align-center">
    <custom-switch
      name="autoToggleParamsSelect"
      id="autoToggleParamsSelect"
      :value="value"
      @input="changeAutoToggleParams"
    />
    <label for="autoToggleParamsSelect" class="hide-parameters-label">Esconder parâmetros</label>
  </span>
</template>

<script>
import CustomSwitch from './CustomSwitch.vue';

export default {
	components: { CustomSwitch },
  name: 'HideParamnsSwitch',
  props: {
    value: Boolean,
    paramName: String,
  },
  methods: {
    changeAutoToggleParams() {
      const newState = !this.value;
      this.$http.patch(
        `${this.$store.state.API_BASE}fieldparameter/${this.paramName}`,
        { value: `${newState}`, column: this.paramName },
        {
          headers: { token: this.$session.get('token') },
        },
      )
      .catch(() => {
        // eslint-disable-next-line no-undef
        showNotification(
          'Atenção',
          'Opa! Parece que algo saiu errado, tente recarregar a página',
          'danger',
        );
      });
      this.$emit('input', newState);
    },
  },
};
</script>

<style>
.hide-parameters-label {
	width: 90px;
	margin-left: 5px;
	line-height: 1.2;
	padding-top: 6px;
	text-align: left;
	cursor: pointer;
}
</style>
