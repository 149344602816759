<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'grouper' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
	            <div class="row" id="frmGrouper">
	            	<input type="hidden" name="company_group_id" id="company_group_id" v-model="company_group_id">
	            	<input type="hidden" name="grouper_id" id="grouper_id" v-model="grouper_id">
	                <div class="col-md-12">
	                    <div class="form-group">
	                        <label>Empresa</label>
	                        <select class="form-control select2 hide" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;">
	                            <option value="">Todas</option>
	                            <option v-for="company in companys" :value="company.id">{{ company.name }}</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tipo</label>
	                        <select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="I">Saldo inicial</option>
	                            <option value="E">Entrada</option>
	                            <option value="S">Saída</option>
	                        </select>
	                    </div>
	                </div>
					<div class="col-md-2">
	                    <div class="form-group">
	                        <label>Código ERP</label>
	                        <input
								id="erp_code"
								class="form-control"
								name="erpCode"
								type="text"
								maxlength="15"
								field-name="Código ERP"
							>
	                    </div>
					</div>
	                <div class="col-md-6">
	                    <div class="form-group">
	                        <label>Descrição</label>
	                        <input type="text" class="form-control" field-name="Descrição" id="description" name="description" required>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tabela</label>
	                        <select class="form-control select2 hide" field-name="Tabela" id="table_type_id" name="table_type_id" style="width: 100%;" required>
								<option value="">Selecione...</option>
								<option v-for="table in tables" :value="table.id">{{ table.description }}</option>
	                        </select>
	                    </div>
	                </div>
					<div class="col-md-3">
						<div class="form_group">
							<label>Onde Mostrar?</label>
							<select class="form-control select2 hide" field-name="Onde Mostrar" id="where_show" required
									name="where_show" style="width: 100%;" multiple="multiple">
								<option value="1">Fluxo Previsto</option>
								<option value="2">Fluxo Realizado</option>
								<option value="4">Fluxo Projetado</option>
								<option value="3">Faturamento</option>
							</select>
						</div>
					</div>
	                <div class="col-md-3">
	                    <div class="form-group">
	                        <label>Status</label>
	                        <select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;">
	                            <option value="0">Inativo</option>
	                            <option value="1">Ativo</option>
	                        </select>
	                    </div>
	                </div>

	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
            	companys: [],
                tables:[],
            	company_group_id: this.$session.get('company_group_id'),
            	grouper_id: this.$route.params.grouper_id,
				whereShow:"",
            }
        },

        mounted: function () {
            this.loadTables();
            this.loadCompanys();
            this.loadMethod();
        	this.loadGrouper();
        },

        methods: {
            loadCompanys: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(this.$store.state.API_BASE+'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {
	                    this.companys = result.body.data
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                	}
                ).bind(this)
            },

        	loadGrouper: function () {
        		var self = this;

				$.ajax({
					method: "GET",
					url: self.$store.state.API_BASE+'grouper/listOne/'+self.$route.params.grouper_id,
					headers: {
	                    'token': self.$session.get('token')
					}
				}).done(function(result) {
					let statusDesc = "", tableDesc = "", whereShow = [];
					let tableTypeId = result.data[0].table_type_id;

					$('#company_id').val(result.data[0].company_id);
					$('#type').val(result.data[0].type);
					$('#description').val(result.data[0].description);
					$('#table_type_id').val(tableTypeId);
					$('#status').val(result.data[0].status);
					$('#erp_code').val(result.data[0].erp_code);

					$('#select2-company_id-container').attr('title', result.data[0].company_name);
					$('#select2-company_id-container').html(result.data[0].company_name);

					$('#select2-type-container').attr('title', result.data[0].type_description);
					$('#select2-type-container').html(result.data[0].type_description);


                    let table = self.tables.find((table) => {
                        return table.id == tableTypeId
                    });



                    $('#select2-table_type_id-container').attr('title', table.description);
                    $('#select2-table_type_id-container').html(table.description);

					if (result.data[0].status == 1) {
						statusDesc = "Ativo";
					} else {
						statusDesc = "Inativo";
					}
					$('#select2-status-container').attr('title', statusDesc);
					$('#select2-status-container').html(statusDesc);


                    whereShow = result.data[0].where_show.split(';');
                    $('#where_show').val(whereShow);
                    $('#where_show').trigger('change');

	            	unblockPage();
				}).fail(function(error){
					console.log(error.responseText);
	            	$("#loading").css('display', 'none');
				});
        	},

            loadMethod: function () {
            	var self = this;
				$(".select2").select2();
				$("#cnpj").mask("00.000.000/0000-00");
				$("#phone").mask("(00) 00000-0000");
				$("#zipcode").mask("0000-000");

                $("#where_show").change(function () {
                    self.whereShow =  $("#where_show").val();
                });

				$("#btn-save").click(function(){
					var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
		            var btn = $(this);

		            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					$.each($('input, select', '#frmGrouper'),function(){
					    var $this = $(this);
                        var name = $this.attr('name');
                        fieldName = $this.attr('field-name');

					    if($.trim(this.value) == '' && $this.attr('required') == 'required'){
					        exitButton = true;

					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }else{
                            exitButton = false;
                            arrayData[i] = {};
                            if(name == "where_show") {
                                arrayData[i][this.id] = $this.val().join(";");
                            }else{
                                arrayData[i][this.id] = this.value;
                            }
					    }

					    i++;
					});

					if(exitButton){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado!', 'warning');
					    return false;
					}

					$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE+'grouper/edit',
                        headers: {
                            'token': self.$session.get('token')
                        },
						data: {
							arrayData: JSON.stringify(arrayData)
						}
					}).done(function(result) {
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

						self.$router.replace({name: 'grouper'});

						if(result.error == false){
							showNotification('Sucesso', 'Agrupador alterado!','success');

							$.each($('input', '#frmGrouper'),function(){
								this.value = '';
							});
						}else{
							showNotification('Atenção',result.error, 'danger');
							return false;
						}
					}).fail(function(error){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            },

            loadTables: function () {
                blockPage('Carregando os dados...');

                this.$http.get(this.$store.state.API_BASE+'grouper/listTables', {headers: {'token': this.$session.get('token')}}).then(
                    function (result) {
                        this.tables = result.body.data
                        unblockPage();
                    },
                    function (error) {
                        showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                ).bind(this)
            },
        }
    }
</script>
