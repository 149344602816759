<template>
	<v-form v-model="valid" ref="add_form">
		<page-container no-column>
			<v-col cols="4" class="d-flex flex-column">
				<v-autocomplete
					label="Campo na API"
					v-model="toAddFieldDetail.field"
					:items="onlyOptionalFilters"
					item-value="id"
					item-text="field_name"
					return-object
					:rules="[ ...rules.required ]"
				/>
			</v-col>

			<v-col
				cols="1"
				class="d-flex flex-column pl-12 ml-n4"
			>
				<span class="mx-auto mb-n3 text-caption">
					Ativo
				</span>
				<status-checkbox
					centralize
					v-model="toAddFieldDetail.status"
				/>
			</v-col>

			<v-col
				cols="3"
				class="d-flex flex-column pl-12 mr-4"
			>
				<value-type-select
					centralize
					v-model="toAddFieldDetail.value_type"
				/>
			</v-col>

			<v-col cols="3" class="d-flex flex-column">
				<v-text-field
					label="Campo no Sistema"
					v-model="toAddFieldDetail.data_field"
					:rules="[ ...rules.required ]"
				/>
			</v-col>

			<v-col cols="1" class="d-flex flex-column mr-n8">
				<v-btn
					:disabled="!valid"
					icon
					@click="add"
					class="my-4 flex-item"
				>
					<v-icon> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</page-container>
	</v-form>
</template>

<script>
import PageContainer from '@/components/defaults/PageContainer.vue';
import StatusCheckbox from './StatusCheckbox.vue';
import ValueTypeSelect from './ValueTypeSelect.vue';

export default {
	components: {
		PageContainer,
		StatusCheckbox,
		ValueTypeSelect,
	},
	name: 'FieldsTableAdd',
	props: {
		bankingFields: [Array, Object],
		bankingFieldDetails: [Array, Object],
	},
	data() {
		return {
			toSave: {},
			name: '',
			toAddFieldDetail: {
				value_type: {
					text: 'Campo do sistema',
					value: 'TF',
				},
				status: 'A',
			},
			rules: {
				required: [(value) => !!value || 'Campo obrigatório.'],
			},
			valid: false,
			toClean: {
				data_field: '',
				fixed_value: 'N',
				value_type: {
					text: 'Campo do sistema',
					value: 'TF',
				},
				field: {},
				status: 'A',
			},
			fixedFieldAdjustment: 'pr-lg-11 pl-lg-0 ml-lg-n3',
		};
	},
	computed: {
		onlyOptionalFilters() {
			if (!this.bankingFields) {
				return [];
			}

			const detailFieldsIDs = this.getIdOfDetailsFields();

			return this.bankingFields
				.filter((field) => field.required != 'Y' && !detailFieldsIDs.includes(field.id));
		},
	},
	methods: {
		add() {
			const valid = this.$refs.add_form;

			if (!valid) {
				return;
			}

			this.$emit('add', this.toAddFieldDetail);

			this.toAddFieldDetail = { ...this.toClean };
			this.$refs.add_form.reset();
		},
		getIdOfDetailsFields() {
			const detailsIDs = [];

			if (!this.details) {
				return detailsIDs;
			}

			this.details.forEach((detail) => {
				detailsIDs.push(detail.field.id);
			});

			return detailsIDs;
		},
	},
};
</script>

<style>

</style>
