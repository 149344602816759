import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBadge,{staticClass:"notification-badge",attrs:{"color":_vm.badge.color,"value":_vm.badge.show,"overlap":"","dot":""}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"title":"Notificações","icon":"","large":""},on:{"click":_vm.onClickNotifications}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-bell-outline ")])],1)]}}])},[_c('notification-area')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }