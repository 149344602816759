<template>
  <v-slide-y-reverse-transition>
    <v-container fluid>
      <v-row class="mx-n6">
        <v-col lg="3" md="6" sm="6" xs="12">
          <date-select
            :disabledDates="{from: parseDate(searchData.emissionEndDate, 1)}"
            :value="searchData.emissionStartDate"
            label="Dt. Emissão Inicial"
            id="emissionStartDate"
            @change="onChange('emissionStartDate', $event)"
          />
        </v-col>
        <v-col lg="3" md="6" sm="6" xs="12">
          <date-select
            :disabledDates="{to: parseDate(searchData.emissionStartDate) }"
            :value="searchData.emissionEndDate"
            label="Dt. Emissão Final"
            id="emissionEndDate"
            @change="onChange('emissionEndDate', $event)"
          />
        </v-col>
        <v-col lg="3" md="6" sm="6" xs="12">
          <date-select
            :disabledDates="{from: parseDate(searchData.dueDateEndDate, 1)}"
            :value="searchData.dueDateStartDate"
            label="Dt. Vencimento Inicial"
            id="dueDateStartDate"
            @change="onChange('dueDateStartDate', $event)"
          />
        </v-col>
        <v-col lg="3" md="6" sm="6" xs="12">
          <date-select
            :disabledDates="{to: parseDate(searchData.dueDateStartDate) }"
            :value="searchData.dueDateEndDate"
            label="Dt. Vencimento Final"
            id="dueDateEndDate"
            @change="onChange('dueDateEndDate', $event)"
          />
        </v-col>
      </v-row>
      <v-row class="mx-n6">
        <v-col lg="3" md="6" sm="6" xs="12">
          <date-select
            :disabledDates="{from: parseDate(searchData.returnEndDate)}"
            :value="searchData.returnStartDate"
            label="Dt. Retorno Inicial"
            id="returnStartDate"
            @change="onChange('returnStartDate', $event)"
          />
        </v-col>
        <v-col lg="3" md="6" sm="6" xs="12">
          <date-select
            :disabledDates="{to: parseDate(searchData.returnStartDate) }"
            :value="searchData.returnEndDate"
            label="Dt. Retorno Final"
            id="returnEndDate"
            @change="onChange('returnEndDate', $event)"
          />
        </v-col>
        <v-col lg="6" md="6" sm="12" xs="12">
          <div class="form-group">
            <label>Empresa</label>
            <select
              class="form-control select2 hide"
              field-name="Empresa"
              v-model="company"
              id="company_id"
              name="company_id"
              style="width: 100%;"
            >
              <option value="">Selecione...</option>
              <option v-for="company in companys" :value="company.id" v-bind:key="company.id">{{
                company.name
              }}</option>
            </select>
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-n6">
        <v-col lg="3" md="6" sm="6" xs="12">
          <div class="form-group">
            <!-- <custom-select
              label="Tipo de Carteira"
              multiple
              placeholder="Deixe em branco para todos"
              id="payment-type-select"
              :value="searchData.paymentType"
              :options="paymentTypes"
              @input="onChange('paymentType', $event)"
            /> -->
            <label>Tipo de Carteira</label>
            <select
              class="form-control select2 hide"
              field-name="Tipo de Carteira"
              id="transaction_type"
              name="transaction_type"
              style="width: 100%;"
              multiple="multiple"
            >
              <option v-for="payments in paymentTypes" :value="payments.code" :key="payments.code">
                {{ payments.label }}
              </option>
            </select>
          </div>
        </v-col>
        <v-col lg="3" md="6" sm="6" xs="12">
          <div class="form-group">
            <label>Forma de Recebimento</label>
            <select
              class="form-control select2 hide"
              field-name="Forma de Recebimento"
              id="payment_method"
              name="payment_method"
              style="width: 100%;"
              multiple="multiple"
            >
              <option v-for="method in paymentMethods" :value="method.code" :key="method.code">
                {{ method.label }}
              </option>
            </select>
            <!-- <custom-select
              label="Forma de Recebimento"
              multiple
              placeholder="Deixe em branco para todos"
              id="payment-method-select"
              :value="searchData.paymentMethod"
              :options="paymentMethods"
              @input="onChange('paymentMethod', $event)"
            /> -->
          </div>
        </v-col>
        <v-col lg="6" md="6" sm="12" xs="12">
          <div class="form-group">
            <label>Cliente</label>
            <select
              class="form-control select2 hide"
              field-name="Cliente"
              id="registration_number"
              name="registration_number"
              style="width: 100%;"
              multiple="multiple"
            >
            </select>
          </div>
          <!-- <custom-select
            label="Cliente"
            multiple
            placeholder="Digite para pesquisar"
            id="client-select"
            :value="searchData.registrationNumber"
            :options="clients"
            :search="fetchClients"
            @input="
              onChange('registrationNumber', $event);
              clients = [];
            "
          /> -->
        </v-col>
      </v-row>
      <v-row class="mx-n6">
        <v-col lg="4" md="6" sm="6" xs="12">
          <!-- <div class="form-group">
            <custom-select
              multiple
              label="Portador"
              placeholder="Deixe em branco para todos"
              :value="searchData.bankCode"
              :options="banks"
              @input="onChange('bankCode', $event)"
            />
          </div> -->
          <div class="form-group">
            <label>Portador</label>
            <select
              class="form-control select2 hide"
              field-name="Portador"
              id="bank_code"
              name="bank_code"
              style="width: 100%;"
              multiple="multiple"
            >
              <option v-for="bank in banks" :value="bank.code" :key="bank.code">
                {{ bank.label }}
              </option>
            </select>
          </div>
        </v-col>
        <v-col lg="4" md="6" sm="6" xs="12">
          <div class="form-group">
            <label>Liberação</label>
            <select
              class="form-control select2 hide"
              field-name="Liberação"
              id="liberated"
              name="liberated"
              style="width: 100%;"
            >
              <option v-for="value in liberationItems" :value="value.code" :key="value.code">
                {{ value.label }}
              </option>
            </select>
            <!-- <custom-select
              label="Liberação"
              placeholder="Todos"
              :value="searchData.liberated"
              :options="liberationItems"
              @input="onChange('liberated', $event)"
            /> -->
          </div>
        </v-col>
        <v-col lg="4" md="6" sm="6" xs="12">
          <div class="form-group">
            <label>Ocorrência de Retorno</label>
            <select
              class="form-control select2 hide"
              field-name="Ocorrência de Retorno"
              id="occurency_code"
              name="occurency_code"
              style="width: 100%;"
              multiple="multiple"
            >
              <option
                v-for="occurrency in occurrences"
                :value="occurrency.code"
                :key="occurrency.code"
              >
                {{ occurrency.label }}
              </option>
            </select>
            <!-- <custom-select
              multiple
              label="Ocorrência de Retorno"
              placeholder="Deixe em branco para todos"
              :value="searchData.returnedOccurrency"
              :options="occurrences"
              @input="onChange('returnedOccurrency', $event)"
            /> -->
          </div>
        </v-col>
        <v-col lg="4" md="6" sm="6" xs="12">
          <div class="form-group">
            <label>Número Borderô</label>
            <select
              class="form-control select2 hide"
              field-name="Número Borderô"
              id="bordero_number"
              name="bordero_number"
              style="width: 100%;"
              multiple="multiple"
            >
              <option value="IS_NULL">
                Em Branco
              </option>
            </select>
            <!-- <custom-select
              multiple
              label="Ocorrência de Retorno"
              placeholder="Deixe em branco para todos"
              :value="searchData.returnedOccurrency"
              :options="occurrences"
              @input="onChange('returnedOccurrency', $event)"
            /> -->
          </div>
        </v-col>
        <v-col lg="4" md="6" sm="6" xs="12">
          <div class="form-group">
            <label>Status</label>
            <select
              class="form-control select2 hide"
              field-name="Status"
              id="document_status"
              name="document_status"
              style="width: 100%;"
            >
            <option v-for="value in documentStatusItems" :value="value.code" :key="value.code">
              {{ value.label }}
            </option>
            </select>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-slide-y-reverse-transition>
</template>

<script>
/* eslint-disable no-undef */
import DateSelect from '../../../components/fields/DateSelect.vue';
// import CustomSelect from '../../../components/fields/CustomSelect.vue';

const INITIAL_SELECT_VALUE = [{ code: 'IS_NULL', label: 'Em Branco' }];

export default {
  name: 'BillingRemittanceSearch',
  components: {
    DateSelect,
    // CustomSelect,
  },
  data() {
    return {
      paymentTypes: [],
      paymentMethods: [],
      clients: [],
      banks: [],
      liberationItems: [
        { code: 'ALL', label: 'Todos' },
        { code: 'NOT_LIBERATED', label: 'Não Liberados' },
        { code: 'LIBERATED', label: 'Liberados' },
      ],
      documentStatusItems: [
        { code: '', label: 'Todos' },
        { code: 'SENT', label: 'Enviados' },
        { code: 'NOT_SENT', label: 'Não Enviados' },
        { code: 'REJECTED', label: 'Rejeitados' },
      ],
      occurrences: INITIAL_SELECT_VALUE,
      loading: true,
      companys: [],
      company: '',
    };
  },
  props: {
    paramsVisibility: Boolean,
    searchData: Object,
    setSearchData: Function,
  },
  mounted() {
    $('#transaction_type').select2({ placeholder: 'Deixe em branco para todos' });
    $('#payment_method').select2({ placeholder: 'Deixe em branco para todos' });
    $('#bank_code').select2({ placeholder: 'Deixe em branco para todos' });
    $('#liberated').select2({ placeholder: 'Deixe em branco para todos' });
    $('#occurency_code').select2({ placeholder: 'Deixe em branco para todos' });
    $('#company_id').select2({ placeholder: 'Selecione uma Empresa' });
    $('#bordero_number').select2({ placeholder: 'Deixe em branco para todos', tags: true });
    $('#registration_number').select2({
      placeholder: 'Deixe em branco para todos',
      minimumInputLength: 3,
      ajax: {
        url: `${this.$store.state.API_BASE}erp/client/listAll`,
        data: (params) => {
          const id = this.company;
          return {
            search: params.term,
            companyId: id,
            limit: 15,
          };
        },
        headers: { token: this.$session.get('token') },
        processResults: (response) => {
          const blank = ' ';
          const line = ' - ';
          if (!response.data) {
            return { results: [] };
          }
          return {
            results: response.data.map((client) => ({
              text: client.a1_cod + blank + client.a1_loja + line + client.a1_nome,
              id: client.a1_cgc,
            })),
          };
        },
      },
    });
    $('#company_id').on('change', (e) => {
      this.company = $('#company_id').val();
      $('#registration_number').val(null).trigger('change');
      this.setSearchData({ companyId: e.target.value });
      this.loadPaymentMethods(0, e.target.value);
    });
  },
  created() {
    this.loadConfigs();
  },
  methods: {
    parseDate(date, plus) {
      if (!date) {
        return false;
      }
      return moment(date, 'DD/MM/YYYY').add(plus, 'days').toDate();
    },
    async loadConfigs() {
      blockPage('Carregando os dados...');
      try {
        this.loadBanks();
        this.loadCompanys();
        this.loadPaymentTypes();
        this.loadPaymentMethods(this.$session.get('company_group_id'), 0);
      } catch (error) {
        this.$helpers.defaultCatchError(error);
      } finally {
        unblockPage();
      }
    },
    loadCompanys() {
      this.$http
        .get(`${this.$store.state.API_BASE}company/listAllActv`, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            this.companys = result.body.data;
          },
        );
    },
    loadPaymentMethods(companyGroupId, id, value) {
      const setValue = () => {
        if (value) {
          $('#payment_method').val(value).change();
        }
      };
      this.$http
        .get(`${this.$store.state.API_BASE}billingmethod/listAll/${companyGroupId}/${id}`, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            if (!result.body.data || result.body.data.length <= 0) {
              setValue();
              return;
            }

            this.paymentMethods = INITIAL_SELECT_VALUE.concat(
              result.body.data.map((bank) => ({
                label: bank.description,
                code: bank.code,
              })),
            );
            setValue();
          },
        );
    },
    loadPaymentTypes() {
      this.$http
        .get(`${this.$store.state.API_BASE}financialdocumentsituation/listActives/${this.$session.get('company_group_id')}/0`, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            if (!result.body.data || result.body.data.length <= 0) {
              return;
            }

            this.paymentTypes = INITIAL_SELECT_VALUE.concat(
              result.body.data.map((situation) => ({
                label: situation.description,
                code: situation.code,
              })),
            );
          },
        );
    },
    loadBanks() {
      this.$http
        .get(`${this.$store.state.API_BASE}bank/listAll/${this.$session.get('company_group_id')}`, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            if (!result.body.data) {
              return;
            }

            this.banks = INITIAL_SELECT_VALUE.concat(result.body.data.map((bank) => ({
              label: `${bank.code} - ${bank.name}`,
              code: bank.code,
            })));
          },
        );
    },
    loadParameter() {
      if (
        this.banks.length <= 0
        || this.paymentMethods.length <= 0
        || this.paymentTypes.length <= 0
        || this.companys.length <= 0
      ) {
        return;
      }

      this.$http
        .get(`${this.$store.state.API_BASE}fieldparameter/getBillingRemittance`, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            const { data } = result.body;

            const dates = {
              issue_date_initial: 'emissionStartDate',
              issue_date_final: 'emissionEndDate',
              due_date_initial: 'dueDateStartDate',
              due_date_final: 'dueDateEndDate',
              return_date_initial: 'returnStartDate',
              return_date_final: 'returnEndDate',
            };

            Object.keys(dates).forEach((item) => {
              if (data[item]) {
                const date = moment(data[item], 'YYYY-MM-DD').format('DD/MM/YYYY');
                this.setSearchData({ [dates[item]]: date });
              }
            });

            if (data.registration_number) {
              data.registration_number.forEach((number) => {
                const blank = ' ';
                const line = ' - ';
                const $option = $('<option selected></option>').val(number.id).text(number.clientCode + blank + number.clientStore + line + number.text);
                $('#registration_number').append($option);
              });
              $('#registration_number').trigger('change');
            }

            const fields = [
              'transaction_type',
              'bank_code',
              'liberated',
              'occurency_code',
              'company_id',
              'document_status',
            ];

            fields.forEach((field) => {
              if (data[field]) {
                $(`#${field}`).val(data[field]).trigger('change');
              }
            });

            if (data.company_id) {
              this.loadPaymentMethods(0, data.company_id, data.payment_method);
            }

            if (data.bordero_number) {
              let borderoLabel = '';
              data.bordero_number.forEach((number) => {
                borderoLabel = (number == 'IS_NULL') ? 'Em Branco' : number;
                const $option = $('<option selected></option>').val(number).text(borderoLabel);
                $('#bordero_number').append($option);
              });
              $('#bordero_number').trigger('change');
            }

            unblockPage();
          },
          () => {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger',
            );
            unblockPage();
          },
        );
    },
    onChange(key, event) {
      this.setSearchData({ [key]: event });
    },
    fetchClients(search, loading) {
      if (search.length <= 3) {
        return;
      }

      loading(true);
      const id = this.$session.get('company_group_id');
      this.$http
        // /erp/client/listAll?search=INDUSTRIA&companyId=50&limit=10
        .get(
          `${this.$store.state.API_BASE}erp/client/listAll?search=${search}&companyId=${id}&limit=15`,
          {
            headers: { token: this.$session.get('token') },
          },
        )
        .then(
          (result) => {
            const blank = ' ';
            const line = ' - ';
            if (!result.body.data) {
              this.clients = [];
            } else {
              this.clients = result.body.data.map((client) => ({
                label: client.a1_cod + blank + client.a1_loja + line + client.a1_nome,
                code: client.a1_cgc,
              }));
            }
            loading(false);
          },
          (error) => {
            if (!error.message || error.message !== 'Not Found') {
              // eslint-disable-next-line no-undef
              showNotification(
                'Atenção',
                'Opa! Parece que algo saiu errado, tente recarregar a página',
                'danger',
              );
            }
            loading(false);
          },
        );
    },
  },
  watch: {
    banks() {
      this.loadParameter();
    },
    companys() {
      this.loadParameter();
    },
    paymentMethods() {
      this.loadParameter();
    },
    paymentTypes() {
      this.loadParameter();
    },
  },
};
</script>

<style>
.v-text-field {
  padding-top: 0;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #575962 !important;
}
</style>
