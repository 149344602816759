<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Envio de DDA"/>
			<v-card class="elevation-0 mb-5"  id="params">
				<v-card-title>
					<v-spacer/>
					<v-col lg="3" md="4" sm="12"  xs="12">
						<v-btn
						@click="toFiles()"
            class="font-weight-bold"
						color="info"
						block
						large
						id="btn-generate"
						depressed
						>
							ARQUIVOS ENVIADOS
						</v-btn>
					</v-col>
				</v-card-title>
			</v-card>
			<vue-dropzone ref="myVueDropzone" id="ddaupload" :options="dropzoneOptions" :use-custom-slot=true vdropzone-duplicate-file=true v-on:vdropzone-error="onError" v-on:vdropzone-success="onSuccess">
				<div class="dropzone-custom-content">
					<img src="/assets/img/1031498-128.png" />
					<br>
					<br>
					<h3 class="dropzone-custom-title">Solte os arquivos aqui</h3>
					<div class="subtitle">...ou clique para selecionar um arquivo.</div>
				</div>
			</vue-dropzone>
		</v-col>
	</v-row>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
 
    export default {
		components: {
			vueDropzone: vue2Dropzone,
    	Breadcrumbs,
		},		
        data () {
            return {
				dropzoneOptions: {
					url: ()=> this.$store.state.API_BASE + 'dda/fileimport/new',
					headers: {'token': this.$session.get('token')},
					dictRemoveFile: 'Remover',
					dictInvalidFileType: 'Tipo do arquivo inválido!',
					autoProcessQueue: true,
					duplicateCheck: true, 
					acceptedFiles: '.RET,.TXT',
					parallelUploads: 1
				}
            }
        },
        mounted: function () {

        },
        methods: {
			onError: function(file, message, xhr){
				showNotification("Atenção", message.message, 'danger');
			},
			onSuccess: function(file, message, xhr){
				if (!message.error) {
					this.$http.get(this.$store.state.API_BASE+'dda/proccessOneFile/'+message.message, {headers: {'token': this.$session.get('token')}}).then(
						(result) =>{
							showNotification('Atenção','Arquivo processado com sucesso!', 'success');
							unblockPage();
						},
						(error) => {
							showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
							unblockPage();
						}
					)
				}
			},
			toFiles(){
				this.$router.push({ path: 'ddafiles' });
			}
        }
    }
</script>