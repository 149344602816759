<template>
	<page-container>
    <v-row>
      <v-col>
        <breadcrumbs page-title="Cadastro de Motivo de Baixa"/>
        <v-card class="elevation-0">
          <v-card-title>
            <v-spacer />
            <v-col cols="12" lg="2" md="3" sm="6" xs="12">
              <list-add-button @click="add"/>
            </v-col>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-data-table
              :headers="headers"
              :loading="loadAcquittanceTable"
              :items="acquittanceReasonTable"
              class="elevation-0 pa-3"
              hide-default-footer
              disable-pagination
              group-by="name">
              <!-- eslint-disable-next-line -->
              <template v-slot:group.header="{ group, isOpen, toggle }">
                <th colspan="12" class="">
                  <v-icon @click="toggle">
                    {{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                  <span> {{ group }}</span>
                </th>
              </template>
              <template v-slot:[`item.acquittanceDetailStatus`]="{ item }">
              <v-chip
                v-if="item.acquittanceDetailStatus === 'I'"
                small
                class="ma-1">
                  Inativo
              </v-chip>
              <v-chip
                v-else
                small
                class="ma-2"
                color="green"
                text-color="white">
                  Ativo
              </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                  <table-edit-button @click="edit(item)"/>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
	</page-container>
</template>

<script>
// eslint-disable-next-line
import AcquittanceReasonService from "@/services/acquittancereason.service";
import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
import tableEditButton from '@/components/buttons/tableEditButton.vue';
import PageContainer from '@/components/defaults/PageContainer.vue';
import ListAddButton from '@/components/buttons/ListAddButton.vue';

export default {
  name: 'AcquittanceReason',
  components: {
    Breadcrumbs,
    tableEditButton,
		PageContainer,
    ListAddButton,
  },
  data() {
    return {
      headers: [
        {
          text: 'Status',
          width: '15%',
          align: 'start',
          sortable: false,
          value: 'acquittanceDetailStatus',
        },
        {
          text: 'Código ERP',
          sortable: false,
          value: 'acquittanceDetailErpCode',
        },
        {
          text: 'Descrição',
          sortable: false,
          value: 'acquittanceDetailDescription',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '5%',
        },
      ],
      acquittanceReasonTable: [],
      loadAcquittanceTable: null,
    };
  },
  created() {
    this.loadAcquittanceReason();
  },
  methods: {
    async loadAcquittanceReason() {
      try {
        this.loadAcquittanceTable = true;
        const companyGroupId = this.$session.get('company_group_id');
        const acquittanceReasonService = new AcquittanceReasonService();
        const result = await acquittanceReasonService
            .listAll()
            .show(companyGroupId);
        this.acquittanceReasonTable = result.data;
      } catch (error) {
        this.$helpers.defaultCatchError(error);
      } finally {
        this.loadAcquittanceTable = false;
      }
    },
    add() {
      this.$router.push('/acquittancereason/save');
    },
    edit(item) {
      const params = {
				id: item.acquittanceDetailId,
			};
      this.$router.push({ name: 'AcquittanceReasonUpdate', params });
    },
  },
};
</script>
