export default {
	name: 'DrawerActions',
	data() {
		return {
			specificAccessToCheck: [
				'company',
				'companygroup',
				'cashflow',
				'cashflowrealized',
				'user',
				'usergroup',
				'accessusergroup',
			],
		};
	},
	methods: {
		verifyIfIsActive(access) {
			if (access.children && access.children.length) {
				return this.isActiveWhenGrouper(access);
			}

			return this.isActiveWhenRouter(access);
		},
		isActiveWhenRouter(access) {
			const route = window.location.pathname;

			if (this.specificAccessToCheck.includes(access.path)) {
				return this.isActiveWhenRouterIsEspecific(access);
			}

			const isActive = (route).includes(access.path);

			return isActive;
		},
		isActiveWhenGrouper(access) {
			return access.children.some((child) => this.isActiveWhenRouter(child));
		},
		/**
		 * tratamento especifico pois a logica quebra ao buscar pela rota cashflowrealized
		 * nesse caso tanto o cashflowrealized quanto cashflow seriam validos
		 * verifica qual de fato é a rota
		 */
		isActiveWhenRouterIsEspecific(access) {
			const route = window.location.pathname;

			const splittedRoute = route.split('/');
			const endOfRoute = splittedRoute[splittedRoute.length - 1];

			return access.path == endOfRoute;
		},
	},
};
