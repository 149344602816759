<template>
	<div class="noPrint">
		<v-navigation-drawer
			permanent
			:mini-variant.sync="mini"
			clipped
			enable-resize-watcher
			expand-on-hover
			width="302"
			mini-variant-width="64"
		>
			<drawer-list
				ref="drawerList"
				v-if="items.length"
				v-model="items"
			/>

			<v-progress-circular
				v-else
				class="loadingDrawer d-flex"
				indeterminate
			/>

			<template v-slot:append>
				<div class="drawer-append">
					<v-img :src="getCompanyGroupImage()"/>
				</div>
			</template>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { bus } from '@/main';
import MenuService from '@/services/menu.service';
import DrawerList from './DrawerList.vue';

export default {
	name: 'Drawer',
	components: {
		DrawerList,
	},
	computed: {
	},
	data: () => ({
		value: false,
		items: [],
		mini: true,
	}),
	mounted() {
		this.loadDrawer();
	},
	watch: {
		mini(val) {
			if (val) {
				this.closeAllMenus();
			}
		},
	},
	methods: {
		getUserGroupId() {
			return this.$session.get('user_group_id');
		},
		async loadDrawer() {
			try {
				if (!this.userDefined()) {
					return;
				}

				const menuService = new MenuService();

				const userGroup = this.getUserGroupId();
				const menusByUserGroup = `listAllAccessProgram/${userGroup}`;

				const response = await menuService.index(menusByUserGroup);

				const data = this.serializeData(response.data);

				this.items = data;
			} catch (error) {
				console.error(error);
			}
		},
		userDefined() {
			return this.$session && this.$session.get('token');
		},
		serializeData(data) {
			const arrayOfData = Object.values(data);

			const serializedData = arrayOfData.sort((a, b) => a.order - b.order);

			return serializedData;
		},
		getCompanyGroupImage() {
			const sessionData = this.$session.getAll();

			return sessionData.company_group_image;
		},
		closeAllMenus() {
			bus.$emit('drawer_closeAllMenus');
		},
	},
};
</script>

<style lang="sass">
.v-navigation-drawer
	height: calc(100% - 48px) !important
	position: fixed !important
	margin-top: 48px !important
	z-index: 1048 !important

.v-navigation-drawer__content

	&::-webkit-scrollbar
		width: 0.4em

	&::-webkit-scrollbar-track
		background-color: rgba(0, 0, 0, 0.1)

	&::-webkit-scrollbar-thumb
		background-color: rgba(0, 0, 0, 0.2)
		border-radius: 24px

.loadingDrawer
	margin: 250px auto
	color: grey

.drawer-append
	padding: 48px 15% !important
</style>
