/* eslint-disable */
import VeeValidate, { Validator } from 'vee-validate';
import ptBr from 'vee-validate/dist/locale/pt_BR';

import Vue from 'vue';
import store from './plugins/store';

// CUSTOM LIBRARIES
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
// eslint-disable-next-line
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import VPopover from 'vue-js-popover';
import VueSession from 'vue-session';
import VCurrencyField from 'v-currency-field';
import VueMask from 'v-mask';
import { VTextField } from 'vuetify/lib';

import snackbar from './plugins/snackbar';
import helpers from '@/plugins/helpers/helpers';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import AppConsts from './constants/App';

import InnDesign from './plugins/inn-design';
Vue.use(InnDesign);

import DialogPlugin from './plugins/dialog';
Vue.use(DialogPlugin);

import NotificationsPlugin from './plugins/notifications';
Vue.use(NotificationsPlugin);

import emitter from './services/event_bus.service';
Vue.prototype.$emitter = emitter;

import StickyThead from "./plugins/stickyThead";
Vue.use(StickyThead)

Vue.config.productionTip = false;

Validator.localize('pt_BR', ptBr);

// MOMENT
const moment = require('moment');

require('moment/locale/pt-br');

Vue.use(require('vue-moment'), {
  moment,
});

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(VueSession);
Vue.use(VeeValidate);
Vue.use(VueLodash, lodash);
Vue.use(VueMask);
Vue.use(VPopover, { tooltip: true });
Vue.use(AppConsts);

// multiplas gerencias de estado
export const bus = new Vue();

/**
 * Definicao do aplicativo
 */
Vue.use(helpers);
Vue.use(snackbar, {
  bus,
});

numeral.language('pt-br');
Vue.filter('formatNumber', (value) => numeral(value).format('0,0.00'));
Vue.filter('formatTax', (value) => numeral(value).format('0,0.0000'));
Vue.filter('formatDate', (value) => moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY'));

var vue = new Vue({
  store,
  router,
  vuetify,
  emitter,
  render: (h) => h(App),
  mounted: function() {
    this.checkToken(this.$store.state.user.token);
    this.createMenuAction();
  },
  methods: {
    checkToken() {
      var self = this;
      if (
        !self.$session.exists() &&
        (self.$route.name === 'TokenHandler' || self.$route.name === 'login')
      ) {
        return;
      }

      if (!self.$session.exists() && self.$route.name != 'logintoken') {
        router.push('login');
        return;
      }

      const url = `${self.$store.state.API_BASE}checkstatus`;

      var timerID = setInterval(() => {
        $.ajax({
          url,
          method: 'POST',
          data: {
            token: self.$session.get('token'),
          },
        })
          .done(() => {
            console.log('Session Check Ok!');
          })
          .fail((error) => {
            if (error.status == 401) {
              router.push('login');
            }
          });
      }, 60 * 1000);

      // clearInterval(timerID);
    },

    createMenuAction() {
      $('#m_aside_left_minimize_toggle').click(function() {
        var header_inncash = $('.header-inncash');

        if (header_inncash.length > 0) {
          setTimeout(function() {
            var width_reference = $('.level-main')[0].clientWidth;

            header_inncash.css({ width: width_reference + 'px' });
          }, 500);
        }

        // Na tela de conciliacao de DDA, tem que destruir e recriar o header
        if ($('#table-conciliation').length > 0) {
          var reinit = $('table').floatThead('destroy');

          setTimeout(() => reinit(), 1000);
        }
      });
    },
  },
}).$mount('#app');

export default vue;
