<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'conciliationconfig' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" v-on:click="save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
				<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link active" data-toggle="tab" href="#dadosprincipais" role="tab" aria-expanded="true">
							Dados Principais
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#infolancamentos" role="tab" aria-expanded="true">
							Config. Lançamentos
						</a>
					</li>
				</ul>
				<div class="tab-content"  id="frmUserGroup">
					<div class="tab-pane active" id="dadosprincipais" role="tabpanel">
	           			<div class="row">
							<input type="hidden" name="company_group_id" id="company_group_id" v-model="company_group_id">
							<div class="col-md-9">
								<div class="form-group">
									<label>Empresa</label>
									<select class="form-control select2" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;" required>
										<option value="0">Todas</option>
										<option v-for="company in companys" :value="company.id" v-bind:key="company.id">{{ company.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Status</label>
									<select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;" required>
										<option value="0">Inativo</option>
										<option value="1" selected>Ativo</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div :class="['form-group']">
									<label>Banco</label>
									<select class="form-control select2 hide"
											id="code_bank" name="code_bank" style="width: 100%;" field-name="Banco" required>
										<option :value="undefined">Selecione...</option>
										<option v-for="bank in banks" :value="bank.code" v-bind:key="bank.code">{{ bank.code}} - {{ bank.name}}
										</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div :class="['form-group']">
									<label>Retorno CNAB</label>
									<select class="form-control hide select2" id="id_cnab_remi_config" name="id_cnab_remi_config" style="width: 100%;" field-name="Retorno CNAB" required>
										<option :value="undefined">Selecione...</option>
										<option v-for="cnabRemittance in cnabRemittancesConfig" :value="cnabRemittance.id" v-bind:key="cnabRemittance.id">
											{{ cnabRemittance.description }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label title="Dia considerado nos extratos bancários quando o banco envia dados que representam movimentos de outro dia no extrato">Dia Liquidação *</label>
									<div class="liquidDayBox">
										<input type="number" class="form-control" id="liquid_day" field-name="Dia da Liquidação" required/>
									</div>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label title="Data para inicio da conciliação">Data para inicio da conciliação</label>
									<div class="liquidDayBox">
										<input type="text" class="form-control text-center" readonly id="init_import" field-name="Dia da conciliação"/>
									</div>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label title="Quantidade de dias para conciliar">Dia conciliação</label>
									<div class="liquidDayBox">
										<input type="number" class="form-control" id="days_import" field-name="Dia da conciliação"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="infolancamentos" role="tabpanel">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group title"  style="">
									<b>Natureza Financeira </b>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Utiliza </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_use_financial_nature" id="in_use_financial_nature" value="0" v-on:change="onChangeReleaseSettings('#in_use_financial_nature', '#in_required_financial_nature')" required>
												<span class="accessSwitch" input-field="in_use_financial_nature"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Obrigatório </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_required_financial_nature" id="in_required_financial_nature" value="0" required>
												<span class="accessSwitch" input-field="in_required_financial_nature"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group title"  style="">
									<b>Centro de Custo</b>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Utiliza </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_use_cost_center" id="in_use_cost_center" value="0" v-on:change="onChangeReleaseSettings('#in_use_cost_center', '#in_required_cost_center')" required>
												<span class="accessSwitch" input-field="in_use_cost_center"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Obrigatório </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_required_cost_center" id="in_required_cost_center" value="0" required>
												<span class="accessSwitch" input-field="in_required_cost_center"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group title"  style="">
									<b>Item contábil</b>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Utiliza </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_use_accounting_item" id="in_use_accounting_item" value="0" v-on:change="onChangeReleaseSettings('#in_use_accounting_item', '#in_required_accounting_item')" required>
												<span class="accessSwitch" input-field="in_use_accounting_item"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Obrigatório </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_required_accounting_item" id="in_required_accounting_item" value="0" required>
												<span class="accessSwitch" input-field="in_required_accounting_item"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group title"  style="">
									<b>Classe de Valor</b>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Utiliza </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_use_value_class" id="in_use_value_class" value="0" v-on:change="onChangeReleaseSettings('#in_use_value_class', '#in_required_value_class')" required>
												<span class="accessSwitch" input-field="in_use_value_class"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Obrigatório </label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_required_value_class" id="in_required_value_class" value="0" required>
												<span class="accessSwitch" input-field="in_required_value_class"
													input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<!-- titulo -->
								<div class="form-group title" style="">
									<b>Prefixo</b>
								</div>

								<!-- campo de definicao de utilização -->
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;">Utiliza</label>
									<div class="col-4">
										<!-- botao de switch -->
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_use_prefix" id="in_use_prefix" value="0" v-on:change="onChangeReleaseSettings('#in_use_prefix', '#in_required_prefix')" required/>
												<span class="accessSwitch" input-field="in_use_prefix" input-value="1"></span>
											</label>
										</span>
									</div>
								</div>

								<!-- campo de definicao de obrigatoriedade -->
								<div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
									<label class="col-8 col-form-label" style="text-align: left;"> Obrigatório </label>
									<div class="col-4">
										<!-- botao de switch -->
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="in_required_prefix" id="in_required_prefix" value="0" required/>
												<span class="accessSwitch" input-field="in_required_value_class" input-value="1"></span>
											</label>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<style scoped>
.liquidDayBox {
	display: flex;
    align-items: center;
}

.liquidDayBox > * {
	flex: 1;
}

.liquidDayBox > button {
    max-width: 27.5%;

}

.title {
	margin-top: 1.5rem;
	font-weight: 500;
}
</style>

<script>
    export default {
        data () {
            return {
				conciliationConfig: {},
				companys: [],
				cnabRemittancesConfig: [],
				banks: [],
				conciliation_config_id: this.$route.params.conciliation_config_id,
				addedActions: [],
				filterFields: [],
                company_group_id: this.$session.get('company_group_id')
            }
        },

        mounted: function () {
          numeral.language('pt-br');

          $(".accessSwitch").click(function () {
            var inputId = $(this).attr('input-field');

            if ($.trim($("#" + inputId).val()) == '0' || $.trim($("#" + inputId).val()) == '') {
              $("#" + inputId).val('1');
            } else {
              $("#" + inputId).val('0');
            }
          });

          $("#date_tolerance").mask("000");

          $(".select2").select2({placeholder: 'Selecione...'});
          $(".select2CnabRemittance").select2({placeholder: "Selecione a remessa..."});

          this.loadCompanys();

          $("#code_bank").change(() =>{
            blockPage('Carregando os dados...');
            this.loadCnabRemittancesConfigs();
          });

          //Desabilita os campos obrigatório se não utiliza
          if($("#in_use_financial_nature").val() == 0){
            $("#in_required_financial_nature").val(0);
            $("#in_required_financial_nature").prop("disabled", true);
          }
          if($("#in_use_cost_center").val() == 0){
            $("#in_required_cost_center").val(0);
            $("#in_required_cost_center").prop("disabled", true);
          }
          if($("#in_use_value_class").val() == 0){
            $("#in_required_value_class").val(0);
            $("#in_required_value_class").prop("disabled", true);
          }
          if($("#in_use_accounting_item").val() == 0){
            $("#in_required_accounting_item").val(0);
            $("#in_required_accounting_item").prop("disabled", true);
          }
          if($("#in_use_prefix").val() == 0){
            $("#in_required_prefix").val(0);
            $("#in_required_prefix").prop("disabled", true);
          }

          $("#init_import").datepicker({
              autoclose: true,
              format: 'dd/mm/yyyy',
              todayHighlight: !0,
              orientation: "bottom left",
              templates: {
                  leftArrow: '<i class="la la-angle-left"></i>',
                  rightArrow: '<i class="la la-angle-right"></i>'
              }
          });
        },

        methods: {
            loadCompanys: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(this.$store.state.API_BASE+'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	(result) =>{
						this.companys = result.body.data

						this.loadBanks();
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
			},
            loadBanks: function () {
				this.cnabRemittancesConfig = [];

                this.$http.get(this.$store.state.API_BASE + 'bank/listAll/' + this.$session.get('company_group_id'), {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
						this.banks = result.body.data;

						this.loadCnabRemittancesConfigs();

						if(this.conciliation_config_id != 'new'){
							setTimeout(() => this.loadData(), 100);
						} else {
							unblockPage();
						}
                    },
                    (error) => {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
			},
			loadCnabRemittancesConfigs: function (){
				let bankField = $("#code_bank");

				if(!bankField.val()) return

				$.ajax({
					method: "GET",
					url: this.$store.state.API_BASE + 'cnabremittanceconfig/listAllByBank/' + bankField.val() + '/operation/1',
					headers: {
						'token': this.$session.get('token')
					}
				}).done((result) =>{
					if (result.error == false) {
						this.cnabRemittancesConfig = result.data;

						if(this.conciliationConfig.id_cnab_remi_config){
							$("#id_cnab_remi_config").val(this.conciliationConfig.id_cnab_remi_config);
							$("#id_cnab_remi_config").trigger('change');
						}
					} else {
						showNotification('Atenção', result.error, 'danger');
					}
					unblockPage();
				}).fail((error) =>{
					unblockPage();
					showNotification('Atenção', 'Oops! Ocorreu um erro ao carregar os bancos.', 'danger');
					console.log(error);
				});
			},
			loadData: function () {
				blockPage('Carregando os dados...');
				$.ajax({
                    method: "GET",
                    url: this.$store.state.API_BASE + 'conciliation/config/listOne/' + this.conciliation_config_id,
                    headers: {
                        'token': this.$session.get('token')
                    },
                }).done(result => {
					if (result &&  result.data && result.data[0]){
						this.conciliationConfig = result.data[0];

						$("#company_id").val(this.conciliationConfig.company_id);
						$("#company_id").trigger('change');

						$("#status").val(this.conciliationConfig.status);
						$("#status").trigger('change');

						$("#code_bank").val(this.conciliationConfig.code_bank);
						$("#code_bank").trigger('change');

						$("#verify_number").val(this.conciliationConfig.verify_number);
						$("#verify_number").trigger('change');
						$("#manual_verify_number").val(this.conciliationConfig.manual_verify_number);
						$("#manual_verify_number").trigger('change');

						$("#verify_cnpj").val(this.conciliationConfig.verify_cnpj);
						$("#verify_cnpj").trigger('change');
						$("#manual_verify_cnpj").val(this.conciliationConfig.manual_verify_cnpj);
						$("#manual_verify_cnpj").trigger('change');

						$("#liquid_day").val(this.conciliationConfig.liquid_day);
						$("#manual_verify_cnpj").trigger('change');

            $("#days_import").val(this.conciliationConfig.days_import);

            if (this.conciliationConfig.init_import) {
              let date = moment(this.conciliationConfig.init_import).format("DD/MM/YYYY");
              $("#init_import").val(date);
              $("#init_import").datepicker({
                  autoclose: true,
                  format: "dd/mm/yyyy",
                  todayHighlight: !0,
                  orientation: "bottom left",
                  defaultDate: date,
                  templates: {
                      leftArrow: '<i class="la la-angle-left"></i>',
                      rightArrow: '<i class="la la-angle-right"></i>'
                  }
              });
            }

						//Natureza financeira
						if(this.conciliationConfig.in_use_financial_nature) {
							$("#in_use_financial_nature").click();
							$("#in_use_financial_nature").val(this.conciliationConfig.in_use_financial_nature);
							$("#in_required_financial_nature").prop("disabled", false);
						}
						if(this.conciliationConfig.in_required_financial_nature) {
							$("#in_required_financial_nature").click();
							$("#in_required_financial_nature").val(this.conciliationConfig.in_required_financial_nature);
						}

						//Centro de custo
						if(this.conciliationConfig.in_use_cost_center) {
							$("#in_use_cost_center").click();
							$("#in_use_cost_center").val(this.conciliationConfig.in_use_cost_center);
							$("#in_required_cost_center").prop("disabled", false);
						}
						if(this.conciliationConfig.in_required_cost_center) {
							$("#in_required_cost_center").click();
							$("#in_required_cost_center").val(this.conciliationConfig.in_required_cost_center);
						}

						//Class de valor
						if(this.conciliationConfig.in_use_value_class) {
							$("#in_use_value_class").click();
							$("#in_use_value_class").val(this.conciliationConfig.in_use_value_class);
							$("#in_required_value_class").prop("disabled", false);
						}
						if(this.conciliationConfig.in_required_value_class) {
							$("#in_required_value_class").click();
							$("#in_required_value_class").val(this.conciliationConfig.in_required_value_class);
						}

						//Item contábil
						if(this.conciliationConfig.in_use_accounting_item) {
							$("#in_use_accounting_item").click();
							$("#in_use_accounting_item").val(this.conciliationConfig.in_use_accounting_item);
							$("#in_required_accounting_item").prop("disabled", false);
						}
						if(this.conciliationConfig.in_required_accounting_item == 1) {
							$("#in_required_accounting_item").click();
							$("#in_required_accounting_item").val(this.conciliationConfig.in_required_accounting_item);
						}

						//prefixo
						if(this.conciliationConfig.in_use_prefix) {
							$("#in_use_prefix").click();
							$("#in_use_prefix").val(this.conciliationConfig.in_use_prefix);
							$("#in_required_prefix").prop("disabled", false);
						}
						if(this.conciliationConfig.in_required_prefix == 1) {
							$("#in_required_accounting_prefix").click();
							$("#in_required_accounting_prefix").val(this.conciliationConfig.in_required_prefix);
						}

						this.loadCnabRemittancesConfigs();
					}

					unblockPage();

                }).fail(error => {
                    console.log(error.responseText);
                    unblockPage();
				});
			},
			addAction: function () {
                if (!$("#actionDescription").val() || !$("#actionErp").val() || !$("#agglutine")) {
                    showNotification('Atenção', 'Todos os campos devem ser preenchidos!', 'warning');
                    return false;
                }

                if ($.trim($("#editing").val()) != '') {
                    var itemId = $("#editing").val();

                    this.addedActions.splice(itemId, 1, {
						description: $("#actionDescription").val(),
						actionErp: $("#actionErp").val(),
						agglutine: $("#agglutine").val()
                    });

                } else {
                    this.addedActions.push(
                        {
							description: $("#actionDescription").val(),
							actionErp: $("#actionErp").val(),
							agglutine: $("#agglutine").val()
                        }
                    )
				}

				$("#actionDescription").val("");

				$('#actionErp').val("");
				$('#actionErp').trigger('change');

				$("#agglutine").val("");
				$('#agglutine').trigger('change');

                $("#editing").val("");
            },
			save: function () {
        let withError;
        let arrayData = [], i = 0;
        let value;

				$.each($('input, select', '#frmUserGroup'),function(){
					if($.trim(this.value) == '' && $(this).attr('required') == 'required'){
						withError = true;

						$(".form-group:has(#"+this.id+")").addClass('has-danger');
						$("#"+this.id).addClass('input-error');
						$("#"+this.id).css('border-color','red');
						showNotification('Atenção', 'O campo <strong>'+ $("#"+this.id).attr('field-name')+'</strong> não foi informado!', 'warning');

						$("#"+this.id).keyup(function(){
							if ($("#"+this.id).hasClass('input-error')) {
								$("#"+this.id).removeClass('input-error');
								$("#"+this.id).css('border-color', '#ebedf2');
								$(".form-group:has(#"+this.id+")").removeClass('has-danger');
							}
						});
					}else {
            value = this.value;

            if (this.id === 'init_import') {
              let aux = this.value.split('/');

              value = `${aux[2]}-${aux[1]}-${aux[0]}`;
            }

            arrayData[i] = {};
            arrayData[i][this.id] = value;
					}

					i++;
				});

				if(withError) return false;

				if(this.conciliation_config_id != 'new') arrayData.push({id: this.conciliation_config_id});

				$.ajax({
					method: "POST",
					url: this.$store.state.API_BASE + 'conciliation/config/' + (this.conciliation_config_id == 'new' ? 'new' : 'edit'),
					headers: {
						'token': this.$session.get('token')
					},
					data: {
						arrayData: JSON.stringify(arrayData),
					}
				}).done((result) => {
					if(!result.error){
						showNotification('Sucesso', 'Processo concluído com sucesso!','success');

						this.$router.replace({name: 'conciliationconfig'});
					}else{
						showNotification('Atenção',result.message, 'danger');
						return false;
					}
				}).fail((error) => {
					console.log(error.responseText);
				});
			},
			onChangeReleaseSettings: function(idUse, idRequired){
				let useValue = $(idUse).val();
				let requiredValue = $(idRequired).val();

				if(useValue == "0" && requiredValue == "1"){
					$(idRequired).click();
					$(idRequired).val("0");
					$(idRequired).prop("disabled", true);
				}else if(useValue == "0" && requiredValue == "0"){
					$(idRequired).val("0");
					$(idRequired).prop("disabled", true);
				}else if(useValue == "1" && requiredValue == "0"){
					$(idRequired).prop("disabled", false);
				}else if(useValue == "1" && requiredValue == "1"){
					$(idRequired).click();
					$(idRequired).val("0");
					$(idRequired).prop("disabled", false);
				}
			}
        }
    }
</script>
