import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Configurações Segmentos CNAB"}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"12","lg":"10","md":"9","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"filled":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"Pesquisar"},model:{value:(_vm.configs.filterTerm),callback:function ($$v) {_vm.$set(_vm.configs, "filterTerm", $$v)},expression:"configs.filterTerm"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c('list-add-button',{on:{"click":_vm.add}})],1)],1),_c(VCardText,{staticClass:"pa-6 pt-0"},[_c('table',{staticClass:"table table-striped m-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Banco")]),_c('th',[_vm._v("Descrição")]),_c('th',[_vm._v("Remessa")]),_c('th',[_vm._v("Nome do Arquivo")]),_c('th',[_vm._v("Extensão")]),_c('th')])]),_c('tbody',_vm._l((_vm.listData),function(cnabRemittanceConfig,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"vertical-align":"middle","text-align":"center"}},[_vm._v(_vm._s(cnabRemittanceConfig.codeBank))]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(cnabRemittanceConfig.description))]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(cnabRemittanceConfig.cnabRemittance.description)+" - "+_vm._s(cnabRemittanceConfig.cnabRemittance.size)+" posições")]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(cnabRemittanceConfig.fileName))]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(cnabRemittanceConfig.extension))]),_c('td',{staticClass:"m--align-right"},[_c('table-edit-button',{on:{"click":function($event){return _vm.edit(cnabRemittanceConfig)}}})],1)])}),0)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }