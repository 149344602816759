import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Configurações de API bancária"}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCardTitle,[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c('list-add-button',{on:{"click":_vm.add}})],1)],1),_c(VCardText,{staticClass:"pa-6 pt-0"},[_c('list-table',{attrs:{"items":_vm.items}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }