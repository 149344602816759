import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Cadastro de Empresa"}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VSelect,{attrs:{"filled":"","dense":"","hide-details":"","items":_vm.filterStatusItems},model:{value:(_vm.configs.filterStatus),callback:function ($$v) {_vm.$set(_vm.configs, "filterStatus", $$v)},expression:"configs.filterStatus"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"5","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"filled":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"Pesquisar"},model:{value:(_vm.configs.filterTerm),callback:function ($$v) {_vm.$set(_vm.configs, "filterTerm", $$v)},expression:"configs.filterTerm"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c('list-add-button',{on:{"click":_vm.add}})],1)],1),_c(VCardText,{staticClass:"pa-6 pt-0"},[_c('table',{staticClass:"table table-striped m-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Status")]),_c('th',[_vm._v("CNPJ")]),_c('th',[_vm._v("Nome")]),_c('th')])]),_c('tbody',_vm._l((_vm.listData),function(company){return _c('tr',[_c('td',{staticStyle:{"vertical-align":"middle"}},[(company.status == 0)?_c(VChip,{attrs:{"dense":"","small":""}},[_vm._v(" Inativo ")]):_c(VChip,{attrs:{"dense":"","small":"","color":"green","text-color":"white"}},[_vm._v(" Ativo ")])],1),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(company.cnpj))]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(company.name))]),_c('td',{staticClass:"m--align-right"},[_c('table-edit-button',{on:{"click":function($event){return _vm.edit(company)}}})],1)])}),0)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }