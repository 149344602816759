<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'usergroup' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
	            <div class="row" id="frmUserGroup">
	                <div class="col-md-9">
	                    <div class="form-group">
	                        <label>Descrição</label>
	                        <input type="text" class="form-control" field-name="Descrição" id="description" name="description" required>
	                    </div>
	                </div>
	                <div class="col-md-3">
	                    <div class="form-group">
	                        <label>Tipo</label>
	                        <select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;">
	                            <option value="N" selected>Normal</option>
	                            <option value="A">Administrador</option>
	                        </select>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
            	company_group_id: this.$session.get('company_group_id')
            }
        },

        mounted: function () {
        	this.loadData();
            this.loadMethod();
        },

        methods: {
        	loadData: function () {
        		var self = this;
            	blockPage('Carregando os dados...');

				if (self.$session.get('user_type') == 'O') {
					$.ajax({
						method: "GET",
						url: self.$store.state.API_BASE+'companygroup/listAll',
						assync: true,
                        headers: {
                            'token': self.$session.get('token')
                        }
					}).done(function(result) {
						var options = "";

						$.each(result.data, function(resultItem) {
							options = options +'<option value="'+result.data[resultItem].id+'">'+result.data[resultItem].description+'</option>';
						});

						$("#frmUserGroup").prepend(
							'<div class="col-md-12">'+
								'<div class="form-group">'+
									'<label>Grupo de empresa</label>'+
									'<select class="form-control select2 hide" field-name="Grupo de empresa" id="company_group_id" name="company_group_id" style="width: 100%;">'+
										'<option value="">Selecione...</option>'+
										options+
									'</select>'+
								'</div>'+
							'</div>'
						);

						$(".select2").select2();
		            	unblockPage();
					}).fail(function(error){
						console.log(error.responseText);
		            	unblockPage();
					});
				} else {
	            	unblockPage();
					$("#frmUserGroup").prepend(
						'<input type="hidden" id="company_group_id" name="company_group_id" value="'+self.$session.get('company_group_id')+'"/>'
					);

					$(".select2").select2();
				}
        	},

            loadMethod: function () {
            	var self = this;

				$(".select2").select2();
				$("#cnpj").mask("00.000.000/0000-00");
				$("#phone").mask("(00) 00000-0000");
				$("#zipcode").mask("0000-000");

				$("#btn-save").click(function(){
					var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';

					$.each($('input, select', '#frmUserGroup'),function(){
					    var $this = $(this);

					    if($.trim(this.value) == ''){
					        exitButton = true;
					        fieldName = $this.attr('field-name');

					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }else{
					        exitButton = false;
					        arrayData[i] = {};
					        arrayData[i][this.id] = this.value;
					    }

					    i++;
					});

					if(exitButton){
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado!', 'warning');
					    return false;
					}

					$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE+'usergroup/new',
                        headers: {
                            'token': self.$session.get('token')
                        },
						data: {
							arrayData: JSON.stringify(arrayData)
						}
					}).done(function(result) {
						if(result.error == false){
							showNotification('Sucesso', 'Novo tipo de usuário registrado!','success');

							$("#description").val("");
						}else{
							showNotification('Atenção',result.error, 'danger');
							return false;
						}
					}).fail(function(error){
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            }
        }
    }
</script>
