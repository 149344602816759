<template>
<!--begin::Modal-->
    <div class="modal fade" id="modalUpdateInformation" role="dialog" aria-labelledby="modalUpdateInformation" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="title_data_update_information">
                        {{getTitle()}}
                    </h5>
                </div>
                <form id="form_data_update_information" class="m-form m-form--fit m-form--label-align-right">
                    <div id="data_update_information" style="padding: 0px 25px 0px 25px;" class="modal-body"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn m-btn--air btn-success" @click="update()" id="btnUpdate">Alterar</button>
                        <button type="button" class="btn m-btn--air btn-primary" id="btnModalClose" data-dismiss="modal">Fechar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--end::Modal-->
</template>

<script>
	const boletoValidator = require('@mrmgomes/boleto-utils');

    export default {
        data() {
            return {
                config: {}
            }
        },
        mounted(){
            this.loadCompanyGroup();
        },
        methods: {
            openModal(config){
                this.config = {};

                document.getElementById("form_data_update_information").reset();

                config.grouperTwoId = config.grouperTwoId || 0;

                this.config = config;

                if(config.operation == 'updateDate' || config.operation == 'updateAllDates'){
                    $('#data_update_information').html(this.getHtmlUpdateDate(config));

                    $("#dateToUpdate").datepicker({
                        autoclose: true,
                        format: 'dd/mm/yyyy',
                        todayHighlight: !0,
                        orientation: "bottom left",
                        templates: {
                            leftArrow: '<i class="la la-angle-left"></i>',
                            rightArrow: '<i class="la la-angle-right"></i>'
                        }
                    });

                    $('#modalUpdateInformation').modal('show');
                }else if(config.operation == 'updateCoinTax'){
                    $('#data_update_information').html(this.getHtmlUpdateCoinTax(config));

                    $('#coinTaxToUpdate').mask('0,0000', {reverse: true});

                    $('#modalUpdateInformation').modal('show');
                }else if(config.operation == 'updatePayment'){
                    $('#data_update_information').html(this.getHtmlUpdatePayment(config));

                    blockPage('Carregando formas de pagamento...');

                    let param = `?filial=${config.keys.E2_FILIAL}&TABLE_COMPANY_CODE=${config.keys.TABLE_COMPANY_CODE}`;

                    this.$http.get(this.$store.state.API_BASE + 'paymentmethod/listAll' + param, {headers: {'token': this.$session.get('token')}}).then(
                        (result) => {
                            $('#updateInformationPaymentMethod').select2();

                            let optionHtml;
                            result.data.data.forEach(function (payment) {
                                optionHtml += '<option value="' + payment.code + '">' + payment.description + '</option>';
                            })

                            $('#updateInformationPaymentMethod').append(optionHtml);

                            this.getCompanyConfig(config.keys.TABLE_COMPANY_CODE);
                        },
                        (error) => {
                            showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                            unblockPage();
                        }
                    )
                }else if(config.operation == 'updateTax'){
                    $('#data_update_information').html(this.getHtmlUpdateTax(config));

                    $('#valueToUpdate').mask('00.000.000.000,00', {reverse: true});

                    $('#modalUpdateInformation').modal('show');
                }else if(config.operation == 'updateValue'){
                    $('#data_update_information').html(this.getHtmlUpdateValue(config));

                    //$('#valueToUpdate').mask('00.000.000.000,00', {reverse: true})

                    $('#valueToUpdate').mask('##.###.###.###,##', {
                        reverse: true,
                        translation: {
                            '#': {
                                pattern: /-|\d/,
                                recursive: true
                            }
                        },
                        onChange: function (value, e) {
                            e.target.value = value.replace(/(?!^)-/g, 	'').replace(/^,/, '').replace(/^-,/, '-');
                        }
                    });

                    $('#modalUpdateInformation').modal('show');
                }else if(config.operation == 'updateIncrease'){
                    $('#data_update_information').html(this.getHtmlUpdateIncrease(config));

                    $("#valueType").select2();

                    if (config.valueIncrease > 0 || config.valueDecrease > 0) {
                        $('#valueToUpdate').val(numeral(config.valueIncrease).format('0,0.00'));

                        $('#valueType').change(function () {
                            if (config.value == 'A') {
                                $('#valueToUpdate').val(numeral(config.valueIncrease).format('0,0.00'));
                            }
                            if (config.value == 'D') {
                                $('#valueToUpdate').val(numeral(config.valueDecrease).format('0,0.00'));
                            }
                        });
                    }

                    $('#valueToUpdate').mask('00.000.000.000,00', {reverse: true});

                    $('#modalUpdateInformation').modal('show');
                }else if(config.operation == 'updateComment'){
                    blockPage('Carregando comentário...');

                    if (config.self.view.id) {
                        $.ajax({
                            method: "POST",
                            url: this.$store.state.API_BASE + 'view/getcomment',
                            headers: {
                                'token': this.$session.get('token')
                            },
                            data: {
                                _identifyValue: config.identifyValue,
                                _date: config.date,
                                _keyId: config.keyId,
                                _view_id: config.self.view.id,
                            }
                        }).done(function (result) {
                            html = '<div class="row">';
                            html += '	<div class="col-sm-2">&nbsp;</div>';
                            html += '	<div class="col-sm-8">';
                            html += '		<div class="form-group">';
                            html += '			<label>Comentário:</label>';
                            html += '			<div class="input-group">';
                            html += '				<textarea class="form-control m-input" data-id_field="' + config.idField + '" data-key-id="' + config.keyId + '" data-date="' + config.date + '" data-identify-value="' + config.identifyValue + '" id="commentToUpdate" rows="5" style="margin-top: 0px; margin-bottom: 0px; height: 200px;">' + result.data.comment + '</textarea>';
                            html += '			</div>';
                            html += '		</div>';
                            html += '	</div>';
                            html += '	<div class="col-sm-2">&nbsp;</div>';
                            html += '</div>';

                            $('#data_update_information').html(html);

                            $('#modalUpdateInformation').modal('show');

                            unblockPage();
                        }).fail(function (error) {
                            showNotification('Atenção', 'Oops! Ocorreu um erro ao consultar o comentário.', 'danger');
                            console.log(error);
                            unblockPage();
                        });
                    } else {
                        $.ajax({
                            method: "POST",
                            url: this.$store.state.API_BASE + 'internaltable/getcomment',
                            headers: {
                                'token': this.$session.get('token')
                            },
                            data: {
                                _identifyValue: config.identifyValue,
                                _date: config.date,
                                _keyId: config.keyId,
                                _futureDate: config.futureDate
                            }
                        }).done(function (result) {

                            html = '<div class="row">';
                            html += '	<div class="col-sm-2">&nbsp;</div>';
                            html += '	<div class="col-sm-8">';
                            html += '		<div class="form-group">';
                            html += '			<label>Comentário:</label>';
                            html += '			<div class="input-group">';
                            html += '				<textarea class="form-control m-input" data-id_field="' + config.idField + '" data-key-id="' + config.keyId + '" data-date="' + config.date + '" data-identify-value="' + config.identifyValue + '" id="commentToUpdate" rows="5" style="margin-top: 0px; margin-bottom: 0px; height: 200px;">' + result.data.comment + '</textarea>';
                            html += '			</div>';
                            html += '		</div>';
                            html += '	</div>';
                            html += '	<div class="col-sm-2">&nbsp;</div>';
                            html += '</div>';

                            $('#data_update_information').html(html);

                            $('#modalUpdateInformation').modal('show');

                            unblockPage();
                        }).fail(function (error) {
                            showNotification('Atenção', 'Oops! Ocorreu um erro ao consultar o comentário.', 'danger');
                            console.log(error);
                            unblockPage();
                        });
                    }
                } else if(config.operation == 'updateCodBarras'){
                    /*
                    *
                    *   EVENTOS DA AREA DE EDICAO DE CODIGO DE BARRA
                    *
                    *   Evento de on click e on change da linha digitavel
                    *   alteracoes na mascara de dados
                    *   definicoes contra erros
                    *   feito em jquery
                    *
                    */


                    $('#data_update_information').html(this.getHtmlUpdateCodigoBarras(config));

                    $('#valueToUpdate').mask('00000000000000000000000000000000000000000000');

                    $("#valueLinhaDigitavel").change(function (event) {

                        let value = $("#valueLinhaDigitavel").val();

                        if(value){

                            // try {
                                $('#valueToUpdate').val('');

                            //     let codBarras = Codbarras.verificaBarra(value, event.target);

                            //     if (codBarras) $('#valueToUpdate').val(codBarras);
                            // } catch (error) {
                            //     showNotification('Atenção', error.message, 'warning');
                            // }

                            const retorno = boletoValidator.validarBoleto(value, 'LINHA_DIGITAVEL');

                            if (!retorno.sucesso) {
                                showNotification('Atenção', retorno.mensagem, 'warning');
                                return;
                            }

                            if (retorno.sucesso) {
                                $('#valueToUpdate').val(retorno.codigoBarras);
                            }
                        }
                    });

                    $('#valueToUpdate').change(function (event) {
                        let value = $('#valueToUpdate').val().replace(/[^0-9]/g,'');

                        if (value.length >= 44){
                            // try {
                            //     let linha = Codbarras.verificaBarra($("#valueToUpdate").val(), event.target);

                            //     $('#valueLinhaDigitavel').val(linha, event.target);

                            // } catch (error) {
                            //     showNotification('Atenção', error.message, 'warning');
                            // }

                            const retorno = boletoValidator.validarBoleto($("#valueToUpdate").val(), 'LINHA_DIGITAVEL');

                            if (!retorno.sucesso) {
                                showNotification('Atenção', retorno.mensagem, 'warning');
                                return;
                            }

                            if (retorno.sucesso) {
                                $('#valueLinhaDigitavel').val(retorno.linhaDigitavel);
                            }
                        }
                    });

                    $('#modalUpdateInformation').modal('show');

                } else if(config.operation == 'updateBankData'){
                    this.getCompanyConfig(config.erp_table_code).then(function (){
                        $('#data_update_information').html(this.getHtmlUpdateBankData(config));
                        $('#modalUpdateInformation').modal('show');
                    });
                }
            },
            update(){
                switch (this.config.operation) {
                    case 'updateDate':
                        updateDate(this.config.self);
                        break;
                    case 'updateAllDates':
                        updateAllDates(this.config.self);
                        break;
                    case 'updatePayment':
                        updatePayment(this.config.self, this.config);
                        break;
                    case 'updateValue':
                        updateValue(this.config.self);
                        break;
                    case 'updateComment':
                        updateComment(this.config.self,);
                        break;
                    case 'updateIncrease':
                        updateIncrease(this.config.self);
                        break;
                    case 'updateCoinTax':
                        updateCoinTax(this.config.self);
                        break;
                    case 'updateTax':
                        updateTax(this.config.self);
                        break;
                    case 'updateCodBarras':
                        let validacao_cod_barra = false;

                        try {
                            validacao_cod_barra = Codbarras.verificaDv($("#valueToUpdate").val());
                        } catch (error) {
                            validacao_cod_barra = false;
                        }

                        if($("#valueToUpdate").val()){
                            if(!validacao_cod_barra){
                                Swal.fire({
                                title: 'Código de barras inválido!',
                                text: "Deseja realmente continuar?",
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#34bfa3',
                                cancelButtonColor: '#5867dd',
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Sim'
                                }).then((result) => {
                                    if (result.value) {
                                        updateCodBarras(this.config.self, this.config);
                                    }
                                })
                            } else {
                                updateCodBarras(this.config.self, this.config);
                            }
                        }

                        break;
                    case 'updateBankData':
                        updateBankData(this.config.self, this.config, this.company_group);
                        break;
                }
            },
            getTitle(){
                let title = '';

                switch (this.config.operation) {
                    case 'updateDate':
                    case 'updateAllDates':
                        title = 'Alteração de data';
                        break;
                    case 'updatePayment':
                        title = 'Alteração da forma de pagamento';
                        break;
                    case 'updateCoinTax':
                        title = 'Alteração da taxa da moeda';
                        break;
                    case 'updateValue':
                        title = 'Alteração do valor';
                        break;
                    case 'updateTax':
                        title = 'Alteração da taxa de juros';
                        break;
                    case 'updateIncrease':
                        title = 'Inclusão de acréscimo/decréscimo';
                        break;
                    case 'updateComment':
                        title = 'Comentário';
                        break;
                    case 'updateCodBarras':
                        title = 'Alteração Código de Barras';
                        break;
                    case 'updateBankData':
                        title = 'Alteração Dados Bancários';
                        break;
                }

                return title;
            },
            getHtmlUpdateDate: function(config){
                let html = '';

                html += '<div class="row">';
                html += '<div class="col-sm-4">&nbsp;</div>';
                html += '<div class="col-sm-4">';
                html += '<div class="form-group">';
                html += '<label>Selecione a nova data</label>';
                html += '<div class="input-group date" id="dateToUpdate" data-erp_table_code="' + config.erp_table_code + '"data-id-customer="' + config.id_customer + '"data-company-token="' + config.company_token + '" data-source-table= "' + config.source_table + '" data-branch-token= "' + config.branch_token +'" data-erp_table="' + config.erp_table + '" data-keys=\'' + JSON.stringify(config.keys) + '\' data-tax_keys=\'' + JSON.stringify(config.tax_keys) + '\' data-internal_table_id="' + config.id + '" data-id_field="' + config.id_field + '" data-key-id="' + config.keyId + '" data-check="' + config.check + '" data-identify-value="' + config.type + '" data-date_old="' + config.date + '" data-value-original="' + config.value_original + '">';
                html += '<input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">';
                html += '<span class="input-group-addon">';
                html += '<i class="la la-calendar-check-o"></i>';
                html += '</span>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-sm-4">&nbsp;</div>';
                html += '</div>';

                return html;
            },
            getHtmlUpdatePayment: function (config) {
                let html = '';
                html += '<div class="row">';
                html += '<div class="col-sm-4">&nbsp;</div>';
                html += '<div class="col-md-4">';
                html += '<div class="form-group">';
                html += '<label>Forma de pagamento</label>';
                html += '<select class="form-control select2 hide" id="updateInformationPaymentMethod" name="updateInformationPaymentMethod" data-erp_table_code="' + config.erp_table_code + '"data-id-customer="' + config.id_customer +   '" data-origin="' +  config.origin  + '" data-company-token= "' + config.company_token + '" data-source-table= "' + config.source_table + '" data-branch-token= "' + config.branch_token + '" data-erp_table="' + config.erp_table +  '" data-keys=\'' + JSON.stringify(config.keys) + '\' style="width: 100%;" data-internal_table_id="' + config.id + '" data-id_field="' + config.id_field + '" data-payment_method="' + config.payment_method + '">';
                html += '<option value="">Selecione...</option>';
                html += '</select>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-sm-4">&nbsp;</div>';
                html += '</div>';

                html += `
                    <div class="row">
                        <div class="col-md-12">
                            <div class="m-checkbox-inline">
                                <label class="m-checkbox m-checkbox--success" style="margin-bottom:0px;font-size:13px;padding-left: 20px;">
                                    <input type="checkbox" id="changeA2" value="true">
                                        Alterar também no cadastro do fornecedor.
                                    <span style="height: 15px;width: 15px;"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                `;
                return html;
            },
            getHtmlUpdateCoinTax: function (config) {
                let html = '';

                html += '<div class="row">';
                html += '	<div class="col-sm-4">&nbsp;</div>';
                html += '	<div class="col-sm-4">';
                html += '		<div class="form-group">';
                html += '			<label>Nova taxa:</label>';
                html += '			<div class="input-group">';
                html += '				<input id="coinTaxToUpdate" data-id-field="' + config.idField + '"  data-key-id="' + config.keyId + '" data-date="' + config.date + '" data-identify-value="' + config.identifyValue + '" data-value="' + config.value + '" type="text" class="form-control text-right" placeholder="Informe a taxa" value="' + numeral(config.coinTax).format('0,0.0000') + '">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';
                html += '	<div class="col-sm-4">&nbsp;</div>';
                html += '</div>';

                return html;
            },
            getHtmlUpdateValue: function (config) {
                let html = '';

                html += '<div class="row">';
                html += '	<div class="col-sm-4">&nbsp;</div>';
                html += '	<div class="col-sm-4">';
                html += '		<div class="form-group">';
                html += '			<label>Valor</label>';
                html += '			<div class="input-group">';
                html += '				<input id="valueToUpdate" data-level="' + config.idSplit[1] + '"data-id-customer="' + config.id_customer + '"data-company-token="' + config.company_token + '" data-source-table= "' + config.source_table + '" data-branch-token= "' + config.branch_token + '" data-id_field="' + config.idField + '" data-key-id="' + config.keyId + '" data-date="' + config.date + '" data-identify-value="' + config.identifyValue + '" type="text" class="form-control text-right" placeholder="Informe o valor" value="' + config.value + '" ' + 'data-keys=\'' + JSON.stringify(config.keys) + '\' data-erp_table_code="' + config.erp_table_code + '" data-erp_table="' + config.erp_table + '" data-internal_table_id="' + config.internalId + '">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';
                html += '	<div class="col-sm-4">&nbsp;</div>';
                html += '</div>';

                return html;
            },
            getHtmlUpdateTax: function (config) {
                let html = '';

                html += '<div class="row">';
                html += '	<div class="col-sm-4">&nbsp;</div>';
                html += '	<div class="col-sm-4">';
                html += '		<div class="form-group">';
                html += '			<label>Valor</label>';
                html += '			<div class="input-group">';
                html += '				<input id="valueToUpdate" data-id_field="' + config.idField + '" data-key-id="' + config.keyId + '" data-date="' + config.date + '" data-identify-value="' + config.identifyValue + '" data-level="' + config.level + '" data-erp_table_code="' + config.erp_table_code + '" data-erp_table="' + config.erp_table + '" data-value-original="' + config.value_original + '" data-value="' + config.value + '" data-tax_keys=\'' + JSON.stringify(config.tax_keys) + '\' type="text" class="form-control text-right" placeholder="Informe o valor" value="' + config.interest_rate + '">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';
                html += '	<div class="col-sm-4">&nbsp;</div>';
                html += '</div>';

                return html;
            },
            getHtmlUpdateIncrease: function (config){
                let html = '';

                if (config.description != '') {
                    html += '<div class="row">'
                    html += '<div class="col-sm-12 text-center" style="font-weight: bold;margin-bottom: 5px; color: red;">ATENÇÃO! Existe valor de ' + config.description + ' preenchido e será substituído.</div>'
                    html += '</div>'
                }

                html += '<div class="row">';
                html += '<div class="col-sm-2">&nbsp;</div>';
                html += '<div class="col-md-4">';
                html += '<div class="form-group">';
                html += '<label>Tipo</label>';
                html += '<select class="form-control hide" id="valueType" name="valueType" style="width: 100%;">';

                if (validateUserAccess(config.self, config.identifyValue, 'register_addition')) {
                    html += '<option value="A">Acréscimo</option>';
                }

                if (validateUserAccess(config.self, config.identifyValue, 'register_decrease')) {
                    html += '<option value="D">Decréscimo</option>';
                }

                html += '</select>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-sm-4">';
                html += '<div class="form-group">';
                html += '<label>Valor</label>';
                html += '<div class="input-group">';
                html += '<input id="valueToUpdate" data-level="' + config.level + '"data-id-customer="' + config.id_customer + '"data-company-token="' + config.company_token + '" data-source-table= "' + config.source_table + '" data-branch-token= "' + config.branch_token + '" data-id_field="' + config.idField + '" data-erp_table_code="' + config.erp_table_code + '" data-erp_table="' + config.erp_table + '" data-keys=\'' + JSON.stringify(config.keys) + '\' data-internal_table_id="' + config.id + '" data-default_value="' + config.default_value + '" data-default_type="' + config.default_type + '" data-tax_keys=\'' + JSON.stringify(config.tax_keys) + '\' data-value-original="' + config.valueOriginal + '" data-date="' + config.date + '" type="text" class="form-control text-right" placeholder="Informe o valor">';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="col-sm-2">&nbsp;</div>';
                html += '</div>';

                return html;
            },
            getHtmlUpdateCodigoBarras(config){
                //inicia o modal com os dados já iniciado
                let html = '', linhaDigitavel = '';
                let codBarra = config.codBar || '';

                if(codBarra){
                    try {
                        linhaDigitavel = Codbarras.construirLinhaDigitavel(codBarra, codBarra.substr(0, 1) == "8" ? "concessionaria" : "boleto");
                    } catch (error) {
                        linhaDigitavel = "";
                    }
                }

                html += '<div class="row">';
                html += '   <div class="col-sm-2">&nbsp;</div>';
                html += '	<div class="col-sm-8">';
                html += '		<div class="form-group">';
                html += '			<label>Linha Digitável</label>';
                html += '			<div class="input-group">';
                html += '				<input id="valueLinhaDigitavel" type="text" class="form-control text-right" value="' + linhaDigitavel +'">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';
                html += '</div>';

                html += '<div class="row">';
                html += '   <div class="col-sm-2">&nbsp;</div>';
                html += '	<div class="col-sm-8">';
                html += '		<div class="form-group">';
                html += '			<label>Código de Barras</label>';
                html += '			<div class="input-group">';
                html += '				<input';
                html += '                   id="valueToUpdate"';
                html += '                   maxlength="54"';
                html += '                   data-keys=\'' + JSON.stringify(config.keyId) + '\'';
                html += '                   data-internal_table_id="' + config.id + '"';
                html += '                   data-erp_table_code="' + config.erp_table_code + '"';
                html += '                   data-id-customer="' +  config.id_customer + '"';
                html += '                   data-company-token="' +  config.company_token + '"';
                html += '                   data-source-table="' +  config.source_table + '"';
                html += '                   data-branch-token="' +  config.branch_token + '"';
                html += '                   data-origin="' +  config.origin + '"';
                html += '                   type="text"';
                html += '                   class="form-control text-right"';
                html += '                   value="' + codBarra + '"';
                html += '               >';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';
                html += '</div>';

                return html;
            },
            /*
            *
            *   GetHtmlUpdateBankData
            *   classe responsavel por abrir modal de edicao de dados do modelo
            *   utilizado em remittancepayable e tratado por cashflow
            *   @link (cashflow.js).updateBankdata()
            *   @param config - traz as informacoes que serao preenchidas no html da modal sendo
            */
            getHtmlUpdateBankData(config){
                let html = '';

                html += '<div class="row">';
                html += '   <div class="col-md-2">&nbsp;</div>';
                html += '	<div class="col-md-8">';
                html += '		<div class="form-group">';
                html += '			<label>Código do Banco</label>';
                html += '			<div class="input-group">';
                html += '				<input id="updateInformationBank" type="text"  data-origin="' + config.origin +  '"data-id-customer="' + config.id_customer + '"data-company-token="' + config.company_token + '" data-source-table= "' + config.source_table + '" data-branch-token= "' + config.branch_token + '"  class="form-control text-right" maxlength="5" value="' + config.cp[config.cp.banco] + '">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';
                html += '</div>';

                html += '<div class="row">';
                html += '   <div class="col-md-2">&nbsp;</div>';
                if(this.company_group.use_digag == 1){
                    html += '	<div class="col-md-6">';
                }else{
                    html += '	<div class="col-md-8">';
                }
                html += '		<div class="form-group">';
                html += '			<label>Agência</label>';
                html += '			<div class="input-group">';
                html += '				<input id="updateInformationAgencia" type="text" class="form-control text-right" maxlength="5" value="' + config.cp[config.cp.agencia] + '">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';

                if(this.company_group.use_digag == 1){
                    html += '	<div class="col-md-2">';
                    html += '		<div class="form-group">';
                    html += '			<label>Dígito</label>';
                    html += '			<div class="input-group">';
                    html += '				<input id="updateInformationDigAgencia" type="text" class="form-control text-right" maxlength="2" value="' + config.cp[config.cp.digag] + '">';
                    html += '			</div>';
                    html += '		</div>';
                    html += '	</div>';
                }
                html += '</div>';

                html += '<div class="row">';
                html += '   <div class="col-md-2">&nbsp;</div>';
                 if(this.company_group.use_digcta == 1){
                    html += '	<div class="col-md-6">';
                }else{
                    html += '	<div class="col-md-8">';
                }
                html += '		<div class="form-group">';
                html += '			<label>Conta Corrente</label>';
                html += '			<div class="input-group">';
                html += '				<input id="updateInformationContaCorrente" type="text" class="form-control text-right" maxlength="10" value="' + config.cp[config.cp.conta] + '">';
                html += '			</div>';
                html += '		</div>';
                html += '	</div>';

                if(this.company_group.use_digcta == 1){
                    html += '	<div class="col-md-2">';
                    html += '		<div class="form-group">';
                    html += '			<label>Dígito</label>';
                    html += '			<div class="input-group">';
                    html += '				<input id="updateInformationDigContaCorrente" type="text" class="form-control text-right" maxlength="2" value="' + config.cp[config.cp.digcta] + '">';
                    html += '			</div>';
                    html += '		</div>';
                    html += '	</div>';
                }

                html += '</div>';
                html += '<div class="row">';
                html += '   <div class="col-md-2">&nbsp;</div>';
                html += '	<div class="col-md-8">';
                html += '		<div class="form-group">';
                html += '			<label>Tipo de conta</label>';
                html += '			<select class="form-control select2 hide" id="updateInformationTipcta" name="updateInformationTipcta" style="width: 100%;">';
                html += '				<option value="">&nbsp;</option>';
                html += '				<option value="1"'+(config.cp[config.cp.tipcta] === "1" ? ' selected' : '')+'>Conta corrente</option>';
                html += '				<option value="2"'+(config.cp[config.cp.tipcta] === "2" ? ' selected' : '')+'>Conta poupança</option>';
                html += '			</select>';
                html += '		</div>';
                html += '	</div>';
                html += '</div>';

                if (config.remittanceType === 5 || config.remittanceType === 6) {
                    html += '</div>';
                    html += '<div class="row">';
                    html += '   <div class="col-md-2">&nbsp;</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<div class="form-group">';
                    html += '			<label>CPF/CNPJ</label>';
                    html += '			<div class="input-group">';
                    html += '				<input id="providerCode" type="text" class="form-control text-center" value="' + (config.cp.A2_ZCGC ? config.cp.A2_ZCGC : '') + '">';
                    html += '			</div>';
                    html += '		</div>';
                    html += '	</div>';
                    html += '</div>';

                    html += '</div>';
                    html += '<div class="row">';
                    html += '   <div class="col-md-2">&nbsp;</div>';
                    html += '	<div class="col-md-8">';
                    html += '		<div class="form-group">';
                    html += '			<label>Nome</label>';
                    html += '			<div class="input-group">';
                    html += '				<input id="providerName" type="text" class="form-control text-left" value="' + (config.cp.A2_ZNOME ? config.cp.A2_ZNOME : '') + '">';
                    html += '			</div>';
                    html += '		</div>';
                    html += '	</div>';
                    html += '</div>';
                }

                html += '<br>';
                html += '<div class="row">';
                html += '  <span class="form-text text-muted">*Será alterado o cadastro do fornecedor no ERP.</span>';
                html += '</div>';

                return html;
            },
            loadCompanyGroup: function () {
                var self = this;
                blockPage('Carregando os dados...');

                $.ajax({
                    method: "GET",
                    url: self.$store.state.API_BASE + 'companygroup/listOne/' + self.$session.get('company_group_id'),
                    headers: {
                        'token': self.$session.get('token')
                    },
                }).done(function (result) {
                    self.company_group = result.data[0];
                    unblockPage();
                }).fail(function (error) {
                    console.log(error.responseText);
                    unblockPage();
                });
            },
            getCompanyConfig (company_erp) {
                return this.$http.get(this.$store.state.API_BASE + 'company/getConfig/' + company_erp, {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
                        result.body.data.forEach(item => {
                            this.config.companyConfig = item;
                        });

                        $('#modalUpdateInformation').modal('show');

                        unblockPage();
                    },
                    (error) => {
                        $('#modalUpdateInformation').modal('show');
                        unblockPage();
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>
