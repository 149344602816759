import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"notification-menu",attrs:{"width":"440"}},[_c(VList,{staticStyle:{"cursor":"pointer","background-color":"rgb(44, 46, 62)"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text"},[_vm._v(" Notificações ")])],1)],1)],1),(!_vm.notifications.length)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"teal"}}):_vm._e(),_c(VList,{staticClass:"notification-area"},_vm._l((_vm.notifications),function(notification){return _c(VListItem,{key:notification.id,style:({ background: _vm.getBackground(notification) })},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":_vm.getColor(notification)}},[_vm._v(" "+_vm._s(_vm.getIcon(notification))+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.getMessage(notification)))])]),_c(VListItemSubtitle,[_c('span',[_vm._v("Início: "),_c('b',[_vm._v(_vm._s(_vm.filterDate(notification.init_datetime)))])])]),(notification.finish_datetime)?_c(VListItemSubtitle,[_c('span',[_vm._v("Término:"),_c('b',[_vm._v(_vm._s(_vm.filterDate(notification.finish_datetime)))])])]):_vm._e()],1),_c(VListItemAction,{staticClass:"item-action"},[_c(VChip,{staticClass:"item-action-label",attrs:{"color":_vm.getLabelColor(notification),"text-color":"white"}},[_vm._v(_vm._s(_vm.getLabelText(notification)))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }