<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'company' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
				<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab" aria-expanded="true">
							Cadastro
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab" aria-expanded="false">
							Configurações
						</a>
					</li>
				</ul>
				<div class="tab-content" id="frmCompany">
					<div class="tab-pane active" id="tab1" role="tabpanel">
						<div class="row" id="frmCompanyRegister">
							<div class="col-md-3">
								<div class="form-group">
									<label>CNPJ</label>
									<input type="text" class="form-control" field-name="CNPJ" id="cnpj" name="cnpj" required>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>IE</label>
									<input type="text" class="form-control" field-name="IE" id="state_registration" name="state_registration" required>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label>Nome abreviado</label>
									<input type="text" class="form-control" field-name="Nome abreviado" id="initials" name="initials" required>
								</div>
							</div>
							<div class="col-md-5">
								<div class="form-group">
									<label>Nome fantasia</label>
									<input type="text" class="form-control" field-name="Nome fantasia" id="fantasy_name" name="fantasy_name" required>
								</div>
							</div>
							<div class="col-md-7">
								<div class="form-group">
									<label>Razão social</label>
									<input type="text" class="form-control" field-name="Razão social" id="description" name="description" required>
								</div>
							</div>
							<div class="col-md-7">
								<div class="form-group">
									<label>Endereço</label>
									<input type="text" class="form-control" field-name="Endereço" id="street" name="street" required />
								</div>
							</div>
							<div class="col-md-5">
								<div class="form-group">
									<label>Bairro</label>
									<input type="text" class="form-control" field-name="Bairro" id="neighborhood" name="neighborhood" required />
								</div>
							</div>
							<div class="col-md-5">
								<div class="form-group">
									<label>Cidade</label>
									<select class="form-control select2City hide" field-name="Cidade" id="city_id" name="city_id" style="width: 100%;">
										<option value="">Selecione...</option>
										<!--<option value="4207007">Içara</option>
										<option value="4204608">Criciúma</option>
										<option value="4218707">Tubarão</option>
										<option value="4201406">Araranguá</option>
										<option value="4205407">Florianópolis</option>-->
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>CEP</label>
									<input type="text" class="form-control" field-name="CEP" id="zipcode" name="zipcode" required />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label>Telefone</label>
									<input type="text" class="form-control" field-name="Telefone" id="phone" name="phone" required />
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="tab2" role="tabpanel">
						<div class="row">
							<div class="col-md-4">
								<div class="form-group">
									<label>Erp</label>
									<select class="form-control select2 hide" field-name="Erp" id="erp_id" name="erp_id" style="width: 100%;">
										<option value="">Selecione...</option>
										<option v-for="erp in erps" :value="erp.id">{{ erp.description }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label>Código da empresa no ERP</label>
									<input type="text" class="form-control" field-name="Código da empresa no ERP" id="erp_company_code" name="erp_company_code" required />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label>Dias para importação</label>
									<input type="text" class="form-control" field-name="Dias para importação" id="import_days" name="import_days" required />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label>Hora para importação</label>
									<input type="text" class="form-control" field-name="Hora para importação" id="import_time" name="import_time" required />
								</div>
							</div>
			                <!--<div class="col-md-4">
			                    <div class="form-group">
			                        <label>Imagem</label>
			                        <input type="text" class="form-control" field-name="Imagem" id="image" name="image">
			                    </div>
			                </div>-->
						</div>
					</div>
				</div>


	        </div>
	    </div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
            	erps: []
            }
        },

        mounted: function () {
        	this.loadData();
            this.loadMethod();
        },

        methods: {
        	loadData: function () {
        		var self = this;
            	blockPage('Carregando os dados...');

				$("#city_id").select2({
					ajax: {
						url: self.$store.state.API_BASE+"city/listCitys",
						dataType: 'json',
						delay: 250,
						headers: {
							'token': self.$session.get('token')
						},
						data: function (params) {
							var par = {
								q: params.term
							};
							return par;
						},
						processResults: function (data) {
							return {
								results: data
							};
						},
						cache: true
					},
					minimumInputLength: 4
				});

				$("#erp_id").select2();

				$.ajax({
					method: "GET",
					url: self.$store.state.API_BASE+'erp/listAll',
					assync: true,
                    headers: {
                        'token': self.$session.get('token')
                    }
				}).done(function(result) {
                    self.erps = result.data
	            	unblockPage();
				}).fail(function(error){
					console.log(error.responseText);
	            	unblockPage();
				});

				if (self.$session.get('user_type') == 'O') {
					$.ajax({
						method: "GET",
						url: self.$store.state.API_BASE+'companygroup/listAll',
						assync: true,
                        headers: {
                            'token': self.$session.get('token')
                        }
					}).done(function(result) {
						var options = "";

						$.each(result.data, function(resultItem) {
							options = options +'<option value="'+result.data[resultItem].id+'">'+result.data[resultItem].description+'</option>';
						});

						$("#frmCompanyRegister").prepend(
							'<div class="col-md-12">'+
								'<div class="form-group">'+
									'<label>Grupo de empresa</label>'+
									'<select class="form-control select2 hide" field-name="Grupo de empresa" id="company_group_id" name="company_group_id" style="width: 100%;">'+
										'<option value="">Selecione...</option>'+
										options+
									'</select>'+
								'</div>'+
							'</div>'
						);

						$("#company_group_id").select2();
		            	unblockPage();
					}).fail(function(error){
						console.log(error.responseText);
		            	unblockPage();
					});
				} else {
					$("#frmCompanyRegister").prepend(
						'<input type="hidden" id="company_group_id" name="company_group_id" value="'+self.$session.get('company_group_id')+'"/>'
					);
				}
			},

            loadMethod: function () {
            	var self = this;

				$("#import_days").mask("000");
				$("#import_time").mask("00:00");
				$("#cnpj").mask("00.000.000/0000-00");
				$("#phone").mask("(00) 00000-0000");
				$("#zipcode").mask("00000-000");

				$("#btn-save").click(function(){
					var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
		            var btn = $(this);

		            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					$.each($('input, select', '#frmCompany'),function(){
					    var $this = $(this);

					    if($.trim(this.value) == ''){
					        exitButton = true;
					        fieldName = $this.attr('field-name');

					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }

						if (this.id == 'import_time') {
							if ($this.val().length != 5) {
								exitButton = true;
								fieldName = $this.attr('field-name');

								$(".form-group:has(#"+this.id+")").addClass('has-danger');
								$("#"+this.id).addClass('input-error');
								$("#"+this.id).css('border-color','red');
								return false;
							} else {
								var hour = $this.val().split(':')[0];
								var minute = $this.val().split(':')[1];

								if ((hour < 0 || hour > 23) || (minute < 0 || minute > 59)) {
									exitButton = true;
									fieldName = $this.attr('field-name');

									$(".form-group:has(#"+this.id+")").addClass('has-danger');
									$("#"+this.id).addClass('input-error');
									$("#"+this.id).css('border-color','red');
									return false;
								}
							}
						}

						exitButton = false;
						arrayData[i] = {};
						arrayData[i][this.id] = this.value;

					    i++;
					});

					if(exitButton){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado, ou é invalido!', 'warning');
					    return false;
					}

					$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE+'company/new',
                        headers: {
                            'token': self.$session.get('token')
                        },
						data: {
							arrayData: JSON.stringify(arrayData)
						}
					}).done(function(result) {
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

						if(result.error == false){
							showNotification('Sucesso', 'Nova empresa registrada!','success');
						}else{
							showNotification('Atenção',result.message, 'danger');
							return false;
						}
					}).fail(function(error){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            }
        }
    }
</script>
