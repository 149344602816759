import configs from '../../config/config.json';

export default {
	API_BASE: configs.url.api_base,
	API_CLIENT_PREFIX: configs.url.api_client_prefix,
	API_IMPORT: configs.url.api_import,
	user: {
		token: '',
		name: '',
		email: '',
		status: '',
		user_group_id: '',
		user_group_name: '',
		user_type: '',
		company_group_id: '',
		company_group_name: '',
		user_gravatar: '',
	},
};
