<template>
    <div>
        <div class="collapse show">
            <div class="row levels level-two"
                 style="padding-top: 6px;padding-bottom: 6px;background-color: #bbd1e0;color: black;font-weight: bold;">
                <div class="col-sm-3" style="padding-right:0;padding-left:10px;">
                    <i :class="showSection ? 'fa fa-chevron-down item-collapse' : 'fa fa-chevron-right item-collapse'" data-toggle="collapse" @click="toggle"
                       style="font-size: 11px;padding-top: 3px;float: left;cursor: pointer;"></i>
                    <span class="description" style="padding-left: 3px;width: 170px;white-space: normal;">{{spanDescription}}</span>
                    <i class="la la-plus-circle" style="font-size: 18px;float: right;padding-top: 2px;color: green;cursor: pointer;margin-right: 15px;" @click="addSimulation"></i>
                </div>
                <div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">
                    <div class="row" style="padding-right:0;padding-left:0;">
                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>

                        <div
                                class="col-sm-2 text-right containerLevelTwo containerDate"
                                :data-date="dataValue.date" style="padding-right:0;padding-left:0;"
                                v-show="verifyShowDate(dataValue.date)"
                                v-for="(dataValue, idx) in mainData.data_values" :key="'L2-' + idx">
                            <span>{{dataValue.value | formatNumber}}</span>
                        </div>

                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="showSection" v-if="childName">
                <slot :name="childName"></slot>
            </div>

            <div v-show="showSection" v-for="(simulation, idx) in simulations" :key="idx" class="row simulation level_three"
                 style="padding-top: 6px;padding-bottom: 6px;color: black;font-weight: bold;">
                <div class="col-sm-3" style="padding-right:0;padding-left:10px;">
                    <input type="text" v-model="simulation.description" class="form-control form-control-sm m-input m-input--solid description" name="simulation_description" />
                </div>
                <div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">
                    <div class="row" style="padding-right:0;padding-left:0;">
                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>

                        <div
                                class="col-sm-2 text-right containerLevelTwo containerDate"
                                :data-date="dataValue.date" style="padding-right:0;padding-left:0;"
                                v-show="verifyShowDate(dataValue.dateHidden)"
                                v-for="(dataValue, idx) in simulation.data_values" :key="'L2S-' + idx">
                            <!-- <input type="text" v-model="dataValue.value" v-currency class="form-control form-control-sm m-input m-input--solid text-right containerLevelThreeSimulation simulationValue value" name="simulation_value" /> -->
                            <v-currency-field
                                v-model="dataValue.value"
                                reverse
                                dense
                                class="form-control form-control-sm m-input m-input--solid text-right containerLevelThreeSimulation simulationValue value"
                                name="simulation_value"
                                hide-details
                                @focus="$event.target.select()"
                                @keyup="calculateTotal"
                                style="font-size: 14px;"
                            />
                        </div>

                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                            <i class="la la-check-circle-o" style="font-size: 22px;padding-top: 8px;cursor:pointer;" @click="saveSimulation(simulation, idx)"></i>
                            <i class="la la-trash-o simulation" style="font-size: 22px;padding-top: 8px;cursor:pointer;" @click="removeSimulation(simulation, idx)"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['typeLevel', 'dataRows', 'cashFlowHiddenDates', 'childName', 'spanDescription', 'companys', 'allDates'],
        data() {
            return {
                showSection: false,
                description: "",
                mainData: this.dataRows
            }
        },
        watch: {
            dataRows: function (newVal, oldVal) {
                var self = this;
                self.mainData = newVal;
            }
        },
        mounted(){
            this.loadMethods();
            // this.calculateTotal();
        },
        computed: {
          simulations () {
            const simulations = [];

            for (const it of this.mainData.simulations) {
              const dates = [];

              for (const date of it.data_values) {
                dates.push({
                  dateHidden: date.dateHidden,
                  date: date.date,
                  value: numeral(date.value).format("0,0.00")
                });
              }

              if (dates.length < this.mainData.data_values.length) {
                const count = (this.mainData.data_values.length - dates.length);

                for (let i = dates.length; i <= count; i++) {
                  let newDate = this.mainData.data_values[i].date;
                  let newDateHidden = this.mainData.data_values[i].dateHidden;

                  // if ($("#periodicity").val() === 'monthly' || $("#periodicity").val() === 'semiannual') {
                  //     newDate = this.mainData.data_values[i].date + '-01';
                  // }

                  // if ($("#periodicity").val() === 'yearly') {
                  //     newDate = this.mainData.data_values[i].date + '-01-01';
                  // }

                  dates.push({
                    dateHidden: newDateHidden,
                    date: newDate,
                    value: numeral(0).format("0,0.00")
                  });
                }
              }

              simulations.push({
                id: it.id,
                description: it.description,
                data_values: dates
              });
            }

            return simulations;
          }
        },
        methods: {
            loadMethods () {
                // $(document).bind('DOMSubtreeModified', function () {
                //     $('.simulationValue').mask('00.000.000.000,00', {reverse: true});
                // });
            },
            calculateTotal () {
              const total = [];

              if (this.mainData.level_three) {
                for (const lvl3 of this.mainData.level_three) {

                  for (const data of lvl3.data_values) {
                    total.push({
                      date: data.date,
                      value: (data.value ? parseFloat(data.value) : 0)
                    });
                  }
                }
              }

              if (this.mainData.simulations) {
                for (const simulation of this.simulations) {
                  for (const item of simulation.data_values) {
                    for (const obj of total) {
                      if (item.date === obj.date || item.dateHidden === obj.date) {
                        obj.value = parseFloat(obj.value) + parseFloat(item.value);
                      }
                    }
                  }
                }
              }

              if (this.mainData.data_values) {
                for (const data of this.mainData.data_values) {
                  if (total) {
                    data.value = 0;
                    for (const it of total) {
                      if (it.date === data.date && it.value > 0) {
                        data.value += parseFloat(it.value);
                      }
                    }
                  }
                }
              }

              this.$emit('recalculeTotal', true);
            },
            toggle() {
                this.showSection = !this.showSection
            },
            verifyShowDate(dateParam) {
                var self = this;
                var lShow = true;
                for (var i = 0; i < self.cashFlowHiddenDates.length; i++) {
                    if (self.cashFlowHiddenDates[i].dateHidden == dateParam || self.cashFlowHiddenDates[i].dateNoConvert == dateParam) {
                        lShow = false;
                        break;
                    }
                }

                return lShow;

            },
            addSimulation () {
                const dates = [];

                for (const date of this.allDates) {
                    let newDate = date.dateNoConvert;

                    // if ($("#periodicity").val() === 'monthly' || $("#periodicity").val() === 'semiannual') {
                    //     newDate = date.date + '-01';
                    // }

                    // if ($("#periodicity").val() === 'yearly') {
                    //     newDate = date.date + '-01-01';
                    // }

                    dates.push({
                        dateHidden: date.dateHidden,
                        date: newDate,
                        value: numeral(0).format("0,0.00")
                    });
                }

                this.mainData.simulations.push({
                    id: null,
                    description: '',
                    data_values: dates
                });
            },
            saveSimulation (simulation, index) {
                const self = this;
                let program = 'addNew';
                let headers = {
                    'token': self.$session.get('token')
                };
                let arrayData = [];
                let arrayDataItens = [];
                let i = 0;
                let j = 0;

                arrayData[i] = {};
                arrayData[i]['cashflow'] = 'J';
                i++;

                if  (simulation.id) {
                    arrayData[i] = {};
                    arrayData[i]['simulation_id'] = simulation.id;
                    i++;

                    program = 'updateIten';
                }

                arrayData[i] = {};
                arrayData[i]['description'] = simulation.description;
                i++;

                arrayData[i] = {};
                arrayData[i]['grouper_iten_id'] = this.mainData.id;
                i++;

                for (const date of simulation.data_values) {
                    let newDate = date.date;

                    if ($("#periodicity").val() === 'monthly' || $("#periodicity").val() === 'semiannual') {
                        newDate = date.date + '-01';
                    }

                    if ($("#periodicity").val() === 'yearly') {
                        newDate = date.date + '-01-01';
                    }

                    arrayDataItens[j] = {};
                    arrayDataItens[j]['date'] = newDate;
                    arrayDataItens[j]['other_date'] = date.dateHidden;
                    arrayDataItens[j]['value'] = numeral().unformat(date.value);
                    j++;
                }

                arrayData[i] = {};
                arrayData[i]['company_id'] = this.companys;
                i++;

                arrayData[i] = {};
                arrayData[i]['itens'] = arrayDataItens;
                i++;

                arrayData[i] = {};
                arrayData[i]['type'] = $("#periodicity").val();

                const params = {
                    arrayData: JSON.stringify(arrayData)
                };

                self.$http.post(self.$store.state.API_BASE + `simulation/${program}`, params, {
                    headers: headers
                }).then(
                    function (result) {
                        showNotification('Sucesso', 'Dados registrados com sucesso!', 'success');
                        console.log(result);
                        unblockPage();
                    },
                    function (error) {
                        unblockPage();
                        showNotification('Atenção', 'Oops! Ocorreu um erro ao salvar os dados.', 'danger');
                        console.log(error);
                    }
                );
            },
            removeSimulation (simulation, index) {
                const self = this;
                let headers = {
                    'token': self.$session.get('token')
                };

                const params = {
                    simulation_id: simulation.id
                };

                self.$http.post(self.$store.state.API_BASE + 'simulation/removeIten', params, {
                    headers: headers
                }).then(
                    function (result) {
                        showNotification('Sucesso', 'Dados registrados com sucesso!', 'success');
                        this.mainData.simulations.splice(index, 1);
                        console.log('deu certo -> ', result);
                        unblockPage();
                        this.calculateTotal();
                    },
                    function (error) {
                        unblockPage();
                        showNotification('Atenção', 'Oops! Ocorreu um erro ao salvar os dados.', 'danger');
                        console.log('deu erro -> ', error);
                    }
                );
            }
        }
    }
</script>

<style>
    .v-text-field > .v-input__control > .v-input__slot::before {
        border-style: none !important;
    }
</style>
