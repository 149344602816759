<template>
  <v-row>
    <v-col>
      <breadcrumbs page-title="Arquivos de Cnab" returnPage />
      <v-card :loading="loading" class="elevation-0">
        <v-card-title>
           <v-col cols="12" lg="3" md="6" sm="12" xs="12">
            <v-select
            v-model="situation"
            :items="situationItems"
            filled
            dense
            hide-details
            placeholder="Selecione a situação"/>
          </v-col>
           <v-col cols="12" lg="3" md="6" sm="12" xs="12">
            <v-select
            v-model="type"

            :items="typeItems"
            filled
            dense
            hide-details
            placeholder="Selecione o tipo"/>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12" xs="12">
            <v-select
            v-model="status"
            :items="statusItems"
            filled
            dense
            hide-details
            placeholder="Selecione o status"/>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12" xs="12">
            <v-select
            v-model="companies"
            :items="companiesItems"
            filled
            dense
            hide-details
            placeholder="Selecione a empresa"
            item-text='name'
            item-value='id'/>
          </v-col>
          <v-col cols="12" lg="5" md="6" sm="12" xs="12">
            <inn-date-field
              v-model="initialDate"
              dense
              hide-details
              label="Processamento inicial"
            ></inn-date-field>
          </v-col>
          <v-col cols="12" lg="5" md="6" sm="12" xs="12">
            <inn-date-field
              v-model="finalDate"
              hide-details
              dense label="Processamento final">
            </inn-date-field>
          </v-col>
          <v-col cols="12" lg="2" md="6" sm="12" xs="12">
            <v-btn
              class="font-weight-bold"
              @click="onSubmit"
              large
              block
              depressed
              :loading="loading"
              color="success"
            >
              VISUALIZAR
            </v-btn>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12" xs="12"></v-col>
        </v-card-title>
        <v-card-text v-show="hasItens">
          <cnab-returns-upload-list-table :items="items" @downloadFile="downloadFile"/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import CnabFiles from "@/services/cnab_files.service";
import ListAddButton from "@/components/buttons/ListAddButton.vue";
import ListStructure from "@/components/defaults/ListStructure.vue";
import CnabReturnsUploadListTable from "./CnabReturnsUploadListTable.vue";
import FormCancelButton from "@/components/buttons/FormCancelButton.vue";
import FormSaveButton from "@/components/buttons/FormSaveButton.vue";
import FormStructure from "@/components/defaults/FormStructure.vue";
import PageContainer from "@/components/defaults/PageContainer.vue";
import Disclaimer from "@/components/defaults/Disclaimer.vue";
import Breadcrumbs from "@/components/defaults/breadcrumbs.vue";
import CompanyService from "@/services/company.service";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    PageContainer,
    FormStructure,
    FormCancelButton,
    FormSaveButton,
    Disclaimer,
    Breadcrumbs,
    ListStructure,
    ListAddButton,
    CnabReturnsUploadListTable
  },

  data() {
    return {
      dropzoneOptions: {
        url: () => `${this.$store.state.API_BASE}/cnab/import`,
        headers: { token: this.$session.get("token") },
        dictRemoveFile: "Remover",
        dictInvalidFileType: "Tipo do arquivo inválido!",
        autoProcessQueue: true,
        duplicateCheck: true,
        acceptedFiles: ".RET,.TXT",
        parallelUploads: 1,
      },
      statusItems: [
        {
          text: "Todos os status",
          value: "",
        },
        {
          text: "Ativo",
          value: 'T',
        },
        {
          text: "Inativo",
          value: 'F',
        },
      ],
      situationItems: [
        {
          text: "Todas as situações",
          value: "",
        },
        {
          text: "Recém importado",
          value: 0,
        },
        {
          text: "Em importação",
          value: 1,
        },
        {
          text: "Enviado ao ERP",
          value: 2,
        },
      ],
      typeItems: [
        {
          text: "Todos os tipos",
          value: "",
        },
        {
          text: "Receber",
          value: 'RECEBER',
        },
        {
          text: "Pagar",
          value: 'PAGAR',
        }
      ],
      companiesItems: [
        { 
          name: 'Todas as empresas',
          id: "",
        },
      ],
      hasItens: false,
      situation:"",
      status: "",
      type: "",
      companies: "",
      loading: false,
      items: [],
      initialDate: this.$moment().format('YYYY-MM-DD'),
      finalDate: this.$moment().format('YYYY-MM-DD'),
    };
  },
  created() {
    this.getAllFiles();
    this.loadCompanies();
  },
  methods: {
    async loadCompanies() {
      try{
        const companyService = new CompanyService();
        const response = await companyService.index();
        const companiesData = response.data;
        this.companiesItems = this.companiesItems.concat(companiesData);
      } catch(error){
          this.$notification.error('Erro ao carregar empresas');
      }
    },

    sendManualFile() {
      this.$router.push({ name: "CnabReturnsSend" });
    },

    async getAllFiles() {
      try {
        this.loading = true;
        const cnabFiles = new CnabFiles();

        const response = await cnabFiles.files().index();

        this.items = response.data;
        this.hasItens = false;
      } catch (err) {
        this.$helpers.defaultCatchError(err);
      } finally {
        this.loading = false;
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const cnabFiles = new CnabFiles();
        const result = await cnabFiles.files().index("", {
          params: {
            initialDate: this.initialDate,
            finalDate: this.finalDate,
            companyId: this.companies,
            situation: this.situation,
            status: this.status,
            type: this.type,
          },
        });

        if (!result.data.length) {
          this.$notification.warn('Nenhum registro localizado');
          this.hasItens = false;
        }

        if (result.error) {
          this.$notification.error(`${result.message}`);
        }

        else {
          this.items = result.data;
          this.hasItens = true;
        }

      } catch (error) {
        this.$helpers.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },

    async downloadFile(id) {
      const cnabFiles = new CnabFiles();
      const result = await cnabFiles.download().show(id);

      if (result.error == true) {
        this.$notification.error(`${result.message}`);
        return false;
      }

      var blob = new Blob([result.file], { type: 'application/octet-stream' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = result.name;
      link.click();
      return true;
    },
  },
};
</script>
