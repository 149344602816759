<template>
	<section class="login-grid-layout h-100 w-100">
		<content class="h-100 d-block">
			<v-img
				class="h-100 d-block"
				src="@/assets/background/inncash-background.webp"
			/>
			<a
				href="https://innovaconnect.com.br/"
				target="_blank"
				class="innova_url d-lg-block d-none"
			>
				<v-img
					src="@/assets/images/innova-logo-horizontal.webp"
					contain
				/>
			</a>
		</content>

		<aside class="shadow-2__left index-1">
			<v-form
				class = "
					login-form-grid-layout
					h-100
					pa-4
					pa-lg-12
					pt-8
					pt-lg-12"
				v-model="valid"
				ref="authForm"
				@submit.prevent="onSubmit"
				lazy-validation
			>
				<v-container
					class="text-center"
					justify="center"
					align="center"
				>
					<v-row justify="center">
						<v-col cols="8">
							<slot name="logo">
								<v-img
									class="mt-5 mt-sm-0"
									src="@/assets/images/inncash-logo.webp"
									aspect-ratio="1.0"
									contain
									max-height="125"
								></v-img>
							</slot>
						</v-col>
					</v-row>
				</v-container>

				<v-container>
					<v-row>
						<slot name="form"></slot>
					</v-row>
				</v-container>

				<v-container>
					<v-row>
						<slot name="actions"></slot>
					</v-row>
				</v-container>
			</v-form>
		</aside>
	</section>
</template>

<script>
export default {
	name: 'LoginLayout',
	data() {
		return {
			valid: false,
		};
	},
	watch: {
		valid() {
			this.$emit('input', this.valid);
		},
	},
	methods: {
		onSubmit() {
			this.$emit('submit');
		},
		revalidate() {
			this.$refs.authForm.validate();
		},
	},
};
</script>

<style>

</style>

<style lang="sass" scoped>
@import '@/sass/breakpoints.sass'

.pp-2
	padding: 2%

.innova_url
	position: relative
	top: -10%
	width: 204px
	left: 1%
	margin: 3%
	cursor: pointer
	transition: 0.3s ease

	&:hover
		filter: brightness(80%)

.login-grid-layout
	display: grid
	grid-template: 13% / 100% 100%

	@include xl
		grid-template: 100%/66% 33.33%

	@include lg
		grid-template: auto / 55% auto

	@include md
		grid-template: 8% 100% / 100%

.login-form-grid-layout
	display: grid
	grid-template: 20% 40% 10% / auto
	row-gap:5%

	@include xl
		grid-template: 20% 47% 10% / 100%
		row-gap:7%

	@include lg
		grid-template: 20% 57% 10% / 100%
		row-gap:7%

	@include md
		grid-template: 20% 30% 10% / auto
		row-gap:2%

	@include sm
		display: grid
		grid-template: 20% 40% 10% / auto
		row-gap:5%

	@include xs
		display: grid
		grid-template: 20% 40% 10% / auto
		row-gap:5%
</style>
