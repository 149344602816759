<template>
	<tbody>
		<tr
			v-for="(item, index) in items"
			:key="item.id" 
			:class="{ 'disabled': isDisabled(item) }" 
			:data-id="index"
		>
			<template v-if="item.visible">
				<td class="text-left pr-0" style="min-width: 1% !important;">
					<div class="m-checkbox-inline">
						<label
							class="m-checkbox m-checkbox--success p-0"
							style="margin-bottom: 0px"
						>
							<input
								type="checkbox"
								:checked="isItemSelected(item)"
								:disabled="isDisabled(item)"
								@change="handleCheckItem(item, $event.target.checked)"
							/>
							<span
								style="
									height: 15px;
									width: 15px;
									background-color: white;
									position: relative;
									display: flex;
								"
							></span>
						</label>
					</div>
				</td>
				<td class="text-left" style="min-width: 40px">
					{{ item.e1_emissao | formatDate }}
				</td>
				<td class="text-left" style="min-width: 40px">
					{{ item.e1_vencrea | formatDate }}
				</td>
				<td class="text-left" style="min-width: 105px">
					{{ item.e1_prefixo }} - {{ item.e1_num }} -
					{{ item.e1_parcela ?  item.e1_parcela + ' - ' : '' }}
					{{ item.e1_tipo }}
				</td>
				<td class="text-left">
					{{ item.a1_nome }}
				</td>
				<td class="text-right">
					{{ item.e1_valor | formatNumber }}
				</td>
				<td class="text-right">
					{{ item.e1_saldo | formatNumber }}
				</td>
				<td class="text-center td-inline-input" v-if="type !== 'payment'">
					<currency-input
						@input="onValuesChange(item)"
						v-model="item.e1_decresc"
					 />
				</td>
				<td class="text-center td-inline-input" v-if="type !== 'payment'">
					<currency-input
						@input="onValuesChange(item)"
						v-model="item.e1_acresc"
					 />
				</td>
				<td class="text-center td-inline-input" v-if="type !== 'payment'">
					<currency-input
						@input="onReceivedChange(item)"
						v-model="item.received_value"
					/>
				</td>
				<td class="text-center td-inline-input" v-if="type !== 'payment' && hasOtherCurrency">
					<currency-input
						@input="onValuesChange(item)"
						v-model="item.e1_txmoeda"
					 />
				</td>
			</template>

			<td v-if="!item.visible" colspan="10" class="td-empty"></td>
		</tr>
	</tbody>
</template>

<script>
	import Vue from 'vue';
	import CurrencyInput from './CurrencyInput';

	Vue.config.silent = true;

	export default {
		name: 'SearchIncomeRecordListTableLine',
		components: {
			CurrencyInput,
		},
		props: {
			items: Array,
			selectedItems: Array,
			hasOtherCurrency: Boolean,
			checkItem: Function,
			type: {
				type: String, 
				default: ''
			},
			search: String,
		},
		created() {
			document.addEventListener('scroll', this.setVisibleItems);
			document.addEventListener('resize', this.setVisibleItems);
			document.addEventListener('keydown', this.searchFocus);
		},
		destroyed() {
			document.removeEventListener('scroll', this.setVisibleItems);
			document.removeEventListener('resize', this.setVisibleItems);
			document.removeEventListener('keydown', this.searchFocus);
		},
		mounted() {
			this.setVisibleItems();
		},
		watch: {
			'search'() {
				this.$nextTick(() => {                                      
        			this.setVisibleItems();
				});          
			},
			'items'(newItems, oldItems) {
				if( oldItems.length == 0 || newItems.length != oldItems.length ) {
					this.$nextTick(() => {                                      
						this.setVisibleItems();
					});  
				}
			}
		},
		methods: {
			onValuesChange(item) {
				let value = numeral(item.e1_saldo);

				if (item.e1_moeda != '01') {
					value = value * numeral(item.e1_txmoeda);
				}
				item.received_value = (numeral(value) + numeral(item.e1_acresc) - numeral(item.e1_decresc)).toFixed(2);
			},
			onReceivedChange(item) {
				item.received_value = numeral(item.received_value);
			},
			isItemSelected(item) {
				return this.selectedItems.some((selectedItem) => (
					selectedItem.id === item.id
				));
			},
			isDisabled(item, taxField) {
				if (taxField && item.e1_moeda == '01') {
					return true;
				}
				return item.e1_saldo == 0;
			},
			handleCheckItem(item, checked) {
				if( !checked ) {
					item.e1_decresc = 0;
					item.e1_acresc = 0;
					this.onValuesChange(item);
				}
				this.checkItem(item);
			},
			isScrolledIntoView(element) {
				const docViewTop = $(window).scrollTop();
				const docViewBottom = docViewTop + $(window).height();
				const elemTop = $(element).offset().top;
				const elemBottom = elemTop + $(element).height();

				return  ((( elemTop >= docViewTop) && (elemTop <= docViewBottom)) || ((elemBottom >= docViewTop) && (elemBottom <= docViewBottom)));
			},
			setVisibleItems() {
				const htmlList = document.querySelectorAll('tr[data-id]');
				
				if(htmlList.length > 0) {
					const newItems = [...this.items];
					let visibleItemsCount = 0;

					htmlList.forEach((element, index) => {
						newItems[index].visible = false;

						if (visibleItemsCount > 50) {
							return;
						}

						const isVisible = this.isScrolledIntoView(element);
						
						if (isVisible) {
							visibleItemsCount++;
						}

						newItems[index].visible = isVisible; 
					});
					
					this.items = [...newItems];
				}
			},
			searchFocus(event) {
				const searchInput = document.querySelector('input#search-input');
				if(searchInput !== undefined){
					if ( event.ctrlKey && ( event.which === 70 ) ){
						event.preventDefault();
						searchInput.focus();
					}
				}
			}
		},
	};
</script>

<style scoped>
	td {
		vertical-align: middle;
		padding: 0.65rem 0.2rem !important;
		height: 64px !important;
	}
	td,
	label {
		font-size: 14px !important;
		text-align: center;
	}
	.disabled {
		color: #B5B5B5 !important;
		cursor: not-allowed;
	}
	.disabled input {
		color: #B5B5B5 !important;
		background: #F4F5F8;
		cursor: not-allowed;
	}
	label {
		color: #575962 !important;
	}
	tr.group-row .v-simple-checkbox i {
		background: #fff;
		background-clip: content-box;
		padding: 5px;
	}
	.td-inline-input {
		width: 9% !important;
	}
	.change-size {
			transform: scale(0.800);
			transform-origin: center;
	}
	.inline-input {
		text-align: right;
		margin: 0px;
		padding: 0px;
		min-width: 125px !important;
			font-size: 1.15em;
		border: 0px !important;
	}
	.td-empty {
		color: transparent;
		text-align: left;
	}
</style>
