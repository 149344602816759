import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.isNewImporter() === false)?_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.isImporting ? 'sync' : '',attrs:{"icon":"","large":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-sync ")])],1)]}}],null,false,1962212500)},[_c('importer-dialog')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }