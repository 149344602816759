<template>
	<v-card>
		<v-list style="cursor: pointer; background-color: rgb(84, 86, 107);">
			<v-list-item @click.native.stop style='cursor: text'>
				<v-avatar class="mr-2" size="50">
					<img v-if="avatar" :src="avatar" alt="avatar" />
					<img v-if="!avatar" src="@/assets/user-placeholder.png" alt="avatar" />
				</v-avatar>
				<v-list-item-content>
					<v-list-item-title class="white--text">
						{{auth.name}}
					</v-list-item-title>

					<v-list-item-subtitle class="white--text">
						{{auth.email}}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider class="mt-n1"></v-divider>

		<v-list style="cursor: pointer">
			<v-list-item
				@click="$router.push('/user/profile')"
				class="my-n4"
			>
				<v-list-item-content>
					<v-list-item-title>
						Acessar o perfil
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-divider></v-divider>
		<v-card-actions>
			<v-spacer></v-spacer>

			<v-btn color="primary" text @click="logout()">Sair</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'AccountManager',
	data: () => ({
		auth: {},
		avatar: null,
	}),
	mounted() {
		this.fetch();
	},
	methods: {
		fetch() {
			this.loadUser();
		},
		loadUser() {
			const userData = this.$session.getAll();
			this.avatar = userData.user_gravatar;
			const user = {
				company_group: {
					name: userData.company_group_name,
					image: userData.company_group_image,
					id: userData.company_group_id,
				},
				name: userData.name,
				email: userData.email,
			};

			this.auth = user;
		},
		logout() {
			this.clearUserSession();

			this.$session.destroy();

			this.$router.push({ name: 'login' });
		},
		clearUserSession() {
			const payload = {
				token: '',
				name: '',
				email: '',
				status: '',
				user_group_id: '',
				user_group_name: '',
				user_type: '',
				company_group_id: '',
				company_group_name: '',
				user_gravatar: '',
			};

			this.$store.dispatch('changeUser', payload);
		},
	},
};
</script>
