<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :return-value.sync="model"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="selected"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        filled
        readonly
        append-icon="mdi-calendar"
        dense
        :hide-details="rules.length == 0"
        :rules="rules"
      >
      </v-text-field>
    </template>
    <v-date-picker v-model="model" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" class="uppercase" @click="menu = false">Cancelar</v-btn>
      <v-btn text color="primary" class="uppercase" @click="$refs.menu.save(model)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  name: 'InnDateField',
  inheritAttrs: false,
  mixins: [ModelManagementMixin],
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    shouldBeGreater: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    selected() {
      return this.$moment(this.model).format('DD/MM/YYYY');
    },
    rules() {
      const rules = [];
      const vm = this;

      if (this.required) rules.push((v) => !!v || 'Esse campo é obrigatório');

      if (this.shouldBeGreater) {
        rules.push((v) => {
          const today = vm.$moment();
          const selected = vm.$moment(v, 'DD/MM/YYYY');
          return selected.diff(today, 'days') >= 0 || 'Escolha uma data atual ou futura';
        });
      }

      return rules;
    },
  },
};
</script>

<style></style>
