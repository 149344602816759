<template>
	<v-card>
		<v-list style="cursor: pointer; background-color: rgb(44, 46, 62)">
			<v-list-item
				@click.native.stop=""
				style='cursor: text'
			>
				<v-list-item-content>
					<v-list-item-title class="white--text">Importação dos Dados</v-list-item-title>

					<v-list-item-subtitle class="white--text">
						Fazer a importação dos dados no ERP
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<div class="d-flex pa-10 cursor-pointer" v-if="!inJobProcess" @click.stop="callImport()">
			<div class="mx-auto my-5">
				<v-btn
					icon
					class="d-flex mx-auto my-2"
				>
					<v-icon class="display-2 text--secondary">mdi-sync</v-icon>
				</v-btn>

				<span class="d-flex mx-auto text--secondary">Sincronizar</span>
			</div>
		</div>
		<div class="d-flex pa-10" v-else>
			<div class="mx-auto my-5">
				<v-btn icon disabled class="d-flex mx-auto my-2">
					<v-icon class="display-2 sync">mdi-sync</v-icon>
				</v-btn>

				<span class="d-flex mx-auto text--disabled font-weight-medium text-center">
					Há um processo em andamento. Aguarde a finalização
				</span>
			</div>
		</div>
	</v-card>
</template>

<script>

import { getters, mutations } from '@/components/notifications/notification.state';
import JobService from '@/services/job.service';

export default {
	name: 'ImporterDialog',
	data: () => ({

	}),
	computed: {
		inJobProcess() {
			return getters.inJobProcess();
		},
	},
	methods: {
		async callImport() {
			try {
				const jobService = new JobService();
				const importerData = this.getImporterData();

				await jobService.import().send(importerData);

				mutations.importing(true);
			} catch (error) {
				mutations.importing(false);

				this.$helpers.defaultCatchError(error);
			}
		},
		getImporterData() {
			const companyGroupId = this.$session.get('company_group_id');
			const erpImportAPI = this.$store.state.API_IMPORT;

			const url = `${erpImportAPI}import?companyid=${companyGroupId}`;
			const serializedUrl = JSON.stringify(url);
			const method = 'GET';

			return {
				url: serializedUrl,
				method,
			};
		},
	},
};
</script>

<style scoped>

.in-btn_sync {
	transform: translate(-55px, 40px);
	position: absolute;
	color: #757575;
}

.import-btn__sync-active {
	transform: translate(-125px, 50px);
	position: absolute;
	color: #757575;
	width: 100%;
}

.importer-dialog {
	top: 75px !important;
	left: 75% !important;
	max-width: 400px;
}

.v-list-item__icon {
	margin: 16px 16px !important;
}

</style>
