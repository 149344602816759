<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Cadastro de Agrupador Nível 1"/>
			<v-card class="elevation-0">
				<v-card-title>
					<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
							v-model="filterStatus"
							filled
							dense
							hide-details
							:items="filterStatusItems"/>
					</v-col>
					<v-col cols="12" lg="6" md="5" sm="12" xs="12">
						<v-text-field
							filled
							dense
							hide-details
							prepend-inner-icon="mdi-magnify"
							placeholder="Pesquisar"
							v-model="filterTerm"
						/>
					</v-col>
					<v-spacer />
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<list-add-button @click="add"/>
						</v-col>
				</v-card-title>
				<v-card-text class="pa-6 pt-0">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Tipo</th>
								<th>Descrição</th>
								<th>Onde Aparece</th>
								<th></th>
							</tr>
						</thead>
						<draggable id="list_grouper_ordem" v-model="filteredList" @update="saveGroupersOrder()" tag="tbody">
							<tr v-for="grouper in filteredList" :key="grouper.id" :data-grouper-id="grouper.id">
								<td class="ui-draggable-handle" style="vertical-align: middle;">
									<v-chip
										v-if="grouper.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td class="ui-draggable-handle" style="vertical-align: middle;">{{ grouper.type_description }}</td>
								<td class="ui-draggable-handle" style="vertical-align: middle;">{{ grouper.description }}</td>
								<td class="ui-draggable-handle" style="vertical-align: middle;">{{ getWhereShowDescription(grouper.where_show) }}</td>
								<td class="m--align-right">
									<table-edit-button @click="edit(grouper)"/>
								</td>
							</tr>
						</draggable>
					</table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash'
	import draggable from 'vuedraggable'
	import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
	import ListAddButton from '@/components/buttons/ListAddButton.vue';
	import tableEditButton from '@/components/buttons/tableEditButton.vue';
	
	export default {
		data () {
			return {
				groupers: [],
				filteredList: [],
				filterTerm: '',
				filterStatus: '',
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				]
			}
		},
		components: {
			draggable,
			Breadcrumbs,
			ListAddButton,
			tableEditButton,
		},
		mounted: function () {
			this.loadBlocks();
		},
		methods: {
			filterData() {
				const filterStatus = this.filterStatus;
				const filterTerm = this.filterTerm.toLowerCase();
				var dataRet = this.groupers;

				if (!_.isEmpty(filterStatus)) {
					dataRet = _.filter(dataRet, function(data) { return data.status == filterStatus; })
				}

				if (!_.isEmpty(filterTerm)) { 
					dataRet = _.filter(dataRet, list => list.type_description.toLowerCase().indexOf(filterTerm) >= 0 || list.description.toLowerCase().indexOf(filterTerm) >= 0)
				}

				this.filteredList = dataRet;
			},
				loadBlocks: function () {
					blockPage('Carregando os dados...');

					this.$http.get(this.$store.state.API_BASE+'grouper/listAll', {headers: {'token': this.$session.get('token')}}).then(
						function (result) {
							this.groupers = result.body.data
							this.filterData();
							unblockPage();
						},
						function (error) {
							showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
							unblockPage();
						}
					).bind(this)
				},

			saveGroupersOrder: function() {
				blockPage('Salvando dados...');

				$.ajax({
						method: "POST",
						url: this.$store.state.API_BASE + 'grouper/updateOrder',
						headers: {
							'token': this.$session.get('token')
						},
						data: {
							arrayData: JSON.stringify(this.filteredList.map((group, index) => ({order: index + 1, grouper_id: group.id})))
						}
					}).done(function(result) {
						showNotification('Sucesso', 'Ordem gravada com sucesso!!!', 'success');
						unblockPage();
					}).fail(function(error){
						showNotification('Atenção','Oops! Ocorreu um erro ao gravar a ordem.', 'danger');
						unblockPage();
						console.log(error);
					});
			},
			getWhereShowDescription(whereShow) {
				let description = '';
				if(whereShow.indexOf('1') >= 0){
						description+='Fluxo Previsto; '
				}
				if(whereShow.indexOf('2') >= 0){
						description+='Fluxo Realizado; '
				}
				if(whereShow.indexOf('3') >= 0){
						description+='Faturamento;'
				}
				if(whereShow.indexOf('4') >= 0){
						description+='Fluxo Projetado;'
				}

				return description;
			},
			add() {
				this.$router.push('/grouper/new');
			},	
			edit(grouper) {
				const params = {
					grouper_id: grouper.id,
				};
				this.$router.push({name: 'editgrouper',params});
			},
		},
		watch:{
			filterTerm: function() {
				this.filterData();
			},
			filterStatus: function() {
				this.filterData();
			}
		}
	}
</script>
