<template>
  <v-container fluid>
    <v-row class="mx-n6">
      <v-col cols="12" lg="3" md="3" sm="12" xs="12"  >
        <date-select
          :disabledDates="{from: parseDate(searchData.emissionEndDate, 1)}"
          :value="searchData.emissionStartDate"
          label="Dt. Emissão Inicial"
          id="emissionStartDate"
          @change="searchData.emissionStartDate = $event"
        />
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" xs="12" >
        <date-select
          :disabledDates="{to: parseDate(searchData.emissionStartDate) }"
          :value="searchData.emissionEndDate"
          label="Dt. Emissão Final"
          id="emissionEndDate"
          @change="searchData.emissionEndDate = $event"
        />
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" xs="12" >
        <date-select
          :disabledDates="{from: parseDate(searchData.dueDateEndDate, 1)}"
          :value="searchData.dueDateStartDate"
          label="Dt. Vencimento Inicial"
          id="dueDateStartDate"
          @change="searchData.dueDateStartDate = $event"
        />
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" xs="12" >
        <date-select
          :disabledDates="{to: parseDate(searchData.dueDateStartDate) }"
          :value="searchData.dueDateEndDate"
          label="Dt. Vencimento Final"
          id="dueDateEndDate"
          @change="searchData.dueDateEndDate = $event"
        />
      </v-col>
    </v-row>
    <v-row class="mx-n6">
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <div class="form-group">
          <label>{{operationType === 'payment' ? 'Fornecedor' : 'Cliente'}}</label>
          <select
            class="form-control select2 hide"
            field-name="registration_number"
            id="registration_number"
            name="registration_number"
            style="width: 100%;"
            multiple="multiple"
          >
          </select>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <div class="form-group">
          <label>Valor</label>
          <formated-input
            inputClasses="form-control"
            field-name="Valor"
            id="value"
            name="value"
            :clearable="true"
            v-model="searchData.value" filter="formatNumber"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" xs="12" v-if="operationType !== 'payment'">
        <div class="form-group">
          <label>Status</label>
          <select
            class="form-control select2 hide"
            field-name="status"
            id="status"
            name="status"
            style="width: 100%;"
          >
            <option v-for="item in status" :value="item.code" :key="item.code">
              {{ item.label }}
            </option>
          </select>
        </div>
      </v-col>
    
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <div class="form-group">
          <label>Prefixo</label>
          <input
            class="form-control"
            field-name="Prefixo"
            id="prefix"
            name="prefix"
            v-model="searchData.prefix"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <div class="form-group">
          <label>Número</label>
          <input
            class="form-control"
            field-name="Número"
            id="number"
            name="number"
            v-model="searchData.number"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <div class="form-group">
          <label>Tipo</label>
          <select
            class="form-control select2 hide"
            field-name="crtype"
            id="crtype"
            name="crtype"
            style="width: 100%;"
            multiple="multiple"
          >
            <option
              v-for="type in types"
              :value="type.code"
              :key="type.code"
            >
              {{ type.label }}
            </option>
          </select>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <div class="form-group">
          <label>Ordenar por</label>
          <select
            class="form-control select2 hide"
            field-name="sort"
            id="sort"
            name="sort"
            style="width: 100%;"
          >
            <option v-for="sort in sortList" :value="sort.value" :key="sort.value">
              {{ sort.label }}
            </option>
          </select>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" xs="12">
        <v-btn
          color="primary"
          class="search-button"
					buttonClass="btn-success"
					@click="search"
				>
        <i class="la la-search pr-2"></i>
        Filtrar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomButton from '../../../../src/components/buttons/CustomButton.vue';
import DateSelect from '../../../../src/components/fields/DateSelect.vue';
import FormatedInput from '../../../../src/components/fields/FormatedInput.vue';

const INITIAL_SELECT_VALUE = [{ code: null, label: 'Em Branco' }];

export default {
  name: 'SearchIncomeRecordFilter',
  components: {
    DateSelect,
    CustomButton,
    FormatedInput,
  },
  props: {
    record: Object,
    setItems: Function,
    companyId: Number|String,
    bankId: Number|String,
    operationType: String,
  },
  data() {
    const date = moment(this.record.data_movimento).format('DD/MM/YYYY');
    return {
      types: [],
      searchData: {
        emissionStartDate: null,
        emissionEndDate: null,
        dueDateStartDate: date,
        dueDateEndDate: date,
        value: null,
        prefix: null,
        number: null,
        companyId: this.companyId,
        bank: this.record.banco,
        agency:
          "e5_agencia" in this.record
            ? this.record.e5_agencia
            : this.record.agencia,
        account:
          "e5_conta" in this.record
            ? this.record.e5_conta
            : this.record.numcon
      },
      status: [
        { code: 'OPEN', label: 'Em Aberto' },
        { code: 'WRITEOFF', label: 'Baixado' },
      ],
      sortList: [
        { label: 'Selecione', value: '' },
        { label: 'Em Branco', value: 'empty'},
        { label: 'Vencimento', value: 'date' },
        { label: 'Razão Social', value: 'name' },
        { label: 'Saldo', value: 'balance' },
      ],
    };
  },
  mounted() {
    $('#status').select2({ placeholder: 'Deixe em branco para todos' });
    $('#crtype').select2({ placeholder: 'Deixe em branco para todos' });
    $('#sort').select2({ placeholder: 'Selecione' });
    $('#registration_number').select2({
      placeholder: 'Deixe em branco para todos',
      minimumInputLength: 3,
      ajax: {
        url: (this.operationType === 'payment' ? `${this.$store.state.API_BASE}provider/listAll` : `${this.$store.state.API_BASE}erp/client/listAll`),
        data: (params) => {
          let data = {
            search: params.term,
            limit: 15,
          };

          if (this.operationType === 'payment') {
            data.company_id = this.companyId;
          } else {
            data.companyId = this.companyId;
          }

          return data;
        },
        headers: { token: this.$session.get('token') },
        processResults: (response) => {
          let result = [];

          if (!response.data) {
            return { results: result };
          }

          if (this.operationType === 'payment') {
            result = response.data.map((provider) => ({
              text: `${provider.a2_nome} ${provide.a2_loja}`,
              id: provider.a2_cod + provider.a2_loja,
            }));
          } else {
            result = response.data.map((client) => ({
              text: `${client.a1_nome} ${client.a1_loja}`,
              id: client.a1_cod + client.a1_loja,
            }));
          }

          return {
            results: result,
          };
        },
      },
    });
  },
  created() {
    this.loadTypes();
  },
  methods: {
    parseDate(date, plus) {
      if (!date) {
        return false;
      }
      return moment(date, 'DD/MM/YYYY').add(plus, 'days').toDate();
    },
    loadTypes() {
      this.$http
        .get((this.operationType === 'payment' ? `${this.$store.state.API_BASE}accountsPayment/types` : `${this.$store.state.API_BASE}accountsReceivable/types`), {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            if (!result.body.data || result.body.data.length <= 0) {
              return;
            }

            if (this.operationType === 'payment') {
              this.types = INITIAL_SELECT_VALUE.concat(
                result.body.data.map((type) => ({
                  label: type.e2_tipo,
                  code: type.e2_tipo,
                })),
              );
            } else {
              this.types = INITIAL_SELECT_VALUE.concat(
                result.body.data.map((type) => ({
                  label: type.e1_tipo,
                  code: type.e1_tipo,
                })),
              );
            }

            this.loadParameter();
          },
        );
    },
    loadParameter() {
      this.$http
        .get(`${this.$store.state.API_BASE}fieldparameter/searchIncomeRecord`, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            const { data } = result.body;
            if (!data) {
              return;
            }

            if (data.registration_number) {
              data.registration_number.forEach((number) => {
                const $option = $('<option selected></option>').val(number.id).text(number.text);
                $('#registration_number').append($option);
              });
              $('#registration_number').trigger('change');
            }

            if (data.type) {
              $('#crtype').val(data.type).trigger('change');
            }
            this.searchData.emissionStartDate = (data.emissionStartDate != "") ? data.emissionStartDate : this.searchData.emissionStartDate;
            this.searchData.emissionEndDate = (data.emissionEndDate != "") ? data.emissionEndDate : this.searchData.emissionEndDate;
            this.searchData.dueDateStartDate =(data.dueDateStartDate != "") ? data.dueDateStartDate : this.searchData.dueDateStartDate;
            this.searchData.dueDateEndDate  = (data.dueDateEndDate != "") ? data.dueDateEndDate : this.searchData.dueDateEndDate;
            this.searchData.value = data.value;
            this.searchData.prefix = data.prefix;
            this.searchData.number = data.number;

            let sort_value = data.assortment || this.sortList;
            $('#sort').val(sort_value);
            $('#sort').trigger('change');
          },
          () => {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger',
            );
            unblockPage();
          },
        );
    },
    search() {
      blockPage("Carregando os dados...");

      const value = this.getFormattedValue();

      let sortValue = $('#sort').val();

      if(sortValue == 'empty') {
        sortValue = '';
      }

      this.$emitter.emit("income_record_search");
      this.$http
        .get(
          `${this.$store.state.API_BASE}conciliation/getBankMovementsWithBalance`,
          {
            params: {
              ...this.searchData,
              value,
              registration_number: $('#registration_number').val(),
              status: (this.operationType === 'payment' ? 'OPEN' : $('#status').val()),
              type: $('#crtype').val(),
              operationType: this.operationType,
              assortment: sortValue,
            },
            headers: { token: this.$session.get('token') },
          },
        )
        .then(
          (result) => {
            this.setItems(result.body.data.map((item) => {
              item.received_value = numeral(item.e1_saldo);
              return item;
            }));
            unblockPage();
          },
          (error) => {
            if (!error.message || error.message !== 'Not Found') {
              // eslint-disable-next-line no-undef
              showNotification(
                'Atenção',
                'Opa! Parece que algo saiu errado, tente recarregar a página',
                'danger',
              );
            }
            unblockPage();
          },
        );
    },
    getFormattedValue() {
      let value = this.searchData.value;

      if( typeof value === 'string' ) {
        value = value.replace(',', '.');
        const num = Number(value);
        return num > 0 ? num.toFixed(2).replace('.', ',') : null;
      } else if ( typeof value === 'number' && value > 0 ) {
        return value.toFixed(2).replace('.', ',');
      } else {
        return null;
      }
    }
  },
};
</script>

<style scoped>
.v-text-field {
  padding-top: 0;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #575962 !important;
}
.search-button {
  width: 100%;
  margin-top: 32px;
}
</style>
