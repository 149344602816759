<template>
  <v-row>
    <v-col>
			<breadcrumbs page-title="Configurações Segmentos CNAB"/>
      <v-card class="elevation-0">
        <v-card-title>
          <v-col cols="12" lg="10" md="9" sm="12" xs="12">
            <v-text-field
							filled
							dense
							hide-details
							prepend-inner-icon="mdi-magnify"
							placeholder="Pesquisar"
							v-model="configs.filterTerm"
						/>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="12" xs="12">
						<list-add-button @click="add"/>
          </v-col>
        </v-card-title>
          <v-card-text class="pa-6 pt-0">
             <table class="table table-striped m-table">
              <thead>
                <tr>
                  <th>Banco</th>
                  <th>Descrição</th>
                  <th>Remessa</th>
                  <th>Nome do Arquivo</th>
                  <th>Extensão</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              <!-- eslint-disable-next-line -->
              <tr v-for="(cnabRemittanceConfig, index) in listData" :key="index">
                <td style="vertical-align: middle; text-align: center;">{{ cnabRemittanceConfig.codeBank }}</td>
                <td style="vertical-align: middle;">{{ cnabRemittanceConfig.description }}</td>
                <td style="vertical-align: middle;">{{ cnabRemittanceConfig.cnabRemittance.description}} - {{ cnabRemittanceConfig.cnabRemittance.size}} posições</td>
                <td style="vertical-align: middle;">{{ cnabRemittanceConfig.fileName }}</td>
                <td style="vertical-align: middle;">{{ cnabRemittanceConfig.extension}}</td>
                <td class="m--align-right">
									<table-edit-button @click="edit(cnabRemittanceConfig)"/>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import _ from 'lodash'
  import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
	import ListAddButton from '@/components/buttons/ListAddButton.vue';
	import tableEditButton from '@/components/buttons/tableEditButton.vue';


  export default {
    components: {
			Breadcrumbs,
			ListAddButton,
      tableEditButton,
		},
    data() {
      return {
        cnabRemittancesConfig: [],
        configs: {
          filterTerm: '',
        }
      }
    },

    mounted() {
      this.loadCnabRemittances();
    },


    computed: {
      listData() {
        let dataRet = this.cnabRemittancesConfig;

				if (this.configs.filterTerm) {
					dataRet = _.filter(dataRet, list => list.description.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 
          || list.codeBank.toString().toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 
          || list.cnabRemittance.size.toString().toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 
          || list.fileName.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 
          || list.cnabRemittance.description.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 
          || list.extension.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 
          )
        }

        return dataRet
      }
		},
    
    methods: {
      loadCnabRemittances: function () {
        blockPage('Carregando os dados...');

        this.$http.get(this.$store.state.API_BASE + 'cnabremittanceconfig/listAll', {headers: {'token': this.$session.get('token')}}).then(
          function (result) {
            this.cnabRemittancesConfig = result.body.data;
            unblockPage();
          },
          function (error) {
            showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
          }
        ).bind(this)
      },
      add() {
        this.$router.push('/cnabremittanceconfig/new');
      },  
      edit(cnabRemittanceConfig) {
				const params = {
					cnab_remittance_config_id: cnabRemittanceConfig.id,
				};
				this.$router.push({name: 'editcnabremittanceconfig',params});
			},
    },
  }
</script>
