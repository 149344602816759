<template>
  <v-subheader class="pa-0 breadcrumbs mb-4 mt-2">
    <!-- RETURN BUTTON -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon @click="returnToPreviousRoute">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </template>

      <span>Voltar</span>
    </v-tooltip>
    <!-- BACK TO HOME BUTTON -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="breadcrumb-link">
          <v-btn v-on="on" v-bind="attrs" icon @click="goToHomePage">
            <v-icon> mdi-home </v-icon>
          </v-btn>
        </div>
      </template>

      <span>Voltar a Página inicial</span>
    </v-tooltip>
    <!-- INNER ROUTES -->
    <template v-if="routes.length > 0">
      <template v-for="(route, index) of routes">
        <span class="separator" :key="`separator-${index}`">|</span>
        <router-link :to="route.path" class="breadcrumb-link" :key="`link-${index}`">
          {{ route.name }}
        </router-link>
      </template>
    </template>
    <span class="separator">|</span>
    <span class="breadcrumb-text">{{ pageTitle }}</span>
  </v-subheader>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    pageTitle: String,
  },
  methods: {
    goToHomePage() {
      this.$router.replace({ name: 'Home' });
    },
    returnToPreviousRoute() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="sass">
.breadcrumbs
	.separator
		font-weight: bold
		font-size: 18px
		margin: 0 8px

		&:first-of-type
			margin-left: 4px

	.breadcrumb-text,
	.breadcrumb-link
		font-size: 20px
		font-weight: bold
		color: #6A6A6A
</style>
