import Rest from './base/Rest';

export default class PayrollService extends Rest {
  static resource = 'payroll';

  listAll() {
    this.relationship = 'listAll';
    return this;
  }

  download() {
    this.relationship = 'download';
    return this;
  }
}
