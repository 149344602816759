<template>
  <v-app>
	<Toolbar v-if="!hide"></Toolbar>

	<Drawer v-if="!hide" :items="[]" v-model="drawer"/>

	<v-main class="main">
		<router-view/>
	</v-main>

	<Company
		v-if="companyVisible"
		:visible="companyVisible"
		v-on:setDialogVisible="actionCompany"
	/>
  </v-app>
</template>

<script>
import Drawer from './drawer/Drawer.vue';
import Toolbar from './toolbar/Toolbar.vue';

export default {
	name: 'AppView',
	components: {
		Drawer,
		Toolbar,
	},
	data: () => ({
		drawer: true,
		source: '',
		userData: {},
		items: [],
		avatar: {
			size: 32,
			tile: false,
			link: '',
		},
		message: true,
		companyVisible: false,
		user: '',
	}),
	computed: {
		hide() {
			return (
				this.$route.path === '/login'
				|| this.$route.path === '/register'
				|| this.$route.path == '/logout'
				|| this.$route.path == '/reset-password'
			);
		},
	},

	watch: {
		hide(valor) {
			if (!valor) {
				this.loadAccess();
			}
		},
	},
	methods: {
		/**
		 * loadAccess
		 * busca os dados de acesso para listar os menus em Drawer.vue
		 * filtra esses dados para retornar no objeto Items
		 * pega os valores de UserGroupAccessController
		 */
		// loadAccess() {
		//     const session = JSON.parse(sessionStorage.authenticated);
		//     const userGroupId = session.user.user_group_id;

		//     userService.access().read(`toMenu/${userGroupId}`).then((response) => {
		//         this.items = response.data;
		//     }).catch((error) => {
		//         console.error(error.response);
		//         this.$toasted.error('Erro ao buscar os menus de acesso
		// ao usuário, tente recarregar a página', { duration: 15000 });
		//     });
		// },
		myUser() {
			this.$router.push({ path: '/my-user' });
		},
		actionChangeDrawer(payload) {
			this.drawer = payload;
		},
		actionCompany(value) {
			this.companyVisible = value;
		},
	},
};
</script>

<style lang="scss">
.btn-primary {
	color: white;
}

.main {
	margin: 0px 0px 0px 64px;
	position: relative;
	background-color: #f0f0f7;
}
</style>
