<template>
  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title"></div>
      </div>
      <div class="m-portlet__head-tools">
        <router-link
          :to="{ name: 'bankconfig' }"
          class="m-btn btn btn-warning mr-3"
          >Cancelar</router-link
        >
        <button
          id="btn-save"
          v-on:click="save"
          type="button"
          class="m-btn btn btn-success"
        >
          Salvar
        </button>
      </div>
    </div>
    <div class="m-portlet__body">
      <div
        class="
          m-form m-form--label-align-right
          m--margin-top-20 m--margin-bottom-30
        "
      >
        <ul
          class="nav nav-tabs m-tabs-line m-tabs-line--2x m-tabs-line--primary"
          role="tablist"
        >
          <li class="nav-item m-tabs__item">
            <a
              class="nav-link m-tabs__link active"
              data-toggle="tab"
              href="#bankConfig"
              role="tab"
              aria-expanded="true"
            >
              Dados da Conta
            </a>
          </li>
          <li class="nav-item m-tabs__item">
            <a
              class="nav-link m-tabs__link"
              data-toggle="tab"
              href="#sendTypes"
              role="tab"
              aria-expanded="true"
            >
              Transações
            </a>
          </li>
        </ul>
        <div class="tab-content" id="frmBankConfig">
          <div class="tab-pane active" id="bankConfig" role="tabpanel">
            <div class="row">
              <div class="col-md-8">
                <input
                  class="form-control"
                  type="hidden"
                  name="company_group"
                  id="company_group"
                  :value="company_group_id"
                />
                <div class="form-group">
                  <label>Bancos</label>
                  <select
                    class="form-control hide select2"
                    field-name="Banco"
                    id="bank_code"
                    name="bank_code"
                    style="width: 100%"
                    required
                  >
                    <option :value="undefined">Selecione...</option>
                    <option
                      v-for="bank in banks"
                      :value="
                        JSON.stringify({
                          a6_cod: bank.a6_cod,
                          a6_agencia: bank.a6_agencia,
                          a6_conta: bank.a6_conta
                        })
                      "
                      :data-a6-cod="bank.a6_cod_full"
                      :data-a6-filial="bank.a6_filial"
                      :data-table-company-code="bank.table_company_code"
                      :data-a6-agencia="bank.a6_agencia_full"
                      :data-a6-dvage="bank.a6_dvage"
                      :data-a6-numcon-full="bank.a6_numcon_full"
                      :data-a6-dvcta="bank.a6_dvcta"
                      :data-keyid="bank.bank_key_id"
                      v-bind:key="bank.id"
                    >
                      {{ bank.a6_cod }} {{ bank.a6_agencia }}
                      {{ bank.a6_conta }} - {{ bank.a6_nome }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Tipo</label>
                  <select
                    class="form-control hide"
                    field-name="Tipo"
                    id="tipo"
                    name="Tipo"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option value="PAGAR">PAGAR</option>
                    <option value="RECEBER">RECEBER</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Código de Convênio com Banco</label>
                  <input
                    class="form-control"
                    maxlength="20"
                    id="convenio"
                    field-name="Convênio"
                    type="text"
                    v-model="bankConfig.convenio"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Apelido (Apenas CEF)</label>
                  <input
                    class="form-control"
                    maxlength="6"
                    id="apelido_cef"
                    field-name="Apelido"
                    type="text"
                    v-model="bankConfig.apelido_cef"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Banco da Conta p/ Conectividade</label>
                  <input
                    class="form-control"
                    maxlength="6"
                    id="banco_conectividade"
                    field-name="Banco da Conta p/ Conectividade"
                    type="text"
                    v-model="bankConfig.banco_conectividade"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Agência da Conta p/ Conectividade</label>
                  <input
                    class="form-control"
                    maxlength="10"
                    id="agencia_conectividade"
                    field-name="Agencia da Conta p/ Conectividade"
                    type="text"
                    v-model="bankConfig.agencia_conectividade"
                  />
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label>Dígito</label>
                  <input
                    class="form-control"
                    maxlength="6"
                    id="digage_conectividade"
                    field-name="Digito"
                    type="text"
                    v-model="bankConfig.digage_conectividade"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Número da Conta p/ Conectividade</label>
                  <input
                    class="form-control"
                    maxlength="15"
                    id="numcon_conectividade"
                    field-name="Número da Conta p/ Conectividade"
                    type="text"
                    v-model="bankConfig.numcon_conectividade"
                  />
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label>Dígito</label>
                  <input
                    class="form-control"
                    maxlength="6"
                    id="digconta_conectividade"
                    field-name="Digito"
                    type="text"
                    v-model="bankConfig.digconta_conectividade"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Chave Pix</label>
                  <input
                    class="form-control"
                    maxlength="32"
                    id="pix_key"
                    field-name="Chave Pix"
                    type="text"
                    v-model="bankConfig.pix_key"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="sendTypes" role="tabpanel">
            <div class="row">
              <div class="col-md-2">
                <label>Tipo de Transação</label>
                  <select
                    class="form-control ignore hide"
                    field-name="Forma de Envio"
                    v-model="form.transaction_type"
                    @change="onChangeTransactionType()"
                    required
                  >
                  <option value="">Selecione...</option>
                  <option value="E">Envio</option>
                  <option value="R">Retorno</option>
                  </select>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Forma de Envio</label>
                  <select
                    class="form-control ignore hide"
                    field-name="Forma de Envio"
                    id="send_type"
                    name="send_type"
                    v-model="form.send_type"
                    @change="onChangeSendType()"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option
                      v-for="(sendType, key) in $getConst('BANK_CONFIG_SEND_TYPES')"
                      :value="key"
                      :key="key"
                    >{{sendType}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4" v-if="form.send_type == '1'">
                <div class="form-group">
                  <label>Opções</label>
                  <select
                    class="form-control ignore hide select-cnab"
                    field-name="CNAB"
                    id="cnab_id"
                    name="cnab_id"
                    v-model="form.send_type_id"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option v-if="form.transaction_type === 'E'"
                      v-for="cnab in cnabRemittance"
                      :value="cnab.id"
                      :key="cnab.id"
                      :selected="form.send_type_id === cnab.id"
                    >
                      {{ cnab.description }}
                    </option>
                    <option v-if="form.transaction_type == 'R'"
                      v-for="cnab in cnabReturn"
                      :value="cnab.id"
                      :key="cnab.id"
                      :selected="form.send_type_id === cnab.id"
                    >
                      {{ cnab.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2" v-if="form.send_type == '2'">
                <div class="form-group">
                  <label>VAN</label>
                  <select
                    class="form-control ignore hide"
                    field-name="Detalhe"
                    id="van_id"
                    name="van_id"
                    v-model="form.send_type_id"
                    @change="onChangeSendTypeId()"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option
                      v-for="van in vans"
                      :value="van.id"
                      :key="van.id"
                      :selected="form.send_type_id === van.id"
                    >
                      {{ van.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" v-if="form.send_type == '3'">
                <div class="form-group">
                  <label>API</label>
                  <select
                    class="form-control ignore hide"
                    field-name="Detalhe"
                    id="api_id"
                    name="api_id"
                    v-model="form.send_type_id"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option
                      v-for="api in apis"
                      :value="api.id"
                      :key="api.id"
                      :selected="form.send_type_id === api.id"
                    >
                      {{ api.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4" v-if="form.send_type == '2'">
                <div class="form-group">
                  <label>Opções</label>
                  <select
                    class="form-control ignore hide"
                    field-name="Detalhe"
                    id="cnab_id"
                    name="cnab_id"
                    v-model="form.cnab_id"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option v-if="form.transaction_type === 'E'"
                      v-for="cnab in cnabRemittance"
                      :value="cnab.id"
                      :key="cnab.id"
                      :selected="form.cnab_id === cnab.id"
                    >
                      {{ cnab.description }}
                    </option>
                    <option v-if="form.transaction_type == 'R'"
                      v-for="cnab in cnabReturn"
                      :value="cnab.id"
                      :key="cnab.id"
                      :selected="form.cnab_id === cnab.id"
                    >
                      {{ cnab.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!clearable"
                      color="red"
                      small
                      fab
                      @click="clear"
                      v-bind="attrs"
                      v-on="on"
                      class="my-4 mt-8 py-0 flex-item mr-5"
                    >
                      <v-icon color="white"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Limpar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!valid"
                      color="green"
                      small
                      fab
                      @click="addSendType"
                      v-bind="attrs"
                      v-on="on"
                      class="my-4 mt-8 py-0 flex-item"
                    >
                      <v-icon color="white"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar</span>
                </v-tooltip>
              </div>
            </div>
            <div class="row">
              <table class="table table-striped m-table">
                <tbody>
                  <tr
                    v-for="sendType in bankConfig.send_types"
                    v-bind:key="sendType.id"
                  >
                    <td style="vertical-align: middle">
                      {{ sendTypeLabel(sendType) }}
                    </td>
                    <td class="m--align-right">
                      <v-btn
                        icon
                        color="error"
                        @click="confirmRemove(sendType)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <v-dialog
              v-model="showRemoveConfirm"
              max-width="490"
            >
              <v-card>
                <v-card-title class="headline">
                  Atenção
                </v-card-title>

                <v-card-text>
                  Deseja remover a forma de envio {{sendTypeLabel(selectedSendType)}}?
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green"
                    class="white--text"
                    @click="closeRemoveConfirm"
                  >
                    Não
                  </v-btn>

                  <v-btn
                    color="orange"
                    class="white--text"
                    @click="removeSendType"
                  >
                    Sim
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-error {
  border-color: red;
}
</style>

<script>
import ApiBankingService from '@/services/apibanking.service'

export default {
  data() {
    return {
      apis: [],
      banks: [],
      cnabRemittance: [],
      cnabReturn: [],
      bankConfig: {send_types: []},
      vans: [],
      form: {
        send_type: 0,
        send_type_id: null,
        cnab_id: null,
      },
      company_group_id: undefined,
      bank: null,
      isDataLoading: false,
      selectedSendType: null,
      showRemoveConfirm: false
    }
  },
  mounted: async function () {
    await this.loadData()
  },
  computed: {
    valid() {
      if (!this.form.transaction_type) {
        return false;
      }
      if (!this.form.send_type || !this.form.send_type_id) {
        return false;
      }
      if (this.form.send_type == '2' && !this.form.cnab_id) {
        return false;
      }
      return true;
    },
    clearable(){
      if (!this.form.transaction_type && !this.form.send_type) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onChangeTransactionType() {
      this.form.send_type = '';
      this.form.cnab_id = '';
      this.form.send_type_id = '';
    },
    onChangeSendType(){
      this.form.cnab_id = '';
      this.form.send_type_id = '';
    },
    onChangeSendTypeId(){
      this.form.cnab_id ='';
    },
    clear() {
      this.form.transaction_type = '';
      this.form.send_type = '';
      this.form.cnab_id = '';
      this.form.send_type_id = '';
    },
    async loadData() {
      blockPage()

      $('.select2').select2();
      $('#bank_code').change(() => {
        if (!this.isDataLoading) {
          this.bankConfig.send_types = [];
        }
        this.isDataLoading = false
        this.loadCnabs();
        this.loadAPIs();
      });
      await this.loadBanks();

      //configura os valores que sao usados ou na edicao ou na inscricao
      if (this.$route.params.edit == 'new') {
        this.company_group_id = this.$session.get('company_group_id')
      } else {
        this.isDataLoading = true;
        await this.loadBankConfig()
        this.company_group_id = this.bankConfig.company_group
        this.inputControl()
      }
      this.loadVans();
    },
    async loadBankConfig() {
      await this.$http
        .get(
          this.$store.state.API_BASE +
            'bankconfig/listOne/' +
            this.$route.params.edit,
          { headers: { token: this.$session.get('token') } }
        )
        .then(
          (result) => {
            this.bankConfig = result.body.data
          },
          () => {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger'
            )
          }
        )
    },
    async loadBanks() {
      let params = {
        companys: null,
        fluxcai: null
      }

      await this.$http
        .get(this.$store.state.API_BASE + 'bank/listAllByGroup', {
          headers: { token: this.$session.get('token') },
          params: params
        })
        .then(
          (result) => {
            this.banks = result.body.data
            unblockPage()
          },
          () => {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger'
            )
            unblockPage()
          }
        )
    },
    loadVans() {
      this.$http
        .get(this.$store.state.API_BASE + 'van/listAll', {
          headers: { token: this.$session.get('token') }
        })
        .then(
          function (result) {
            this.vans = result.body.data
          },
          function () {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger'
            )
            unblockPage()
          }
        )
    },
    async loadAPIs() {
      let bank = JSON.parse($('#bank_code').val());
      
      const apiBankingService = new ApiBankingService()

      try {
        const result = await apiBankingService.listAll().show(bank.a6_cod)
        this.apis = result.data
      } catch (error) {
        this.$helpers.defaultCatchError(error)
      }
    },
    loadCnabs() {
      let bank = JSON.parse($('#bank_code').val());
      let operationCnabRemittance = 0;
      let operationCnabReturn = 1;

      this.$http
        .get(
          this.$store.state.API_BASE +
            'cnabremittanceconfig/listAllByBank/' +
            bank.a6_cod +'/operation/' + operationCnabRemittance,
          { headers: { token: this.$session.get('token') } }
        )
        .then(
          function (result) {
            this.cnabRemittance = result.body.data;
          },
          function () {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger'
            )
            unblockPage()
          }
        )
      this.$http
        .get(
          this.$store.state.API_BASE +
            'cnabremittanceconfig/listAllByBank/' +
            bank.a6_cod +'/operation/' + operationCnabReturn,
          { headers: { token: this.$session.get('token') } }
        )
        .then(
          function (result) {
            this.cnabReturn = result.body.data;
          },
          function () {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger'
            )
            unblockPage()
          }
        )
    },
    sendTypeLabel(sendType) {
      if (!sendType) {
        return;
      }
      // 1: 'CNAB',
      // 2: 'VAN',
      // 3: 'API',
      const transactionTypeLabel = this.formatTransactionTypeLabel(sendType);
      const sendTypeLabel = this.$getConst('BANK_CONFIG_SEND_TYPES')[sendType.send_type] || '';
      const valueLabel = this.getLabelFromArray(
        this.getValueList(sendType), 
        sendType.send_type_id, 
        'id', 
        sendType.send_type == 3 ? 'name' : 'description'
      );
      const cnabLabel = this.formatCnabLabel(sendType);
      return `${transactionTypeLabel} ${sendTypeLabel}: ${valueLabel} ${cnabLabel}`;
    },
    formatCnabLabel(sendType) {
      if (sendType.transaction_type == 'E') {
         const result = sendType.send_type == 2 ? ' | ' 
         + this.getLabelFromArray(this.cnabRemittance, sendType.cnab_id, 'id', 'description') : '';
          return result;
      }
      if (sendType.transaction_type == 'R') {
         const result = sendType.send_type == 2 ? ' | ' 
         + this.getLabelFromArray(this.cnabReturn, sendType.cnab_id, 'id', 'description') : '';
          return result;
      }
    },
    getValueList(sendType) {
      if (sendType.send_type == 1) {
        if(sendType.transaction_type == 'E') {
          return this.cnabRemittance;
        }
         if(sendType.transaction_type == 'R') {
           return this.cnabReturn;
         }
      }
      if (sendType.send_type == 2) {
        return this.vans;
      }
      return this.apis;
    },
    getLabelFromArray(array, id, compareKey, valueKey) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element[compareKey] == id) {
          return element[valueKey];
        }
      }
      return '';
    },
    formatTransactionTypeLabel(sendType){
      if (sendType.transaction_type == 'R') {
        return 'RETORNO |';
      } 
      return 'ENVIO |'
    },
    addSendType() {
      if (!this.bankConfig.send_types) {
        this.bankConfig.send_types = [];
      }

      const alreadInserted = this.bankConfig.send_types.find(type => {
        if (
          type.transaction_type == this.form.transaction_type &&
          type.send_type == this.form.send_type &&
          type.send_type_id == this.form.send_type_id &&
          (this.form.send_type != 2 || type.cnab_id == this.form.cnab_id)
        ) {
          return true;
        }
        return false;
      });

      if (alreadInserted) {
        showNotification(
          'Atenção',
          'A forma de envio já está na lista!',
          'warning'
        )
        return;
      }

      const bank_id = $('#bank_code option:selected').data('keyid');

      this.bankConfig.send_types.push(
        {
          client_bank_id: bank_id,
          bank_config_id: this.bankConfig.id,
          send_type: this.form.send_type,
          transaction_type: this.form.transaction_type,
          send_type_id: this.form.send_type_id,
          cnab_id: this.form.send_type == 2 ? this.form.cnab_id : null,
        }
      );
    },
    confirmRemove(sendType) {
      this.selectedSendType = sendType;
      this.showRemoveConfirm = true;
    },
    closeRemoveConfirm() {
      this.selectedSendType = null;
      this.showRemoveConfirm = false;
    },
    removeSendType() {
      if (!this.selectedSendType) {
        return;
      }
      this.bankConfig.send_types = this.bankConfig.send_types.filter(
        current => { 
          return current.send_type != this.selectedSendType.send_type ||
            current.send_type_id != this.selectedSendType.send_type_id ||
            current.cnab_id != this.selectedSendType.cnab_id
          ;
        }
      );
      this.closeRemoveConfirm();
    },
    save() {
      let checkError = false
      let arrayData = {}

      if ((this.$route.params.edit == 'new') != 'new')
        arrayData['id'] = this.bankConfig.id

      $('.form-control').not('.ignore').each((_, element) => {
        //Logica de filtragem por valores inseridos
        if ($(element).attr('required') && !$(element).val()) {
          showNotification(
            'Atenção',
            'O campo <strong>' +
              $(element).attr('field-name') +
              '</strong> não foi informado!',
            'warning'
          )

          $(element).addClass('input-error')
          $(element).bind('keyup change', function (target) {
            if ($(this).hasClass('input-error'))
              $(this).removeClass('input-error')
          })

          checkError = true
        }
        //Logica para implementar os dados
        if ($(element)[0].id == 'bank_code') {
          let bank = JSON.parse($(element).val())

          arrayData['banco'] = bank.a6_cod;
          arrayData['agencia'] = bank.a6_agencia;
          arrayData['conta'] = bank.a6_conta;

          const opt = $(element).find('option:selected');
          arrayData['bancoFull'] = opt.data('a6-cod');
          arrayData['filial'] = opt.data('a6-filial');
          arrayData['table_company_code'] = opt.data('table-company-code');
          arrayData['agenciaFull'] = opt.data('a6-agencia');
          arrayData['digitoAgencia'] = opt.data('a6-dvage');
          arrayData['contaFull'] = opt.data('a6-numcon-full');
          arrayData['digitoConta'] = opt.data('a6-dvcta');
        } else {
          if ($(element).val() == '') {
            arrayData[$(element)[0].id] = ''
          } else {
            arrayData[$(element)[0].id] = $(element).val()
          }
        }
      })

      if (checkError) return false

      arrayData['sendTypes'] = this.bankConfig.send_types || [];

      //insercao no banco de dados atraves do bankconfigcontroller
      $.ajax({
        method: 'POST',
        url: this.$store.state.API_BASE + 'bankconfig/save/',
        headers: {
          token: this.$session.get('token')
        },
        data: arrayData
      })
        .done((result) => {
          if (!result.error) {
            showNotification(
              'Sucesso',
              'Processo concluído com sucesso!',
              'success'
            )
            this.$router.replace({ name: 'bankconfig' })
          }
        })
        .fail((error) => {
          let fail = JSON.parse(error.responseText)
          showNotification('Atenção', fail.message, 'danger')
          return false
        })
    },

    inputControl() {
      $('.form-control').each((_, element) => {
        if ($(element)[0].id == 'bank_code') {
          $(element).val(
            JSON.stringify({
              a6_cod: this.bankConfig.banco,
              a6_agencia: this.bankConfig.agencia,
              a6_conta: this.bankConfig.conta
            })
          )
          $(element).trigger('change')
        } else if (this.bankConfig[$(element)[0].id]) {
          $(element).val(this.bankConfig[$(element)[0].id])
        }
      })
    }
  }
}
</script>
