import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,[_c(VRow,{staticClass:"mb-12"},[_c(VCol,{staticClass:"d-flex flex-row my-5",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":_vm.companyGroup.url_image,"max-width":"400"}})],1),_c(VCol,{staticClass:"dashboard-shortcut",class:_vm.$vuetify.theme.dark ? 'theme--dark' : 'theme--light',attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('p',[_vm._v("Atalhos")]),_c(VDivider)],1),_vm._l((_vm.items),function(item){return _c(VCol,{key:item.id,attrs:{"cols":"12","xl":"2","lg":"3","md":"4","sm":"6","xs":"6"}},[_c('div',{staticClass:"d-flex flex-column ma-1 dashboard-widget",style:(_vm.changeColor(item)),on:{"click":function($event){return _vm.changeRoute(item)}}},[_c(VIcon,{staticClass:"dashboard-widget__icon"},[_vm._v(_vm._s(item.new_icon_class))]),_c('p',{staticClass:"dashboard-widget__text"},[_vm._v(_vm._s(item.name))])],1)])})],2),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!_vm.items.length),expression:"!items.length"}],staticClass:"py-12"},[_c(VRow,[_c(VCol,{staticClass:"flex-row d-flex align-center"},[_c(VProgressCircular,{staticClass:"mx-auto",attrs:{"indeterminate":"","color":"grey"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }