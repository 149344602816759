<template>
	<v-list dense>
		<component
			v-for="access in accesses"
			:key="access.id"
			v-model="access.value"
			:access="access"
			:is-active="verifyIfIsActive(access)"
			:is="isAGrouper(access) ? 'drawer-group' : 'drawer-link'"
			@routeChanged="onRouteChanged"
		/>
	</v-list>
</template>

<script>
import { bus } from '@/main';
import DrawerLink from './DrawerLink.vue';
import DrawerGroup from './DrawerGroup.vue';
import DrawerActions from './drawer.actions';

export default {
	name: 'DrawerList',
	components: {
		DrawerLink,
		DrawerGroup,
	},
	props: {
		value: Array,
	},
	mixins: [DrawerActions],
	data() {
		return {
			accesses: this.value || [],
		};
	},
	mounted() {
		bus.$on('update_drawer', this.updateDrawer);
	},
	watch: {
		accesses() {
			this.$emit('input', this.accesses);
		},
		value() {
			if (this.accesses == this.value) {
				return;
			}

			this.accesses = this.value;
		},
	},
	methods: {
		updateDrawer() {
			this.$forceUpdate();
			console.log('updated');
		},
		isAGrouper(access) {
			return access.children
				&& access.children.length
				&& access.father_name;
		},
		onRouteChanged() {
			this.$forceUpdate();
		},
	},
};
</script>

<style>

</style>
