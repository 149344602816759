/* eslint-disable max-len */
import Vue from 'vue';
import moment from 'moment';
import vm from '@/main';
import NotificationService from '@/services/notification.service';
import HelperMethods from '@/plugins/helpers/helper.methods';
import emitter from '@/services/event_bus.service';

const state = Vue.observable({
  notifications: [],
  viewed: true,
  importing: false,
  inConciliationProcess: false,
  inBillingRemittanceProcess: false,
  inPaymentRemittanceProcess: false,
  badge: {},
  wsUser: {
    user: '',
    token: '',
    companyGroupId: null,
    data: {},
  },
});

export const getters = {
  notifications: () => state.notifications,
  viewed: () => state.viewed,
  importing: () => state.importing,
  inConciliationProcess: () => state.inConciliationProcess,
  inBillingRemittanceProcess: () => state.inBillingRemittanceProcess,
  inPaymentRemittanceProcess: () => state.inPaymentRemittanceProcess,
  inJobProcess: () => state.importing
    || state.inConciliationProcess
    || state.inBillingRemittanceProcess
    || state.inPaymentRemittanceProcess,
  loading: () => state.loading,
  wsUser: () => state.wsUser,
};

export const mutations = {
  notifications: (val) => {
    state.notifications = val;

    return state.notifications;
  },

  pushNotification: (val) => {
    state.notifications.unshift(val);

    vm.$session.set('notifications', JSON.stringify(state.notifications));

    return state.notifications;
  },

  viewed: (val) => {
    state.viewed = val;

    return state.viewed;
  },

  importing: (val) => {
    state.importing = val;

    return state.importing;
  },

  loading: (val) => {
    state.loading = val;

    return state.loading;
  },
  wsUser: (val) => {
    state.wsUser = val;

    return state.wsUser;
  },

  updateProcesses: (updated) => {
    state.importing = updated.import && updated.import.isRunning;
    state.inConciliationProcess = updated.conciliation && updated.conciliation.isRunning;
    // eslint-disable-next-line max-len
    state.inBillingRemittanceProcess = updated.billing_remittance && updated.billing_remittance.isRunning;
    state.inPaymentRemittanceProcess = updated.payment_remittance && updated.payment_remittance.isRunning;

    return updated;
  },
};

export const actions = {
  notificationFields: [
    'init_datetime',
    'finish_datetime',
    'start_manual',
    'error',
    'error_message',
    'message',
    'action',
  ],

  scope: null,

  ws: null,

  connect(user, onOpen) {
    this.ws = new WebSocket('wss://notifer.inn.cash/ws');
    // Evento que será chamado ao abrir conexão
    this.ws.onopen = () => {
      mutations.wsUser({
        user: user.user_id,
        token: user.token,
        companyGroupId: user.company_group_id,
        data: '{}',
      });

      this.sendMessage();

      // Se houver método de retorno
      if (onOpen) {
        onOpen();
      }

      this.getNotifications();
      this.verifyProcesses();
    };
    // Evento que será chamado quando houver erro na conexão
    this.ws.onerror = () => {
      console.log('Não foi possível conectar-se ao servidor');
    };
    // Evento que será chamado quando recebido dados do servidor
    this.ws.onmessage = (e) => {
      const parsed = JSON.parse(e.data);
      this.emitEvent(parsed);
      this.addMessage(parsed);
    };
    this.ws.onclose = () => {
      setTimeout(this.connect(user, onOpen), 1000);
    };
  },
  emitEvent(message) {
    if (message.data && message.data.action) {
      emitter.emit(message.data.action, message.data);
    }
  },
  // Método responsável por adicionar uma mensagem de usuário
  addMessage(data) {
    const user = getters.wsUser();
    if (data.companyGroupId == user.companyGroupId && data.user == user.user) {
      if (data.data.notification == true) {
        // eslint-disable-next-line no-undef
        showNotification(
          data.data.notificationHeader,
          data.data.notificationMessage,
          data.data.notificationType,
        );
      }

      const id = data.data.id ?? `temp_${moment().unix()}}`;

      mutations.pushNotification({
        id,
        init_datetime: data.data.init_datetime,
        finish_datetime: data.data.finish_datetime,
        start_manual: '',
        error: data.data.error,
        error_message: data.data.message,
        message: data.data.message,
        action: data.data.action,
        type: data.data.notificationType,
      });

      this.setAsUnreadNotifications();
    }
  },
  // Método responsável por enviar uma mensagem
  sendMessage() {
    // Se não houver o texto da mensagem ou o nome de usuário
    if (!this.data || !this.user) {
      // Saindo do método
      return;
    }
    // Se a conexão não estiver aberta
    if (this.ws.readyState !== this.ws.OPEN) {
      // Exibindo notificação de erro
      console.log('Problemas na conexão. Tentando reconectar...');
      // Tentando conectar novamente e caso tenha sucesso
      // envia a mensagem novamente
      this.connect(() => {
        this.sendMessage();
      });
      // Saindo do método
      return;
    }
    // Envia os dados para o servidor através do websocket
    this.ws.send(JSON.stringify(getters.wsUser()));
    // Limpando texto da mensagem
    mutations.wsUser({ ...getters.wsUser(), data: null });
  },

  setAsUnreadNotifications() {
    mutations.viewed(false);
  },

  setAsReadNotifications() {
    mutations.viewed(true);
  },

  userIsDefined() {
    if (!this.scope || !this.scope.$session) {
      return false;
    }

    const token = this.scope.$session.get('token');

    return token;
  },

  async getNotifications(upateUnread) {
    try {
      mutations.loading(true);

      const notificationService = NotificationService.build();

      const response = await notificationService.index('listAll');

      let previous = getters.notifications();

      if (previous.length == 0) {
        const stored = vm.$session.get('notifications');
        previous = stored ? previous.concat(JSON.parse(stored)) : previous;
      }

      previous = previous.map((not) => {
        const found = response.data.find((data) => data.id == not.id);
        return found ?? not;
      });
      const previousIds = previous.map((p) => p.id);
      const newNotifications = response.data.filter((n) => !previousIds.includes(n.id));
      const updated = newNotifications.concat(previous);

      mutations.notifications(updated);
      if (upateUnread) {
        this.setAsUnreadNotifications();
      }
    } catch (error) {
      HelperMethods.defaultCatchError(error);
    } finally {
      mutations.loading(false);
    }
  },

  async verifyProcesses() {
    const notificationService = NotificationService.build();

    const response = await notificationService.checkImport();

    mutations.updateProcesses(response.active);
  },

  notHaveNewButHaveUnreadNotifications(result) {
    return result == 'none' && !getters.viewed();
  },

  haveNewOrUnreadNotifications(newNotifications, oldNotifications) {
    const isEmpty = this.isEmpty(oldNotifications);

    if (isEmpty) {
      return 'none';
    }

    const result = this.verifyDifferences(newNotifications, oldNotifications);

    if (this.notHaveNewButHaveUnreadNotifications(result)) {
      return this.defineUnreadNotificationStatus(newNotifications);
    }

    this.notifyUnreadNotifications(result);

    return result;
  },

  defineUnreadNotificationStatus(newNotifications) {
    const [lastNotification] = newNotifications;

    if (lastNotification.error) {
      return 'error';
    }

    if (!lastNotification.finish_datetime) {
      return 'importing';
    }

    return 'unread';
  },

  verifyDifferences(newNotifications, oldNotifications) {
    const [lastNotification] = newNotifications;
    const [supposedLastNotification] = oldNotifications;

    const isNew = this.someFieldOfBothIsDifferent(lastNotification, supposedLastNotification);

    if (!isNew) {
      return 'none';
    }

    if (this.isImporting(lastNotification)) {
      return 'importing';
    }

    if (this.isError(lastNotification)) {
      return 'error';
    }

    return 'finished';
  },

  notifyUnreadNotifications(result) {
    if (result == 'none') {
      return;
    }

    this.setAsUnreadNotifications();

    if (result == 'importing') {
      mutations.importing(true);
    }
  },

  someFieldOfBothIsDifferent(lastNotification, supposedLastNotification) {
    const result = this.notificationFields.some(
      (field) => lastNotification[field] != supposedLastNotification[field],
    );

    return result;
  },

  isEmpty(array) {
    return array === 'undefined' || array.length == 0;
  },
  /**
   *
   * Clausúlas condicionais
   *
   */
  isError(notification) {
    return notification.error;
  },

  isImporting(notification) {
    return !notification.finish_datetime && !notification.error;
  },
};
