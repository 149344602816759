import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"page-content",class:("page-content--" + (_vm.kebabCase(_vm.title)))},[_c(VToolbar,{attrs:{"flat":""}},[_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item,index){return _c(VTab,{key:index},[_vm._v(_vm._s(item))])}),1),_c(VSpacer),_vm._t("actions")],2),_c(VDivider,{staticClass:"ma-0"}),_c(VContainer,{staticClass:"px-md-8 pt-md-2 pb-md-6",attrs:{"fluid":""}},[_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._t("tabs")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }