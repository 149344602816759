<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
				<div class="row m--align-left">
					<div class="text-separator">
						<span>De</span>
					</div>					
	                <div class="col-md-3">
							<div class="input-group date" id="dateStart">
								<input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
								<span class="input-group-addon">
									<i class="la la-calendar-check-o"></i>
								</span>
							</div>
					</div>
					<div class="text-separator">
						<span>até</span>
					</div>
	                <div class="col-md-3">
						<div class="input-group date" id="dateEnd">
							<input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
							<span class="input-group-addon">
								<i class="la la-calendar-check-o"></i>
							</span>
						</div>
					</div>					
					
	                <div class="col-md-5">
	                    <div class="m-input-icon m-input-icon--left">
	                        <input type="text" class="form-control m-input m-input--solid" placeholder="Pesquisa..." v-model="configs.filterTerm">
	                        <span class="m-input-icon__icon m-input-icon__icon--left">
	                            <span>
	                                <i class="la la-search"></i>
	                            </span>
	                        </span>
	                    </div>
	                </div>
				</div>
	        </div>
	        <div class="m-portlet__head-tools">
	            <div class="btn-group m-btn-group--air" role="group" aria-label="...">
	                <router-link :to="{ name: 'statementconciliation' }" class="m-btn btn btn-warning">Voltar</router-link>
	            </div>
	        </div>			
	    </div>
	    <div class="m-portlet__body">
			<div class="m-section">
				<div class="m-section__content">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th class="text-center">Tipo</th>
								<th>Descrição</th>
								<th class="text-center">Usuário</th>
								<th class="text-center">Data</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="file in listData" v-bind:key="file.id">
								<td class="text-center" style="vertical-align: middle;">
                                    <span v-if="file.operation_type == 0">Automática</span>
                                    <span v-else>Manual</span>
								</td>
								<td style="vertical-align: middle;">{{ file.description }}</td>
								<td class="text-center" style="vertical-align: middle;">{{ file.user_name }}</td>
								<td class="text-center" style="vertical-align: middle;">{{ file.created_at | moment("DD/MM/YY HH:mm")}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
	    </div>
	</div>
</template>

<script>
	import _ from 'lodash'

    export default {
        data () {
            return {
                listFiles: [],
                configs: {
	                filterTerm: ''
                }
            }
        },
		computed: {
			listData() {
				const filterTerm = this.configs.filterTerm.toLowerCase();

				let dataRet = this.listFiles;

				if (!_.isEmpty(filterTerm)) {
					dataRet = _.filter(dataRet, list => list.description.toLowerCase().indexOf(filterTerm) >= 0 || list.user_name.toLowerCase().indexOf(filterTerm) >= 0)
				}

				return dataRet
			}
		},
        mounted: function () {
			$("#dateStart, #dateEnd").datepicker({
				autoclose: true,
				format: 'dd/mm/yyyy',
				todayHighlight:!0,
				orientation:"bottom left",
				templates:{
					leftArrow:'<i class="la la-angle-left"></i>',
					rightArrow:'<i class="la la-angle-right"></i>'
				}
			});

			this.loadParameter();
            this.loadHistory();
        },
        methods: {
			loadParameter: function () {
				let date_initial = moment().format("DD/MM/YYYY");

				$("#dateStart > input").val(date_initial);						
				$("#dateStart > input").datepicker({
					onSelect: (d,i) => {
						this.loadHistory();
					},	 					
					autoclose: true,
					format: 'dd/mm/yyyy',
					todayHighlight:!0,
					orientation:"bottom left",
					defaultDate: date_initial,
					templates:{
						leftArrow:'<i class="la la-angle-left"></i>',
						rightArrow:'<i class="la la-angle-right"></i>'
					}
				});

				let date_end = moment().format("DD/MM/YYYY");

				$("#dateEnd > input").val(date_end);						
				$("#dateEnd > input").datepicker({			
					onSelect: (d,i) => {
						this.loadHistory();
					},							
					autoclose: true,
					format: 'dd/mm/yyyy',
					todayHighlight:!0,
					orientation:"bottom left",
					defaultDate: date_end,
					templates:{
						leftArrow:'<i class="la la-angle-left"></i>',
						rightArrow:'<i class="la la-angle-right"></i>'
					}
				});

				$("#dateStart > input").change(() => {
					this.loadHistory();
				});

				$("#dateEnd > input").change(() => {
					this.loadHistory();
				});
			},
            loadHistory: function () {
				blockPage('Carregando os dados...');
				this.listFiles = [];
				
				let dateStart = $("#dateStart > input").val();
				dateStart = dateStart || moment().format("DD/MM/YYYY");

				let dateEnd = $("#dateEnd > input").val();
				dateEnd = dateEnd || moment().format("DD/MM/YYYY");

				$.ajax({
					method: "GET",
					url: this.$store.state.API_BASE + 'conciliation/history/listAll',
					headers: {
						'token': this.$session.get('token')
					},
					data: {
						dateStart: dateStart,
						dateEnd: dateEnd
					}
				}).done((result) => {
					if (result.error) {
						showNotification('Atenção', result.message, 'danger');
					} else {
						if (result.total_records > 0){
							this.listFiles = result.data;
						} else {
							showNotification('Atenção', 'Sem dados para listar', 'warning');
						}
					}

					unblockPage();
				}).fail((error) => {
					showNotification('Atenção', 'Opss!!! Não foi possível carregar os dados', 'danger');
					unblockPage();
				});				
            }
        }
    }
</script>

<style scoped>
	.text-separator{
		margin-top: 8px;
	}
</style>