<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Configurações de Conciliação Bancária"/>
			<v-card class="elevation-0">
				<v-card-title>
					<v-col cols="12" lg="4" md="4" sm="12" xs="12">
						<v-select 
							v-model="configs.filterStatus"
							filled
							dense
							hide-details
							:items="filterStatusItems"/>
					</v-col>
					<v-col cols="12" lg="6" md="5" sm="12" xs="12">
							<v-text-field
							filled
							dense
							hide-details
							prepend-inner-icon="mdi-magnify"
							placeholder="Pesquisar"
							v-model="configs.filterTerm"
						/>
					</v-col>
					<v-col cols="12" lg="2" md="3" sm="12" xs="12">
						<list-add-button @click="add"/>
					</v-col>
				</v-card-title>
				<v-card-text class="pa-6 pt-0">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Empresa</th>
								<th>Banco</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="conciliationconfig in listData" v-bind:key="conciliationconfig.id">
								<td style="vertical-align: middle;">
										<v-chip
										v-if="conciliationconfig.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td style="vertical-align: middle;">{{ conciliationconfig.name || 'Todas' }}</td>
								<td style="vertical-align: middle;">{{ conciliationconfig.code_bank }}</td>
								<td class="m--align-right">
									<table-edit-button @click="edit(conciliationconfig)"/>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash';
	import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
	import ListAddButton from '@/components/buttons/ListAddButton.vue';
	import tableEditButton from '@/components/buttons/tableEditButton.vue';

	export default {
		components: {
			Breadcrumbs,
			ListAddButton,
			tableEditButton,
		},
		data () {
			return {
				conciliationconfigs: [],
				configs: {
					filterTerm: '',
					filterStatus: ''
				},
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				]
			}
		},

		computed: {
			listData() {
				let dataRet = this.conciliationconfigs;

				if (this.configs.filterStatus) {
					dataRet = _.filter(dataRet, data => data.status == this.configs.filterStatus)
				}
				if(this.conciliationconfigs.name != null){
					if (this.configs.filterTerm) {
						dataRet = _.filter(dataRet, list => list.name.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0)
					}
				}
				if (this.configs.filterTerm) {
						dataRet = _.filter(dataRet, list => list.code_bank.toString().toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0)
				}
				

				return dataRet
			}
		},

		mounted: function () {
			this.loadConfigs();
		},

		methods: {
			loadConfigs: function () {
				blockPage('Carregando os dados...');

				this.$http.get(`${this.$store.state.API_BASE}conciliation/config/listAll`, {headers: {'token': this.$session.get('token')}}).then(
					(result) => {
						this.conciliationconfigs = result.body.data
						unblockPage();
					},
					(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				)
			},
			add() {
				this.$router.push('/conciliationconfig/new');
			},
			edit(conciliationconfig) {
				const params = {
					conciliation_config_id: conciliationconfig.id,
				};
				this.$router.push({name: 'newconciliationconfig',params});
			},
		}
	}
</script>
