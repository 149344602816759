<template>
	<div class="m-portlet m-portlet--mobile">
		<div class="m-portlet__head">
			<div class="m-portlet__head-caption">
				<div class="m-portlet__head-title">
				</div>
			</div>
			<div class="m-portlet__head-tools">
				<router-link :to="{ name: 'paymentmethod' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
				<button id="btn-save" v-on:click="save" type="button" class="m-btn btn btn-success">Salvar</button>
			</div>
		</div>
		<div class="m-portlet__body">
			<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30" id="frmPaymentMethod">
				<div class="row">
					<div class="col-md-4">
						<div class="form-group">
							<label>Código ERP</label>
							<input type="text" class="form-control" field-name="Código ERP" id="code" required>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label>Descrição</label>
							<input type="text" class="form-control" field-name="Descrição" id="description" required>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label>Tipo</label>
							<select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;">
								<option :value="undefined">Selecione...</option>
								<option value="1">Boleto</option>
								<option value="2">TED/Transf.</option>
								<option value="3">DOC</option>
								<option value="4">Dinheiro</option>
								<option value="5">TED/Transf. Outra Titularidade</option>
								<option value="6">DOC Outra Titularidade</option>
								<option value="7">Pix</option>
								<option value="8">GPS</option>
								<option value="9">DARF</option>
								<option value="0">Outros</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				paymentmethod_id: this.$route.params.id,
			}
		},
		mounted: function () {
			numeral.language('pt-br');

			if(this.paymentmethod_id != 'new'){
				this.loadData();
			}
		},
		methods: {
			loadData: function () {
				blockPage('Carregando os dados...');

				$.ajax({
					method: "GET",
					url: this.$store.state.API_BASE + 'paymentmethod/listOne/' + this.paymentmethod_id,
					headers: {
						'token': this.$session.get('token')
					},
				}).done(result => {
					if (result && result.data && result.data[0]){
						let value = result.data[0];

						$('#code').val(value.code);
						$('#description').val(value.description);
						$('#type').val(value.type);
					}

					unblockPage();
				}).fail(error => {
					console.log(error.responseText);
					unblockPage();
				});
			},
			save: function () {
				let withError;
				let arrayData = [], i = 0;
				
				$.each($('input, select', '#frmPaymentMethod'),function(){
					if($.trim(this.value) == ''){
						withError = true;

						$(".form-group:has(#"+this.id+")").addClass('has-danger');
						$("#"+this.id).addClass('input-error');
						$("#"+this.id).css('border-color','red');
						showNotification('Atenção', 'O campo <strong>'+ $("#"+this.id).attr('field-name')+'</strong> não foi informado!', 'warning');

						$("#"+this.id).keyup(function(){
							if ($("#"+this.id).hasClass('input-error')) {
								$("#"+this.id).removeClass('input-error');
								$("#"+this.id).css('border-color', '#ebedf2');
								$(".form-group:has(#"+this.id+")").removeClass('has-danger');
							}
						});						
					}else {
						arrayData[i] = {};

						arrayData[i][this.id] = this.value;
					}
					
					i++;
				});

				if(withError) return false;

				if(this.paymentmethod_id != 'new') arrayData.push({id: this.paymentmethod_id});	

				$.ajax({
					method: "POST",
					url: this.$store.state.API_BASE + 'paymentmethod/' + (this.paymentmethod_id == 'new' ? 'new' : 'edit'),
					headers: {
						'token': this.$session.get('token')
					},
					data: {
						arrayData: JSON.stringify(arrayData),
					}
				}).done((result) => {
					if(!result.error){
						showNotification('Sucesso', 'Processo concluído com sucesso!','success');

						this.$router.replace({name: 'paymentmethod'});
					}else{
						showNotification('Atenção',result.message, 'danger');
						return false;
					}
				}).fail((error) => {
					console.log(error.responseText);
				});
			}
		}
	}
</script>
