<template>
  <v-sheet class="page-content" :class="`page-content--${kebabCase(title)}`">
    <v-toolbar flat>
      <v-tabs v-model="tab">
        <v-tab v-for="(item, index) in tabs" :key="index">{{ item }}</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
    </v-toolbar>
    <v-divider class="ma-0"></v-divider>
    <v-container fluid class="px-md-8 pt-md-2 pb-md-6">
      <v-tabs-items v-model="tab">
        <slot name="tabs"></slot>
      </v-tabs-items>
    </v-container>
  </v-sheet>
</template>

<script>
import { kebabCase } from '@/utils';

export default {
  name: 'PageTabsContent',
  props: {
    title: String,
    tabs: Array,
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    kebabCase,
  },
};
</script>

<style lang="sass"></style>
