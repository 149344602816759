export default {
	$vue: null,

	/**
	 * Usado na construcao dos erros no sistema para todas as telas.
	 * trata os erros recebidos notificando-os com uma snackbar
	 * @param {Throwable} error
	 * @param {String} defaultMessage
	 *
	 * @return {void}
	 */
	defaultCatchError(error, defaultMessage = 'Ocorreu um erro desconhecido') {
		this.logErrorInDevelopmentMode(error);

		const { message, status } = this.getExceptionResponseMessage(error) || defaultMessage;

		if (status == 401) {
			console.log(status);
		}

		// eslint-disable-next-line
		showNotification('Erro', message, 'danger');
	},

	/**
	 * @param {Throwable} error
	 * @return {String}
	 */
	getExceptionResponseMessage(error) {
		try {
			let message = '';
			if (error.message) {
				message = error.message;
			} else {
				message = error.response.data;
			}
			const { status } = error.response || 500;

			if (!message) {
				return { message: null, status: 500 };
			}

			return { message, status };
		} catch (e) {
			return null;
		}
	},

	/**
	 * Verifica se o sistema está em modo de desenvolvimento.
	 * se estiver, notifica o erro
	 * @param {Throwable} error
	 * @return {void}
	 */
	logErrorInDevelopmentMode(error) {
		if (process.env.NODE_ENV !== 'production') {
			console.error(error);
		}
	},
};
