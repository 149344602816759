<template>
	<div>
		<div id="params" class="m-portlet m-portlet--mobile">
		  <div class="m-portlet__head">
		    <div class="m-portlet__head-caption">
		      <div class="m-portlet__head-title">
		        <h3 id="teste" class="m-portlet__head-text">
		          Parâmetros
		        </h3>
		    </div>
		  </div>
		  <div class="m-portlet__head-tools">
	     	<button 
					id="btn-generate" 
					type="button" 
					v-on:click="loadAdvancePayments(false)" 
					class="btn m-btn--air btn-success"
					>Gerar Adiantamentos
				</button>
				<v-btn icon depressed class="ml-4" @click="toggleParams()">
					<v-icon 
						x-large class="text--disabled chevron" 
						:class="!paramsVisibility ? 'toggle-chevron' : ''" 
					>mdi-chevron-up
					</v-icon>
				</v-btn>
		  </div>
		</div>
		<div class="m-portlet__body" v-show="paramsVisibility">
		<!--begin: Search Form -->
			<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
				<div class="row align-items-center">
					<div class="col-md-3">
						<div class="form-group">
							<label>Visualizar</label>
							<select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;">
								<option value="PR">Em aberto</option>
								<option value="PA">Já realizados</option>
							</select>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group">
							<label>Data inicial</label>
							<div class="input-group date" id="dateStart">
								<input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
								<span class="input-group-addon">
									<i class="la la-calendar-check-o"></i>
								</span>
							</div>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group">
							<label>Data final</label>
							<div class="input-group date" id="dateEnd">
								<input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
								<span class="input-group-addon">
									<i class="la la-calendar-check-o"></i>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
	<template v-if="showAdvancePayments" >
		<div id="advancepayments" class="m-portlet m-portlet--mobile">
			<div class="m-portlet__head">
				<div class="m-portlet__head-caption">
					<div class="m-portlet__head-title">
						<h3 class="m-portlet__head-text">Adiantamentos</h3>
					</div>
				</div>
				<div class="m-portlet__head-tools">
				</div>
			</div>
			<div class="m-portlet__body" style="font-size:11px;">
				<div class="row levels level-main header-advancepayments" style="padding-top: 6px;padding-bottom: 6px;padding-left: 4px;background-color: #498cc5;color: white;font-weight: bold;">
					<div class="col-sm-2 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Filial</strong></div>
					<div class="col-sm-1 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Data pagamento</strong></div>
					<div class="col-sm-1 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Data emissão</strong></div>
					<div class="col-sm-2 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Título</strong></div>
					<div class="col-sm-1 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Valor original</strong></div>
					<div class="col-sm-1 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Valor em R$</strong></div>
					<div class="col-sm-4 description" style="padding-right:0;padding-left:0;text-align: center;"><strong>Fornecedor</strong></div>
				</div>
				<div v-for="(dayAdvancePayment, index1) in daysAdvancePayments" :data-date="dayAdvancePayment.date" :key="index1">
					<draggable v-model="dayAdvancePayment.data" :options="{draggable: '.leve-order-' + index1}" @update="updateOrder(index1)">
						<div v-for="(AdvancePayment, index2) in dayAdvancePayment.data" v-bind:class="'leve-order-' + index1" :key="index2">
							<div v-bind:class="[(index2 % 2) == 0 ? '' : 'zebra']" class="row levels level-one" :data-level-day="index1" :data-level-item="index2" :data-key-id="AdvancePayment.key_id" style="padding-top: 6px;padding-bottom: 6px;color: black;">
								<div class="col-sm-2" style="padding-right:2px;padding-left:0;">
									<div class="row" >
										<div class="col-sm-5 advance-payments-options" style="padding-left: 20px;padding-right: 0px;">
											<i class="fa fa-chevron-right item-collapse" data-toggle="collapse" :data-target="'#collapse_' + AdvancePayment.key_id" style="font-size: 11px;padding-top: 3px;float: left;cursor: pointer;"></i>
											<div v-if="showBarsOptions" class="dropdown">
												<i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown" style="float: left;cursor: pointer;margin-left: 5px;display:none;"></i>
												<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

													<a v-if="validateUserAccess('change_date')" class="dropdown-item" href="" style="padding: .3rem 1.2rem" v-on:click="showDate(index1, index2)" data-toggle="modal">Alterar Data</a>
													<a v-if="validateUserAccess('include_comment')" class="dropdown-item" href="" style="padding: .3rem 1.2rem" v-on:click="showComment(index1, index2)" data-toggle="modal">Comentário</a>
												</div>
											</div>
											<i class="fa fa-comment-o" v-if="AdvancePayment.have_comment == 1" v-on:click="showComment(index1, index2)" style="float: left;cursor: pointer;margin-left: 5px;"></i>
										</div>
										<div class="col-sm-7" style="text-align: left;">
											<span>{{ AdvancePayment.filial }}</span>
										</div>
									</div>
								</div>
								<div class="col-sm-1" style="padding-right:2px;padding-left:0;text-align: center;"><span>{{ AdvancePayment.date_payment }}</span></div>
								<div class="col-sm-1" style="padding-right:2px;padding-left:0;text-align: center;"><span>{{ AdvancePayment.date_issue }}</span></div>
								<div class="col-sm-2" style="padding-right:2px;padding-left:0;text-align: center;"><span>{{ AdvancePayment.title_description }}</span></div>
								<div class="col-sm-1" style="padding-right:2px;padding-left:0;padding-right:4px;text-align: right;">
									<span class="coin-description">{{AdvancePayment.coin_description}}</span>
									<span class="value-description">{{AdvancePayment.value_original | formatNumber}}</span>
								</div>
								<div class="col-sm-1" style="padding-right:2px;padding-left:0;padding-right:4px;text-align: right;">
									<span class="coin-description">{{AdvancePayment.coin_converted_description}}</span>
									<span class="value-description">{{AdvancePayment.value_converted | formatNumber}}</span>
								</div>
								<div class="col-sm-4" style="padding-right:2px;padding-left:0;"><span style="float: left;padding-left: 3px;text-align: left;">{{ AdvancePayment.supplier_description }}</span></div>
							</div>
							<div :id="'collapse_' + AdvancePayment.key_id" class="collapse">
								<div class="row" v-if="AdvancePayment.products.total_records == 0" style="padding-top: 6px;padding-bottom: 5px;">
									<div class="col-sm-12" style="padding-right:2;padding-left:0;text-align: center;">(Sem produtos)</div>
								</div>
								<div class="row" v-for="(product, index3) in AdvancePayment.products.data" v-bind:class="[(index3 % 2) == 0 ? '' : 'zebra']" :key="index3">
									<div class="col-sm-2" style="padding-right:2;padding-left:0;text-align: left;font-weight: bold;">&nbsp;</div>
									<div class="col-sm-1" style="padding-right:2;padding-left:0;text-align: right;">{{product.item}}</div>
									<div class="col-sm-1" style="padding-right:2;padding-left:0;text-align: right;">{{product.product}}</div>
									<div class="col-sm-8" style="padding-right:2;padding-left:0;text-align: left;">{{product.product_description}}</div>
								</div>

								<div class="row level-empty" style="padding-top: 6px;padding-bottom: 5px;">
									<div class="col-sm-12" style="padding-right:2;padding-left:0;text-align: center;">&nbsp;</div>
								</div>
							</div>
						</div>
					</draggable>
					<div class="row level-total" style="padding-top: 6px;padding-bottom: 5px;color: black;background-color: #9bbfd8;font-weight: bold;">
						<div class="col-sm-5" style="padding-right:2;padding-left:4px;text-align: left;"><strong>Total do dia:</strong></div>
						<div class="col-sm-2" style="padding-right:2;padding-left:4px;padding-right:4px;text-align: right;">
							<span class="coin-description">R$</span>
							<span class="value-description">{{dayAdvancePayment.total_value_converted | formatNumber}}</span>
						</div>
						<div class="col-sm-5" style="padding-right:2;padding-left:0;text-align: center;">&nbsp;</div>
					</div>

					<div class="row level-empty" style="padding-top: 6px;padding-bottom: 5px;">
						<div class="col-sm-12" style="padding-right:2;padding-left:0;text-align: center;">&nbsp;</div>
					</div>
				</div>	
				<div class="row level-total-grant" style="padding-top: 6px;padding-bottom: 5px;background-color: #498cc5;color:white;font-weight: bold;">
					<div class="col-sm-5" style="padding-right:2;padding-left:4px;text-align: left;"><strong>Total geral:</strong></div>
					<div class="col-sm-2" style="padding-right:2;padding-left:4px0;padding-right:4px;text-align: right;">
						<span class="coin-description">R$</span>
						<span class="value-description">{{totalGrant | formatNumber}}</span>
					</div>
					<div class="col-sm-5" style="padding-right:2;padding-left:4px;text-align: center;">&nbsp;</div>
				</div>
			</div>
		</div>
	</template>
	<!--begin::Modal-->
	<div class="modal fade" id="m_viewInformation" tabindex="-1" role="dialog" aria-labelledby="m_viewInformation" aria-hidden="true">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{updateData.title}}</h5>
				</div>
				<form class="m-form m-form--fit m-form--label-align-right">
					<div class="modal-body">
						<div class="row" v-bind:class="updateData.type == 'updateComment' ? '' : 'hide_block'">
							<div class="col-sm-2">&nbsp;</div>
							<div class="col-sm-8">
								<div class="form-group">
									<label>Comentário:</label>
									<div class="input-group">
										<textarea class="form-control m-input" rows="5" style="margin-top: 0px; margin-bottom: 0px; height: 200px;" v-model="updateData.updateComment.comment"></textarea>
									</div>
								</div>
							</div>
							<div class="col-sm-2">&nbsp;</div>
						</div>
						<div class="row" v-bind:class="[updateData.type == 'updateDate' ? '' : 'hide_block']">
							<div class="col-sm-4">&nbsp;</div>
							<div class="col-sm-4">
								<div class="form-group">
									<label>Selecione a nova data</label>
									<div class="input-group date" id="dateToUpdate" >
										<input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
										<span class="input-group-addon"><i class="la la-calendar-check-o"></i></span>
									</div>
								</div>
							</div>
							<div class="col-sm-4">&nbsp;</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" v-if="showButtonModalUpdate" class="btn m-btn--air btn-success" id="btnModalUpdate" v-on:click="dataToUpdate()">Alterar</button>
						<button type="button" class="btn m-btn--air btn-primary" id="btnModalClose" data-dismiss="modal">Fechar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!--end::Modal-->
</div>
</template>

<script>
	import Vue from 'vue';
	import draggable from 'vuedraggable'

	Vue.use(draggable);
	
	Vue.filter("formatNumber", function (value) {
		return numeral(value).format("0,0.00");
	});

	export default {
    data () {
    return {
			paramsVisibility: true,
			showAdvancePayments: false,
			daysAdvancePayments: [],
			updateData: {
				type : "",
				title: "",
				AdvancePayment : {},
				updateComment : {
				comment : "",
			},
			updateDate : {
				date: new Date()
			},
			},
			totalGrant : 0,
			}
    },

		components: {
      draggable,
    },

		computed: {
			showButtonModalUpdate() {
				const self = this;

				if (self.updateData){
					if (self.updateData.type == 'updateComment'){
						return self.validateUserAccess('include_comment');
					}
				}

				return true
			},

			showBarsOptions() {
				const self = this;
				var validateIncludeComment = self.validateUserAccess('include_comment');
				var validateChangeDate = self.validateUserAccess('change_date');
				var showBars = (validateIncludeComment || validateChangeDate);				

				return showBars
			}
			
		},

        mounted: function () {
        	var self = this;

            self.loadMethod();
			self.loadParameter();
        },

        methods: {
			toggleParams() {
        		this.paramsVisibility = !this.paramsVisibility;
   			 },
			hiddenParams() {
				this.paramsVisibility = false;
			},

        	loadData: function () {
        		var self = this;

        	},

			dataToUpdate: function(){
        		var self = this;
				
				switch(self.updateData.type) {
					case 'updateDate':
						self.updateDate();
						break;
					case 'updateComment':
						self.updateComment();
						break;
					default:
				}
			},

			showComment: function(levelDay, levelItem){
        		var self = this;
				var item = self.daysAdvancePayments[levelDay].data[levelItem];

				self.updateData.type = "updateComment";
				self.updateData.title = "Comentário";
				self.updateData.AdvancePayment = item;
				
				$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE + 'internaltable/getcomment',
						headers: {
							'token': self.$session.get('token')
						},
						data: {
							_identifyValue: item.identify_value,
							_date: item.date,
							_keyId: item.key_id,
						}
					}).done(function(result) {
						self.updateData.updateComment.comment = result.data.comment;

						$('#m_viewInformation').modal('show');
					}).fail(function(error){

						showNotification('Atenção','Oops! Ocorreu um erro ao consultar o comentário.', 'danger');
						console.log(error);
					});
			},

			internalTableSaveData : function (identifyValue, field, date, keyId, value, done, fail){
				var self = this;
				var data = {
					_identifyValue: identifyValue,
					_field: field,
					_date: date,
					_keyId: keyId,
					_data: value
				};
					
				$.ajax({
					method: "POST",
					url: self.$store.state.API_BASE + 'internaltable/savedata',
					headers: {
						'token': self.$session.get('token')
					},
					data: data
				}).done(function(result) {
					done(result);
				}).fail(function(error){
					fail(error);
				});
			},

			updateComment: function(){
        		var self = this;
				var item = self.updateData.AdvancePayment;

				self.internalTableSaveData(item.identify_value, 
											"comentario", 
											item.date,
											item.key_id, 
											self.updateData.updateComment.comment,
											function(result){
												item.have_comment = 1;

												$('#m_viewInformation').modal('hide');			
												
												showNotification('Sucesso!', 'Comentário salvo com sucesso!', 'success');
											},
											function(erro){
												showNotification('Atenção','Oops! Ocorreu um erro ao salvar o comentário.', 'danger');
												console.log(error);
											});
			},

			showDate: function(levelDay, levelItem){
        		var self = this;
				var item = self.daysAdvancePayments[levelDay].data[levelItem];

				self.updateData.type = "updateDate";
				self.updateData.title = "Alteração de data";
				self.updateData.AdvancePayment = item;

				self.updateData.updateDate.date = item.date;

				$('#dateToUpdate > input').val(item.date_payment);
				$("#dateToUpdate > input").datepicker({
							autoclose: true,
							format: 'dd/mm/yyyy',
							todayHighlight:!0,
							orientation:"bottom left",
							defaultDate: item.date_payment,
							templates:{
								leftArrow:'<i class="la la-angle-left"></i>',
								rightArrow:'<i class="la la-angle-right"></i>'
							}
				});
				
				$('#m_viewInformation').modal('show');
			},

			updateDate: function(){
        var self = this;
				var item = self.updateData.AdvancePayment;
				var date = $('#dateToUpdate > input').val();
				var btn = $("#btnModalUpdate");
    		var btnClose = $("#btnModalClose");
				var dateInternal = date.split("/")[2]+'-'+date.split("/")[1]+'-'+date.split("/")[0];
				var dateProtheus = date.split("/")[2]+date.split("/")[1]+date.split("/")[0];

				var url = self.$session.get('host_address_api_client') + self.$store.state.API_CLIENT_PREFIX + 'data?';
				var use_ncc = self.$session.get('use_ncc');
				var url_internal = self.$store.state.API_BASE + 'internaltable/updateImportTable';
				var company = item.table_company_code;
				var fieldToUpdate = '';
				var fields = '';
				var where = '';
				var erp_table = 'SE2';
				var table_name = 'table_name=' + erp_table;

				if (date == '') {
					showNotification('Atenção','A data não foi informada!', 'warning');
					return false;
				}

				btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
				btnClose.attr('disabled', true);

				fieldToUpdate = 'E2_ZDTFLUX';

				use_ncc = (use_ncc === 1 && item.type === 'NCC');

				fields = '&fields=' + (use_ncc ? "E1_ZDTFLUX" : "E2_ZDTFLUX") + '%3D'+dateProtheus;
				where = '&where=' + (use_ncc ? "E1_FILIAL" : "E2_FILIAL") + '%3D'+item.subsidiary;
				where = where + ',' + (use_ncc ? "E1_CLIENTE" : "E2_FORNECE") + '%3D'+item.supplier;
				where = where + ',' + (use_ncc ? "E1_LOJA" : "E2_LOJA") + '%3D'+item.shop;
				where = where + ',' + (use_ncc ? "E1_NUM" : "E2_NUM") + '%3D'+item.number;
				if (item.parcel != "") {
					where = where + ',' + (use_ncc ? "E1_PARCELA" : "E2_PARCELA") + '%3D'+item.parcel;
				}
				where = where + ',' + (use_ncc ? "E1_PREFIXO" : "E2_PREFIXO") + '%3D'+item.prefix;

				if (use_ncc) {
					table_name = 'table_name=SE1';
				}

				url = url + table_name + fields + where + '&company='+company;

				$.ajax({
					type: 'POST',
					url: self.$store.state.API_BASE + 'job/callErp',
					data: {
						method: 'PUT',
						url: JSON.stringify(url)
					},
					success: function (result) {
						if (result.error) {
							btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
							btnClose.attr('disabled', false);

							$('#m_viewInformation').modal('hide');

							showNotification('Atenção',error.message, 'danger');
							return false;
						}

						$.ajax({
							type: 'POST',
							url: url_internal,
							headers: {
								'token': self.$session.get('token')
							},
							data: {
								identify_values: erp_table,
								id: item.id,
								field: fieldToUpdate,
								value: dateInternal
							},
							success: function (result) {
								self.internalTableSaveData(
														item.identify_value, 
														"date", 
														item.date,
														item.key_id,
														dateInternal,
									function(result){
										btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
										btnClose.attr('disabled', false);
										$('#m_viewInformation').modal('hide');

										self.internalTableSaveData(
																item.identify_value, 
																"ordem_adiantamento", 
																dateInternal,
																item.key_id,
																9999,
											function(result){},
											function(erro){}
										)

										showNotification('Sucesso!', 'Data alterada com sucesso!', 'success');
										self.loadAdvancePayments(true);
									},
									function(erro){
										btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
										btnClose.attr('disabled', false);

										showNotification('Atenção','Oops! Ocorreu um erro ao fazer a alteração dos dados!', 'danger');
										return false;
									}
								);
							},
							error: function (error) {
								btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
								btnClose.attr('disabled', false);
								
								showNotification('Atenção','Oops! Ocorreu um erro ao fazer a alteração dos dados!', 'danger');
								return false;
							}
						});
					},
					error: function (error) {
						btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						btnClose.attr('disabled', false);
						
						showNotification('Atenção','Oops! Ocorreu um erro ao fazer a alteração dos dados!', 'danger');
						return false;
					}
				});
			},

        	loadAdvancePayments: function (hideBlock) {
        		var self = this;
				var dateStart = $("#dateStart > input").val();
				var dateEnd = $("#dateEnd > input").val();
				var type = $("#type").val();

				if ($.trim(dateStart) == '') {
					showNotification('Atenção','A data inicial deve ser informada', 'warning');
					return false;
				}

				if ($.trim(dateEnd) == '') {
					showNotification('Atenção','A data final deve ser informada', 'warning');
					return false;
				}

				self.showAdvancePayments = hideBlock;

				blockPage('Carregando os dados...');

				$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE + 'advancepayments/loadadvancepayments',
						headers: {
							'token': self.$session.get('token')
						},
						data: {
							type: type,
							dateStart: dateStart,
							dateEnd: dateEnd,
						}
					}).done(function(result) {
						if (result.error) {
							showNotification('Atenção', result.message, 'danger');
						} else {
							if (result.total_records > 0){
								self.daysAdvancePayments = result.data;
								self.totalGrant = result.total_grant;
								
								self.showAdvancePayments = true;
								if (self.$session.get('user_config_autotoggle_paramns')) {
									self.hiddenParams();
								}
							} else {
								showNotification('Atenção', 'Sem dados para listar', 'warning');
							}
						}

						unblockPage();
					}).fail(function(error){
						showNotification('Atenção', 'Opss!!! Não foi possível carregar os dados', 'danger');
						unblockPage();
					});
        	},
			
			updateOrder: function(itemIndex){
        		var self = this;
				var itemOder = self.daysAdvancePayments[itemIndex];
				var itensUpdate = [];

				blockPage('Gravando ordem da prioridade...');

				itemOder.data.forEach(function(item, index){
					itensUpdate.push({
						identifyValue: item.identify_value,
						date: item.date,
						keyId: item.key_id,
						order: index + 1,
					});
				});

				$.ajax({
					method: "POST",
					url: self.$store.state.API_BASE + 'internaltable/savearraydata',
					headers: {
						'token': self.$session.get('token')
					},
					data: {
						_field: 'ordem_adiantamento', 
						_arrayData: JSON.stringify(itensUpdate)
					}
				}).done(function(result) {

					showNotification('Sucesso!', 'Odem da prioridade alterada com sucesso!', 'success');
					unblockPage();										
				}).fail(function(error){

					showNotification('Atenção', 'Opss!!! Não foi possível salvar a ordem de prioridade.', 'danger');
					unblockPage();
				});
			},
			
			loadParameter: function () {
        		var self = this;

                this.$http.get(this.$store.state.API_BASE + 'fieldparameter/getDataAdvancePayments', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {						
						$("#dateStart > input").val(result.body.data.date_initial);						
						$("#dateStart > input").datepicker({
							autoclose: true,
							format: 'dd/mm/yyyy',
							todayHighlight:!0,
							orientation:"bottom left",
							defaultDate: result.body.data.date_initial,
							templates:{
								leftArrow:'<i class="la la-angle-left"></i>',
								rightArrow:'<i class="la la-angle-right"></i>'
							}
						});

						$("#dateEnd > input").val(result.body.data.date_finish);
						$("#dateEnd > input").datepicker({
							autoclose: true,
							format: 'dd/mm/yyyy',
							todayHighlight:!0,
							orientation:"bottom left",
							defaultDate: result.body.data.date_finish,
							templates:{
								leftArrow:'<i class="la la-angle-left"></i>',
								rightArrow:'<i class="la la-angle-right"></i>'
							}
						});
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                ).bind(this);
            },

            loadMethod: function () {
            	var self = this;

				$("#dateStart, #dateEnd, #dateToUpdate").datepicker({
					autoclose: true,
					format: 'dd/mm/yyyy',
					todayHighlight:!0,
					orientation:"bottom left",
					templates:{
						leftArrow:'<i class="la la-angle-left"></i>',
						rightArrow:'<i class="la la-angle-right"></i>'
					}
				});

				$(window).scroll(function(){
					var header = $('.header-advancepayments');
					var scroll = $(window).scrollTop();

					if (scroll >= 500){
						var width_reference = $('.level-main')[0].clientWidth;

						header.addClass('fix-header-inncash');
						header.css({width: width_reference+'px'});
					} else{
						header.removeClass('fix-header-inncash');
					}
				});

				$(document).on('mouseover', '.advance-payments-options', function(){
					$(this).find('i.fa.fa-bars').css('display', 'block');
				});

				$(document).on('mouseout', '.advance-payments-options', function(){
					$(this).find('i.fa.fa-bars').css('display', 'none');
				});

            },

			validateUserAccess: function(option){
				var typeAccess = 'ad';
				var userAccess = '';

				userAccess = 'user_access-' + option + '_' + typeAccess;

				return (this.$session.get(userAccess) != '');
			}
        }
    }
</script>

<style scoped>
	.zebra {
		background: #f0f4f7;
	}

	.hide_block {
		display: none;
	}
</style>