<template>
	<page-container>
		<v-row>
			<v-col>
				<breadcrumbs page-title="Configurações de API bancária"/>
				<v-card class="elevation-0">
					<v-card-title>
						<v-spacer/>
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<list-add-button @click="add"/>
						</v-col>
					</v-card-title>
					<v-card-text class="pa-6 pt-0">
						<list-table :items="items"/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</page-container>
</template>

<script>
import ListAddButton from '@/components/buttons/ListAddButton.vue';
import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
import PageContainer from '@/components/defaults/PageContainer.vue';

// eslint-disable-next-line
import ApiBankingService from '@/services/apibanking.service';

import ListTable from './ListTable.vue';

export default {
	components: {
		PageContainer,
		ListTable,
		ListAddButton,
		Breadcrumbs,
	},
	name: 'ApiBankinConfigList',
	data() {
		return {
			items: [
			],
			loading: false,
		};
	},
	created() {
		this.loadApiBankingConfigs();
	},
	methods: {
		async loadApiBankingConfigs() {
			try {
				this.loading = true;

				const apiBankingService = new ApiBankingService();

				const result = await apiBankingService.configurated().index();

				this.items = result.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		add() {
			this.$router.push({ name: 'ApiBankingConfigSave', params: { service: 'new' } });
		},
	},
};
</script>

<style>

</style>
