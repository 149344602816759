<template>
	<div class="m-portlet m-portlet--mobile">
		<div class="m-portlet__head">
			<div class="m-portlet__head-caption">
				<div class="m-portlet__head-title">
				</div>
			</div>
			<div class="m-portlet__head-tools">
				<router-link :to="{ name: 'ddaconfig' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
				<button id="btn-save" v-on:click="save" type="button" class="m-btn btn btn-success">Salvar</button>
			</div>
		</div>
		<div class="m-portlet__body">
			<!--begin: Search Form -->
			<div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
				<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link active" data-toggle="tab" href="#dadosprincipais" role="tab" aria-expanded="true">
							Dados Principais
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#conciliacaoautomatica" role="tab" aria-expanded="true">
							Conciliação Automática
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#conciliacaomanual" role="tab" aria-expanded="true">
							Conciliação Manual
						</a>
					</li>
				</ul>
				<div class="tab-content"  id="frmUserGroup">
					<div class="tab-pane active" id="dadosprincipais" role="tabpanel">
						<div class="row">
							<div class="col-md-9">
								<div class="form-group">
									<label>Empresa</label>
									<select class="form-control select2" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;" >
										<option value="">Selecione...</option>
										<option v-for="company in companys" :value="company.id" v-bind:key="company.id">{{ company.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Status</label>
									<select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;">
										<option value="0">Inativo</option>
										<option value="1" selected>Ativo</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div :class="['form-group']">
									<label>Banco</label>
									<select class="form-control select2 hide"
											id="code_bank" name="code_bank" style="width: 100%;" field-name="Banco">
										<option :value="undefined">Selecione...</option>
										<option v-for="bank in banks" :value="bank.code" v-bind:key="bank.code">{{ bank.code}} - {{ bank.name}}
										</option>
									</select>
								</div>
							</div>		
							<div class="col-md-6">
								<div :class="['form-group']">
									<label>Retorno CNAB</label>
									<select class="form-control hide select2" id="id_cnab_remi_config" name="id_cnab_remi_config" style="width: 100%;" field-name="Retorno CNAB">
										<option :value="undefined">Selecione...</option>
										<option v-for="cnabRemittance in cnabRemittancesConfig" :value="cnabRemittance.id" v-bind:key="cnabRemittance.id">
											{{ cnabRemittance.description }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>CNPJ</label>
									<select class="form-control select2 hide" field-name="Considera CNPJ" id="root_cnpj" name="root_cnpj" style="width: 100%;">
										<option value="2">Todo CNPJ</option>
										<option value="1">Somente Raiz</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="conciliacaoautomatica" role="tabpanel">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Valor</label>
									<input type="text" class="form-control" field-name="Tolerância de Valor" id="value_tolerance" name="value_tolerance" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Venc.</label>
									<input type="text" class="form-control" v-model.lazy="ddaConfig.date_tolerance" field-name="Tolerância de Vencimento" id="date_tolerance" name="date_tolerance" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Considera Nº Título</label>
									<select class="form-control select2 hide" field-name="Considera Nº Título" id="verify_number" name="verify_number" style="width: 100%;">
										<option value="1">Sim</option>
										<option value="0">Não</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Considera CNPJ</label>
									<select class="form-control select2 hide" field-name="Considera CNPJ" id="verify_cnpj" name="verify_cnpj" style="width: 100%;">
										<option value="0">Não considera</option>
										<option value="1">Somente Raiz</option>
										<option value="2">Todo CNPJ</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="conciliacaomanual" role="tabpanel">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Valor</label>
									<input type="text" class="form-control" field-name="Tolerância de Valor" id="manual_value_tolerance" name="manual_value_tolerance" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Tolerância de Venc.</label>
									<input type="text" class="form-control" v-model.lazy="ddaConfig.manual_date_tolerance" field-name="Tolerância de Vencimento" id="manual_date_tolerance" name="manual_date_tolerance" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Considera Nº Título</label>
									<select class="form-control select2 hide" field-name="Considera Nº Título" id="manual_verify_number" name="manual_verify_number" style="width: 100%;">
										<option value="1">Sim</option>
										<option value="0">Não</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Considera CNPJ fornecedor</label>
									<select class="form-control select2 hide" field-name="Considera CNPJ" id="manual_verify_cnpj" name="manual_verify_cnpj" style="width: 100%;">
										<option value="0">Não considera</option>
										<option value="1">Somente Raiz</option>
										<option value="2">Todo CNPJ</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
        data () {
            return {
				ddaConfig: {
					value_tolerance: 0,
					date_tolerance: 0,
					status: 1,
					verify_number: 0,
					verify_cnpj: 0,
					manual_verify_number: 0,
					manual_verify_cnpj: 0,
					manual_value_tolerance: 0,
					manual_date_tolerance: 0
				},
				companys: [],
				cnabRemittancesConfig: [],
				banks: [],
				dda_config_id: this.$route.params.dda_config_id,
            }
        },

        mounted: function () {
			numeral.language('pt-br');

			$('#value_tolerance').mask('00.000.000.000,00', {reverse: true, watchDataMask: true});
			$('#manual_value_tolerance').mask('00.000.000.000,00', {reverse: true, watchDataMask: true});

			$("#date_tolerance").mask("000");

			$(".select2").select2({placeholder: 'Selecione...'});
			$(".select2CnabRemittance").select2({placeholder: "Selecione a remessa..."});

			$('#value_tolerance').val(numeral(0).format('0,0.00'));
			$('#manual_value_tolerance').val(numeral(0).format('0,0.00'));

			this.loadCompanys();
			
			$("#code_bank").change(() =>{
				blockPage('Carregando os dados...');

				this.loadCnabRemittancesConfigs();
			});
        },

        methods: {
            loadCompanys: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(this.$store.state.API_BASE+'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	(result) =>{
						this.companys = result.body.data
						
						this.loadBanks();
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
			},
            loadBanks: function () {
				this.cnabRemittancesConfig = [];

                this.$http.get(this.$store.state.API_BASE + 'bank/listAll/' + this.$session.get('company_group_id'), {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
						this.banks = result.body.data;
						
						this.loadCnabRemittancesConfigs();

						if(this.dda_config_id != 'new'){
							setTimeout(() => this.loadData(), 100);
						} else {
							unblockPage();
						}
                    },
                    (error) => {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
			},	
			loadCnabRemittancesConfigs: function (){
				let bankField = $("#code_bank");

				if(!bankField.val()) return

				$.ajax({
					method: "GET",
					url: this.$store.state.API_BASE + 'cnabremittanceconfig/listAllByBank/' + bankField.val() + '/operation/1',
					headers: {
						'token': this.$session.get('token')
					}
				}).done((result) =>{
					if (result.error == false) {
						this.cnabRemittancesConfig = result.data;

						if(this.ddaConfig.id_cnab_remi_config){
							$("#id_cnab_remi_config").val(this.ddaConfig.id_cnab_remi_config);
							$("#id_cnab_remi_config").trigger('change');
						}
					} else {
						showNotification('Atenção', result.error, 'danger');
					}
					unblockPage();
				}).fail((error) =>{
					unblockPage();
					showNotification('Atenção', 'Oops! Ocorreu um erro ao carregar os bancos.', 'danger');
					console.log(error);
				});
			},
			loadData: function () {
				blockPage('Carregando os dados...');

				$.ajax({
                    method: "GET",
                    url: this.$store.state.API_BASE + 'dda/config/listOne/' + this.dda_config_id,
                    headers: {
                        'token': this.$session.get('token')
                    },
                }).done(result => {
					if (result &&  result.data && result.data[0]){
						this.ddaConfig = result.data[0];

						$("#company_id").val(this.ddaConfig.company_id);
						$("#company_id").trigger('change');

						$("#status").val(this.ddaConfig.status);
						$("#status").trigger('change');

						$("#code_bank").val(this.ddaConfig.code_bank);
						$("#code_bank").trigger('change');

						$("#root_cnpj").val(this.ddaConfig.root_cnpj);
						$("#root_cnpj").trigger('change');

						$("#verify_number").val(this.ddaConfig.verify_number);
						$("#verify_number").trigger('change');
						$("#manual_verify_number").val(this.ddaConfig.manual_verify_number);
						$("#manual_verify_number").trigger('change');

						$("#verify_cnpj").val(this.ddaConfig.verify_cnpj);
						$("#verify_cnpj").trigger('change');
						$("#manual_verify_cnpj").val(this.ddaConfig.manual_verify_cnpj);
						$("#manual_verify_cnpj").trigger('change');

						$('#value_tolerance').val(numeral(this.ddaConfig.value_tolerance).format('0,0.00'));
						$('#manual_value_tolerance').val(numeral(this.ddaConfig.manual_value_tolerance).format('0,0.00'));

						this.loadCnabRemittancesConfigs();						
					}

                    unblockPage();
                }).fail(error => {
                    console.log(error.responseText);
                    unblockPage();
				});				
			},			
			save: function () {
				let withError;
				let arrayData = [], i = 0;

				$.each($('input, select', '#frmUserGroup'),function(){
					if($.trim(this.value) == ''){
						withError = true;

						$(".form-group:has(#"+this.id+")").addClass('has-danger');
						$("#"+this.id).addClass('input-error');
						$("#"+this.id).css('border-color','red');
						showNotification('Atenção', 'O campo <strong>'+ $("#"+this.id).attr('field-name')+'</strong> não foi informado!', 'warning');

						$("#"+this.id).keyup(function(){
							if ($("#"+this.id).hasClass('input-error')) {
								$("#"+this.id).removeClass('input-error');
								$("#"+this.id).css('border-color', '#ebedf2');
								$(".form-group:has(#"+this.id+")").removeClass('has-danger');
							}
						});						
					}else {
						arrayData[i] = {};
						if(this.id == 'value_tolerance'){
							arrayData[i][this.id] = numeral($('#value_tolerance').val()).value();
						} else {
							arrayData[i][this.id] = this.value;
						}
					}
					
					i++;
				});

				if(withError) return false;

				if(this.dda_config_id != 'new') arrayData.push({id: this.dda_config_id});

				$.ajax({
					method: "POST",
					url: this.$store.state.API_BASE + 'dda/config/' + (this.dda_config_id == 'new' ? 'new' : 'edit'),
					headers: {
						'token': this.$session.get('token')
					},
					data: {
						arrayData: JSON.stringify(arrayData),
					}
				}).done((result) => {
					if(!result.error){
						showNotification('Sucesso', 'Processo concluído com sucesso!','success');

						this.$router.replace({name: 'ddaconfig'});
					}else{
						showNotification('Atenção',result.message, 'danger');
						return false;
					}
				}).fail((error) => {
					console.log(error.responseText);
				});
			}
        }
    }
</script>
