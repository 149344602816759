const snackbar = {};

// eslint-disable-next-line
snackbar.install = function (Vue, options) {
	if (!options.bus) {
		throw 'Snackbar::installError - event bus doesn\'t exist';
	}

	Vue.prototype.$snackbar = {
		error(messaging) {
			messaging.obj = { color: 'error' };

			if (!messaging.message) throw 'Snackbar::show() - Mensagem não definida';

			options.bus.$emit('showSnackbar', messaging);
		},
		success(messaging) {
			messaging.obj = { color: 'success' };

			if (!messaging.message) throw 'Snackbar::show() - Mensagem não definida';

			options.bus.$emit('showSnackbar', messaging);
		},
	};
};

export default snackbar;
