<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'user' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
				<ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab" aria-expanded="true">
							Cadastro
						</a>
					</li>
					<li class="nav-item m-tabs__item">
						<a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab" aria-expanded="false">
							Empresas
						</a>
					</li>
				</ul>
				<div class="tab-content">
					<div class="tab-pane active" id="tab1" role="tabpanel">
			            <div class="row" id="frmUser">
			            	<input type="hidden" name="user_id" id="user_id" v-model="user_id">
			                <div class="col-md-6">
			                    <div class="form-group">
			                        <label>Nome</label>
			                        <input type="text" class="form-control" field-name="Nome" id="name" name="name" required>
			                    </div>
			                </div>
			                <div class="col-md-6">
			                    <div class="form-group">
			                        <label>Email</label>
			                        <input type="email" class="form-control" field-name="Email" id="email" name="email" autocomplete="off" required>
			                    </div>
			                </div>
			                <div class="col-md-6">
			                    <div class="form-group">
			                        <label>Tipo de usuário</label>
			                        <select class="form-control select2 hide" field-name="Tipo de usuário" id="user_group_id" name="user_group_id" style="width: 100%;" required>
			                            <option v-for="(userGroup, index) in userGroups" :value="userGroup.id" :key="index">{{ userGroup.description }}</option>
			                        </select>
			                    </div>
			                </div>
			                <div class="col-md-6">
			                    <div class="form-group">
			                        <label>Senha</label>
			                        <input type="password" class="form-control" field-name="Senha" id="password" name="password">
			                    </div>
			                </div>
			                <div class="col-md-4">
			                    <div class="form-group">
			                        <label>Codigo ERP</label>
			                        <input type="text" class="form-control" field-name="Codigo ERP" id="erp_code" name="erp_code" required>
			                    </div>
			                </div>
			                <div class="col-md-3">
			                    <div class="form-group">
			                        <label>Status</label>
			                        <select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;">
			                            <option value="0">Inativo</option>
			                            <option value="1">Ativo</option>
			                        </select>
			                    </div>
			                </div>
			                <div class="col-md-12">
			                    <div class="form-group">
			                        <label>URL PowerBI</label>
			                        <input type="text" class="form-control" field-name="URL PowerBI" id="url_powerbi" name="url_powerbi">
			                    </div>
			                </div>
			            </div>
					</div>
					<div class="tab-pane" id="tab2" role="tabpanel">
						<div class="row">
							<div class="col-sm-12 text-right">
								<button type="button" class="btn m-btn--pill btn-primary btn-sm invertCompany">Inverter seleção</button>
							</div>
						</div>
			            <div class="row" id="frmUserCompany">
			                <div class="col-md-12">
			                	<h4><i class="la la-check-square"></i> Selecione as empresas que o usuário terá acesso</h4>
			                	<div v-for="(company, index) in companys" :key="index" class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
				                	<label class="col-8 col-form-label" style="text-align: left;">{{ company.name }}</label>
									<div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" :name="'ipt_company_'+company.id" :id="'ipt_company_'+company.id" :input-value="company.id">
												<span class="companySwitch" :input-field="'ipt_company_'+company.id"></span>
											</label>
										</span>
									</div>
			                	</div>
			                </div>
			            </div>
					</div>
				</div>
	        </div>
	    </div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
            	userGroups: [],
            	companys: [],
            	user_id: this.$route.params.user_id
            }
        },

        mounted: function () {
            this.loadUserGroups();
        	this.loadCompanyGroup();
        	this.loadCompanyByUser();
            this.loadMethod();
        	this.loadUser();
        },

        methods: {
            loadUserGroups: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(this.$store.state.API_BASE+'usergroup/listAll', {headers: {'token': this.$session.get('token')}}).then((result) => {
                    this.userGroups = result.body.data
                }).bind(this)
            },

            loadCompanyByUser: function () {
                this.$http.get(this.$store.state.API_BASE+'company/listAllActivie', {headers: {'token': this.$session.get('token')}}).then((result) => {
                    this.companys = result.body.data
                }).bind(this)
            },

        	loadCompanyGroup: function () {
        		var self = this;

            	$("#loading").css('display', 'initial');

				if (self.$session.get('user_type') == 'O') {
					$.ajax({
						method: "GET",
						url: self.$store.state.API_BASE+'companygroup/listAll',
						headers: {
							'token': self.$session.get('token')
						}
					}).done(function(result) {
						var options = "";

						$.each(result.data, function(resultItem) {
							options = options +'<option value="'+result.data[resultItem].id+'">'+result.data[resultItem].description+'</option>';
						});

						$("#frmUser").prepend(
							'<div class="col-md-12">'+
								'<div class="form-group">'+
									'<label>Grupo de empresa</label>'+
									'<select class="form-control select2 hide" field-name="Grupo de empresa" id="company_group_id" name="company_group_id" style="width: 100%;">'+
										'<option value="">Selecione...</option>'+
										options+
									'</select>'+
								'</div>'+
							'</div>'
						);
						$(".select2").select2();
					}).fail(function(error){
						console.log(error.responseText);
					});
				} else {
					$("#frmUser").prepend(
						'<input type="hidden" id="company_group_id" name="company_group_id" value="'+self.$session.get('company_group_id')+'"/>'
					);
				}
        	},

        	loadUser: function () {
        		var self = this;

				$.ajax({
					method: "GET",
					url: self.$store.state.API_BASE+'user/listOne/'+self.$route.params.user_id,
					headers: {
						'token': self.$session.get('token')
					}
				}).done(function(result) {
					var statusDesc = "";

					$('#name').val(result.data.name);
					$('#email').val(result.data.email);
					$('#status').val(result.data.status);
					$('#user_group_id').val(result.data.user_group_id);
					$('#company_group_id').val(result.data.company_group_id);
					$('#erp_code').val(result.data.erp_code);
					$('#url_powerbi').val(result.data.url_powerbi);

					if (self.$session.get('user_type') == 'O') {
						$('#select2-company_group_id-container').attr('title', result.data.company_group_name);
						$('#select2-company_group_id-container').html(result.data.company_group_name);
					}

					$('#select2-user_group_id-container').attr('title', result.data.user_group_name);
					$('#select2-user_group_id-container').html(result.data.user_group_name);

					if (result.data.status == 1) {
						statusDesc = "Ativo";
					} else {
						statusDesc = "Inativo";
					}
					$('#select2-status-container').attr('title', statusDesc);
					$('#select2-status-container').html(statusDesc);

					$.each(result.data.user_company, function() {
						$("#ipt_company_"+this.company_id).click();
						//$("#ipt_company_"+this.company_id).val(this.company_id);
					});

					unblockPage();
				}).fail(function(error){
					console.log(error.responseText);
				});
        	},

            loadMethod: function () {
				var self = this;
				$(".select2").select2();

				/*$(document).on('click', "span.companySwitch", function() {
					var inputId = $(this).attr('input-field');
					var inputValue = $(this).attr('input-value');

					if ($.trim($("#"+inputId).val()) == '') {
						$("#"+inputId).val(inputValue);
					} else {
						$("#"+inputId).val("");
					}
				});*/

				$(".invertCompany").click(function(){
					$.each($('input, select, span', '#frmUserCompany'),function(){
						if($(this).hasClass('companySwitch')){
							$(this).click();
						}
					});
				});

				$("#btn-save").click(function(){
					var arrayData = [], arrayDataCompany = [], exitButton = true, fieldName = '', i = 0, post = '';
		            var btn = $(this);

		            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					$.each($('input, select', '#frmUser'),function(){
					    var $this = $(this);
					    var attr = $this.attr('required');

					    if($.trim(this.value) == '' && typeof attr !== typeof undefined && attr !== false){
					        exitButton = true;
					        fieldName = $this.attr('field-name');

					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }else{
					        exitButton = false;
					        arrayData[i] = {};
					        arrayData[i][this.id] = this.value;
					    }

					    i++;
					});

					if(exitButton){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado!', 'warning');
					    return false;
					}

					i = 0;
					$.each($('input', '#frmUserCompany'),function(){
					    var $this = $(this);

					    if ($("#"+this.id+":checked").length > 0) {
					        arrayDataCompany[i] = {};
					        arrayDataCompany[i]['company_id'] = $(this).attr('input-value');

						    i++;
					    }
					});

					$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE+'user/edit',
						headers: {
							'token': self.$session.get('token')
						},
						data: {
							arrayData: JSON.stringify(arrayData),
							arrayDataCompany: JSON.stringify(arrayDataCompany)
						}
					}).done(function(result) {
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

						if(result.error == false){
							showNotification('Sucesso', 'Usuário alterado!','success');
						}else{
							showNotification('Atenção','Ocorreu um problema ao salvar os dados!<br><br><b style="color:red;">'+result.error+'</b>', 'danger');
							return false;
						}
					}).fail(function(error){
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            }
        }
    }
</script>
