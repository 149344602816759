import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSubheader,{staticClass:"pa-0 breadcrumbs mb-4 mt-2"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.returnToPreviousRoute}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-arrow-left ")])],1)]}}])},[_c('span',[_vm._v("Voltar")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"breadcrumb-link"},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.goToHomePage}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-home ")])],1)],1)]}}])},[_c('span',[_vm._v("Voltar a Página inicial")])]),(_vm.routes.length > 0)?[_vm._l((_vm.routes),function(route,index){return [_c('span',{key:("separator-" + index),staticClass:"separator"},[_vm._v("|")]),_c('router-link',{key:("link-" + index),staticClass:"breadcrumb-link",attrs:{"to":route.path}},[_vm._v(" "+_vm._s(route.name)+" ")])]})]:_vm._e(),_c('span',{staticClass:"separator"},[_vm._v("|")]),_c('span',{staticClass:"breadcrumb-text"},[_vm._v(_vm._s(_vm.pageTitle))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }