<template>
	<v-container fluid class="pa-6">
		<v-row>
			<v-col cols="12" v-if="!noColumn">
				<slot></slot>
			</v-col>

			<slot v-else></slot>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'PageContainer',
	props: {
		noColumn: Boolean,
	},
};
</script>

<style>

</style>
