import vuetify from '@/plugins/vuetify';
import InnDialogsWrapper from './InnDialogsWrapper.vue';

const plugin = {
  install(Vue) {
    const Dialog = Vue.extend(InnDialogsWrapper);
    const vm = new Dialog({
      vuetify,
    }).$mount();
    document.body.appendChild(vm.$el);

    Vue.prototype.$dialog = {
      confirm(title, message, options) {
        return vm.confirm(title, message, options);
      },
      startLoading() {
        vm.startLoading();
      },
      stopLoading() {
        vm.stopLoading();
      },
    };
  },
};

export default plugin;
