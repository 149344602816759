<template>
  <page-container>
    <v-row>
      <v-col>
        <breadcrumbs page-title="Folha de Pagamento"/>
        <v-card class="elevation-0 mb-5">
          <v-card-title>
            <v-spacer/>
            <v-col lg="3" md="4" sm="12"  xs="12">
              <v-btn
              @click="goToHistory"
              color="info"
              block
              large
              depressed
              >
                ARQUIVOS ENVIADOS
              </v-btn>
            </v-col>
          </v-card-title>
        </v-card>
        <vue-dropzone
          ref="payrollUpload"
          id="payrollUpload"
          :options="dropzoneOptions"
          use-custom-slot
          vdropzone-duplicate-file
          @vdropzone-error="handleUploadError"
          @vdropzone-success="handleUploadSuccess"
        >
          <div class="upload-message d-flex flex-column align-center justify-center">
            <v-img src="/assets/img/1031498-128.png" />
            <h3 class="mt-4 dropzone-custom-title">Solte os arquivos aqui</h3>
            <div class="mt-2 subtitle">...ou clique para selecionar um arquivo.</div>
          </div>
        </vue-dropzone>
      </v-col>
    </v-row>
  </page-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
import PageContainer from '../../components/defaults/PageContainer.vue';

export default {
  name: 'PayrollUpload',
  components: {
    VueDropzone: vue2Dropzone,
    Breadcrumbs,
    PageContainer,
  },
  data() {
   return {
      dropzoneOptions: {
        url: () => `${this.$store.state.API_BASE}payroll/upload`,
        dictRemoveFile: 'Remover',
        headers: {
          token: this.$session.get('token'),
        },
        dictInvalidFileType: 'Tipo do arquivo inválido!',
        autoProcessQueue: true,
        duplicateCheck: true,
        acceptedFiles: '.txt',
        parallelUploads: 1,
      },
    };
  },
  methods: {
    goToHistory() {
      this.$router.push('/folhas-de-pagamento/historico');
    },
    handleUploadError(file, { message }) {
      this.$notification.error(`${message}`);
    },
    handleUploadSuccess() {
      this.$notification.success('Arquivo enviado com sucesso!');
    },
  },
};
</script>

<style lang="sass">
#payrollUpload:not(.dz-started)
  display: flex
  align-items: center
  justify-content: center
</style>
