<template>
	<v-list-item
		link
		class="d-flex"
		@click="goToLink(access)"
		:class="isActive ? linkActiveClasses : ''"
	>
		<v-list-item-icon v-if="!subgroup">
			<v-icon>
				{{ access.new_icon_class }}
			</v-icon>
		</v-list-item-icon>

		<v-list-item-title
			class="font-weight-regular"
			:class="subgroup ? 'ml-11' : 'ml-n4'"
		>
			{{ access.name }}
		</v-list-item-title>
	</v-list-item>
</template>

<script>
export default {
	name: 'DrawerLink',
	props: {
		access: Object,
		subgroup: Boolean,
		isActive: Boolean,
	},
	data() {
		return {
			linkActiveClasses: 'drawer-link-active',
		};
	},
	methods: {
		goToLink(access) {
			let link = access.path;

			if (this.$session.get('company_group_id') === 7 && link === 'cashflowprojected') {
				link = `unoesc/${link}`;
			}

			this.$router.replace('/dashboard');
			this.$router.replace(`${link}`);

			this.$emit('routeChanged');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/sass/colors';

.drawer-link-active {
	& > .v-list-item__icon > i {
		color: map_get($colors, 'primary') !important;
	}

	& > .v-list-item__title {
		color: map_get($colors, 'primary') !important;
	}
}

</style>
