import DdaFiles from '@oldinncash/views/ddaupload/ddafiles.vue';
import ApiBankingConfigList from '@/views/apibankingconfig/List.vue';
import ApiBankingConfigSave from '@/views/apibankingconfig/Save.vue';
import BillingRemittance from '@/views/billingRemittance/List.vue';
import AcquittanceReason from '@/views/acquittanceReason/List.vue';
import AcquittanceReasonSave from '@/views/acquittanceReason/Save.vue';
import ApiBankingCredential from '@/views/apiBankingCredential/List.vue';
import ApiBankingCredentialSave from '@/views/apiBankingCredential/Save.vue';

import PayrollUpload from '@/views/payroll/PayrollUpload.vue';
import PayrollHistory from '@/views/payroll/PayrollHistory.vue';

import reportCashFlowRealized from '@/views/reportCashflowRealized/List.vue';
import reportFinancialApplication from '@/views/reportFinancialApplication/List.vue';

export default [
  {
    path: '/apibankingconfig',
    name: 'ApiBankingConfig',
    component: ApiBankingConfigList,
  },
  {
    path: '/apibankingconfig/:service/type/:type/company/:company',
    name: 'ApiBankingConfigEdit',
    component: ApiBankingConfigSave,
  },
  {
    path: '/apibankingconfig/:service/',
    name: 'ApiBankingConfigSave',
    component: ApiBankingConfigSave,
  },
  {
    path: '/billingremittance/',
    name: 'BillingRemittance',
    component: BillingRemittance,
  },
  {
    path: '/ddafiles',
    name: 'ddafiles',
    component: DdaFiles,
  },
  {
    path: '/folhas-de-pagamento/enviar',
    name: 'folhas-de-pagamento-enviar',
    component: PayrollUpload,
  },
  {
    path: '/folhas-de-pagamento/historico',
    name: 'folhas-de-pagamento-historico',
    component: PayrollHistory,
  },
	{
		path: '/acquittancereason',
		name: 'AcquittanceReason',
		component: AcquittanceReason,

	},
	{
		path: '/acquittancereason/save',
		name: 'AcquittanceReasonSave',
		component: AcquittanceReasonSave,
	},
	{
		path: '/acquittancereason/save/:id',
		name: 'AcquittanceReasonUpdate',
		component: AcquittanceReasonSave,
	},

	{
		path: '/apibankingcredential',
		name: 'ApiBankingCredential',
		component: ApiBankingCredential,

	},
	{
		path: '/apibankingcredential/save',
		name: 'ApiBankingCredentialSave',
		component: ApiBankingCredentialSave,
	},
	{
		path: '/apibankingcredential/save/:id',
		name: 'ApiBankingCredentialUpdate',
		component: ApiBankingCredentialSave,
	},
  {
    path: '/relatorio/fluxo-caixa-realizado',
    name: 'relatorio-fluxo-caixa-realizado',
    component: reportCashFlowRealized,
  },
  {
    path: '/relatorio/aplicacoes',
    name: 'relatorio-aplicacoes',
    component: reportFinancialApplication,
  },

];
