import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Folha de Pagamento"}}),_c(VCard,{staticClass:"elevation-0 mb-5"},[_c(VCardTitle,[_c(VSpacer),_c(VCol,{attrs:{"lg":"3","md":"4","sm":"12","xs":"12"}},[_c(VBtn,{attrs:{"color":"info","block":"","large":"","depressed":""},on:{"click":_vm.goToHistory}},[_vm._v(" ARQUIVOS ENVIADOS ")])],1)],1)],1),_c('vue-dropzone',{ref:"payrollUpload",attrs:{"id":"payrollUpload","options":_vm.dropzoneOptions,"use-custom-slot":"","vdropzone-duplicate-file":""},on:{"vdropzone-error":_vm.handleUploadError,"vdropzone-success":_vm.handleUploadSuccess}},[_c('div',{staticClass:"upload-message d-flex flex-column align-center justify-center"},[_c(VImg,{attrs:{"src":"/assets/img/1031498-128.png"}}),_c('h3',{staticClass:"mt-4 dropzone-custom-title"},[_vm._v("Solte os arquivos aqui")]),_c('div',{staticClass:"mt-2 subtitle"},[_vm._v("...ou clique para selecionar um arquivo.")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }