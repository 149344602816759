<template>
	<v-list-group
		v-model="handleValue"
	>
		<template v-slot:activator>
			<v-list-item
				class="pa-0 mx-0"
				:class="isActive ? 'primary--text': ''"
			>
				<v-list-item-icon>
					<v-icon	:class="isActive ? 'primary--text': ''">
						{{ access.new_icon_class }}
					</v-icon>
				</v-list-item-icon>

				<v-list-item-title
					class="font-weight-regular drawer-group__item"
					:class="isActive ? 'primary--text': ''"
				>
					{{ access.father_name }}
				</v-list-item-title>
			</v-list-item>
		</template>

		<drawer-link
			v-if="access.name"
			:access="access"
			:is-active="isActiveWhenRouter(access)"
			subgroup
		/>

		<drawer-link
			v-for="child in access.children"
			:key="child.id"
			:access="child"
			:is-active="isActiveWhenRouter(child)"
			subgroup
		/>
	</v-list-group>
</template>

<script>
import { bus } from '@/main';
import DrawerLink from './DrawerLink.vue';
import DrawerActions from './drawer.actions';

export default {
	name: 'DrawerGroup',
	components: {
		DrawerLink,
	},
	mixins: [DrawerActions],
	props: {
		access: Object,
		value: [Object, Boolean],
		isActive: Boolean,
	},
	data() {
		return {
			handleValue: this.value,
		};
	},
	created() {
		bus.$on('drawer_closeAllMenus', this.closeMenu);
	},
	watch: {
		handleValue() {
			this.$emit('input', this.handleValue);
		},
	},
	methods: {
		closeMenu() {
			this.handleValue = false;
		},
	},
};
</script>

<style>
.drawer-group__item {
	transform: translate(-14px, 0px)
}

.v-list-group__header__append-icon {
	float: right;
    position: absolute !important;
    left: 222px;
}
</style>
