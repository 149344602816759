<template>
    <div class="modal fade" id="modalViewInformation" tabindex="-1" role="dialog" aria-labelledby="modalViewInformation" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="title_data_view_information">
                        Inclusão de Movimento Bancário no ERP
                    </h5>
                </div>
                <form id="form_data_view_information" class="m-form m-form--fit m-form--label-align-right">
                    <div id="data_view_information" style="padding: 0px 25px 0px 25px;" class="modal-body">
                        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Data</label>
                                        <div class="input-group date" id="statement_date">
                                            <input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data" disabled>
                                            <span class="input-group-addon">
                                                  <i class="la la-calendar-check-o"></i>
                                              </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Banco</label>
                                        <select class="form-control select2 hide" field-name="Banco" id="statement_bank" style="width: 100%;" disabled>
                                            <option value="">Selecione...</option>
                                            <option v-for="bank in banks" :value="JSON.stringify(bank)" :key="bank.id">
                                                {{bank.code}} {{ bank.agency}} {{ bank.account}} - {{ bank.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Tipo</label>
                                        <select class="form-control select2 hide" id="statement_tp_lancamento" style="width: 100%;" disabled>
                                            <option :value="undefined">Selecione...</option>
                                            <option value="C">Crédito</option>
                                            <option value="D">Débito</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Valor</label>
                                        <input id="statement_value" type="text" class="form-control text-right" placeholder="Informe o valor" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Realizar transferência bancária</label>
                                        <select class="form-control select2 hide" field-name="Realizar transferência bancária" id="tp_bank_transfer" name="tp_bank_transfer" style="width: 100%;">
                                            <option value="">Selecione...</option>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Banco para transferência</label>
                                        <select class="form-control select2 hide" field-name="Banco para transferência" id="bank_transfer" name="bank_transfer" style="width: 100%;" :disabled="generate_bank_transfer == '0'" :required="generate_bank_transfer == '1'">
                                            <option value="">Selecione...</option>
                                            <option v-for="bank in banks" :value="JSON.stringify(bank)" :key="bank.id">
                                                {{bank.code}} {{ bank.agency}} {{ bank.account}} - {{ bank.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="conciliationConfigs.in_use_financial_nature == 1">
                                    <div class="form-group">
                                        <label>Natureza Financeira</label>
                                        <select class="form-control select2 hide" field-name="Natureza Financeira" id="statement_ed_codigo" name="statement_ed_codigo" style="width: 100%;" :required="conciliationConfigs.in_required_financial_nature == 1">
                                            <option v-for="financialnature in listFinancialNature" :value="financialnature.ed_codigo" :key="financialnature.id">{{ financialnature.ed_codigo  }} - {{financialnature.ed_descric}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="conciliationConfigs.in_use_cost_center == 1">
                                    <div class="form-group">
                                        <label>Centro de Custo</label>
                                        <select class="form-control select2 hide" field-name="Centro de Custo" id="statement_ctt_custo" name="statement_ctt_custo" style="width: 100%;" :required="conciliationConfigs.in_required_cost_center == 1">
                                            <option v-for="costcenter in listCostCenter" :value="costcenter.ctt_custo" :key="costcenter.id">{{ costcenter.ctt_custo  }} - {{costcenter.ctt_desc01}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="conciliationConfigs.in_use_accounting_item == 1">
                                    <div class="form-group">
                                        <label>Item Contábil</label>
                                        <select class="form-control select2 hide" field-name="Item Contabil" id="statement_ctd_item" name="statement_ctd_item" style="width: 100%;" :required="conciliationConfigs.in_required_accounting_item == 1">
                                            <option v-for="accountingItem in listAccountingItem" :value="accountingItem.ctd_item" :key="accountingItem.id">{{ accountingItem.ctd_item  }} - {{accountingItem.ctd_desc01}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="conciliationConfigs.in_use_value_class == 1">
                                    <div class="form-group">
                                        <label>Classe de Valor</label>
                                        <select class="form-control select2 hide" field-name="Classe de Valor" id="statement_cth_clvl" name="statement_cth_clvl" style="width: 100%;" :required="conciliationConfigs.in_required_value_class == 1">
                                            <option v-for="valueClass in listValueClass" :value="valueClass.cth_clvl" :key="valueClass.id">{{ valueClass.cth_clvl  }} - {{valueClass.cth_desc01}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="conciliationConfigs.in_use_prefix == 1">
                                    <div class="form-group">
                                        <label>Prefixo</label>
                                        <input type="text" class="form-control m-input" id="statement_prefix">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Histórico</label>
                                        <input type="text" class="form-control m-input" id="statement_historico">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn m-btn--air btn-success" @click="saveConciliation()" id="btnUpdate">Salvar</button>
                        <button type="button" class="btn m-btn--air btn-primary" id="btnModalClose" data-dismiss="modal">Fechar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            banks: [],
            record: null,
            conciliationrecord: null,
            listFinancialNature: [],
            listCostCenter:[],
            listAccountingItem: [],
            listValueClass: [],
            conciliationConfigs: [],
            currentBank: null,
            generate_bank_transfer: false,
            thisStatementconciliation: null
        };
    },
    methods: {
        loadBanks: async function(company_id) {
            await this.$http.get(this.$store.state.API_BASE + 'bank/listAllByCompany/' + company_id, { headers: { 'token': this.$session.get('token') } }).then((result) => {                this.banks = result.body.data && result.body && result.body.data ? result.body.data : [];
            }, (error) => {
                showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                unblockPage();
            });
        },
        openModal: async function(record, bank, thisStatementconciliation, conciliatiionrecord) {
            this.currentBank = bank;
            this.record = record;
            this.generate_bank_transfer = false;
            this.thisStatementconciliation = thisStatementconciliation;
            this.conciliationrecord = conciliatiionrecord;

            blockPage("Carregando os dados...");

            await this.loadConciliationConfigReleases(this.record.company_id, this.record.banco).then(async (result) => {

                if(!result.body.data.conciliation_config[0]){
                    showNotification("Atenção", "Verifique as configurações de conciliação bancária para esta empresa.", "warning");
                    unblockPage();
                    return;
                }
                this.conciliationConfigs = result.body.data.conciliation_config[0];

                await this.loadBanks(record.company_id);

                $("#statement_bank").val(bank);
                $("#statement_bank").trigger("change");
                $("#statement_bank").select2({ placeholder: "Selecione" });

                let date = moment(record.data_movimento).format("DD/MM/YYYY");
                $("#statement_date > input").val(date);
                $("#statement_date > input").datepicker({
                    autoclose: true,
                    format: "dd/mm/yyyy",
                    todayHighlight: !0,
                    orientation: "bottom left",
                    defaultDate: date,
                    templates: {
                        leftArrow: '<i class="la la-angle-left"></i>',
                        rightArrow: '<i class="la la-angle-right"></i>'
                    }
                });

                $("#statement_tp_lancamento").val(record.tp_lancamento);
                $("#statement_tp_lancamento").trigger("change");
                $("#statement_tp_lancamento").select2({ placeholder: "Selecione" });

                $("#statement_id_cost_center").select2({
                    placeholder: "Selecione"
                });
                $("#statement_id_accounting_item").select2({
                    placeholder: "Selecione"
                });
                $("#statement_id_value_class").select2({
                    placeholder: "Selecione"
                });

                $("#statement_ed_codigo").val(null);
                $("#statement_ed_codigo").select2({
                    placeholder: "Selecione",
                    language: "pt-BR",
                    dropdownParent: $("#modalViewInformation"),
                    ajax: {
                        url: this.$store.state.API_BASE + "financialnature/listAll",
                        dataType: "json",
                        width: "resolve",
                        type: "GET",
                        delay: 500,
                        headers: {
                            token: this.$session.get("token")
                        },
                        data: function(param) {
                            const company = record.company_id;

                            return {
                                search: param.term || "",
                                limit: 100,
                                company_id: (company ? company : '')
                            };
                        },
                        processResults: result => {
                            let resultado = [];
                            for (let i = 0; i < result.data.length; i++) {
                                resultado.push({
                                    id: result.data[i].ed_codigo,
                                    text:
                                        (result.data[i].ed_codigo || "") +
                                        " - " +
                                        (result.data[i].ed_descric || "")
                                });
                            }
                            return {
                                results: resultado
                            };
                        },
                        cache: true
                    }
                });

                $("#statement_ctt_custo").val(null);
                $("#statement_ctt_custo").select2({
                    placeholder: "Selecione",
                    language: "pt-BR",
                    dropdownParent: $("#modalViewInformation"),
                    ajax: {
                        url: this.$store.state.API_BASE + "costcenter/listAll",
                        dataType: "json",
                        width: "resolve",
                        type: "GET",
                        delay: 500,
                        headers: {
                            token: this.$session.get("token")
                        },
                        data: function(param) {
                            const company = record.company_id;

                            return {
                                search: param.term || "",
                                limit: 100,
                                company_id:(company ? company : '')
                            };
                        },
                        processResults: result => {
                            let resultado = [];
                            for (let i = 0; i < result.data.length; i++) {
                                resultado.push({
                                    id: result.data[i].ctt_custo,
                                    text:
                                        (result.data[i].ctt_custo || "") +
                                        " - " +
                                        (result.data[i].ctt_desc01 || "")
                                });
                            }
                            return {
                                results: resultado
                            };
                        },
                        cache: true
                    }
                });

                $("#statement_ctd_item").val(null);
                $("#statement_ctd_item").select2({
                    placeholder: "Selecione",
                    language: "pt-BR",
                    dropdownParent: $("#modalViewInformation"),
                    ajax: {
                        url: this.$store.state.API_BASE + "accountingitem/listAll",
                        dataType: "json",
                        width: "resolve",
                        type: "GET",
                        delay: 500,
                        headers: {
                            token: this.$session.get("token")
                        },
                        data: function(param) {
                            const company = record.company_id;
                            return {
                                search: param.term || "",
                                limit: 100,
                                company_id: (company ? company: '')
                            };
                        },
                        processResults: result => {
                            let resultado = [];
                            for (let i = 0; i < result.data.length; i++) {
                                resultado.push({
                                    id: result.data[i].ctd_item,
                                    text:
                                        (result.data[i].ctd_item || "") +
                                        " - " +
                                        (result.data[i].ctd_desc01 || "")
                                });
                            }
                            return {
                                results: resultado
                            };
                        },
                        cache: true
                    }
                });

                $("#statement_cth_clvl").val(null);
                $("#statement_cth_clvl").select2({
                    placeholder: "Selecione",
                    language: "pt-BR",
                    dropdownParent: $("#modalViewInformation"),
                    ajax: {
                        url: this.$store.state.API_BASE + "valueclass/listAll",
                        dataType: "json",
                        width: "resolve",
                        type: "GET",
                        delay: 500,
                        headers: {
                            token: this.$session.get("token")
                        },
                        data: function(param) {
                            const company = record.company_id;

                            return {
                                search: param.term || "",
                                limit: 100,
                                company_id: (company ? company : '')
                            };
                        },
                        processResults: result => {
                            let resultado = [];
                            for (let i = 0; i < result.data.length; i++) {
                                resultado.push({
                                    id: result.data[i].cth_clvl,
                                    text:
                                        (result.data[i].cth_clvl || "") +
                                        " - " +
                                        (result.data[i].cth_desc01 || "")
                                });
                            }
                            return {
                                results: resultado
                            };
                        },
                        cache: true
                    }
                });

                $("#statement_value").val(
                    numeral(record.valor).format("0,0.00")
                );
                $("#statement_value").mask("00.000.000.000,00", { reverse: true });

                $("#statement_historico").val(record.historico);
                $("#statement_prefix").val(this.conciliationConfigs.prefix);

                $("#tp_bank_transfer").select2({
                    placeholder: "Selecione",
                    language: "pt-BR",
                    dropdownParent: $("#modalViewInformation")
                });

                $("#bank_transfer").select2({
                    placeholder: "Selecione",
                    language: "pt-BR",
                    dropdownParent: $("#modalViewInformation")
                });

                $('#tp_bank_transfer').val("0");
                $("#tp_bank_transfer").trigger('change');

                $("#tp_bank_transfer").change(() => {
                    this.generate_bank_transfer = $("#tp_bank_transfer").val();

                    if(this.generate_bank_transfer == "0"){
                        $("#bank_transfer").val(null);
                        $("#bank_transfer").trigger("change");
                        $("#bank_transfer").select2({
                            placeholder: "Selecione",
                            language: "pt-BR",
                            dropdownParent: $("#modalViewInformation")
                        });
                    }
                });

                $("#modalViewInformation").modal("show");

                unblockPage();
            },
            error => {
                showNotification("Atenção", "Opa! Parece que algo saiu errado, tente recarregar a página", "danger");
                unblockPage();
            });
        },
        loadConciliationConfigReleases: async function(company_id, bank_code) {
            return this.$http.get(this.$store.state.API_BASE + "conciliation/action/listOneByCompanyBank/" + company_id + "/" + bank_code, { headers: { token: this.$session.get("token") } });
        },
        saveConciliation () {
            let self = this;

            checkImportBeforeSearch(self).then(
                function (result) {
                    if (!result.body.payload) {
                        self.save();
                    } else {
                        showNotification('Atenção', 'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.', 'warning')
                        unblockPage();
                    }

                },
                function (error) {
                    showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                    unblockPage();
                });
        },
        save() {

            var thiss = this;
            let prefix = $("#statement_prefix").val();

            //Validação
            var arrayData = [],
            arrayDataFilter = [],
            arrayDataFilterErp = [],
            exitButton = false,
            fieldName = '',
            i = 0,
            post = '',
            id = '',
            totExpression = 0,
            totOperator = 0,
            totOperatorCap = 0;
            var btn = $(this);

            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
            $.each($('input, select', '#form_data_view_information'), function() {
                var $this = $(this);
                var name = $this.attr('name');
                id = $this.attr('id');

                if ($.trim(this.value) == '' && $this.attr('required') == 'required') {
                    exitButton = true;
                    fieldName = $this.attr('field-name');

                    $(".form-group:has(#" + this.id + ")").addClass('has-danger');
                    $("#" + this.id).addClass('input-error');
                    $("#" + this.id).css('border-color', 'red');
                    showNotification('Atenção', 'O campo <strong>' + fieldName + '</strong> não foi informado!', 'warning');
                    //return false;
                } else {
                    arrayData[i] = {};
                    if (name != "where_show") {
                        arrayData[i][this.id] = this.value;
                    } else {
                        arrayData[i][this.id] = $this.val().join(";");
                    }

                    //Se não for pr gerar transferência bancário seta como null
                    if(id == "bank_transfer" && !thiss.generate_bank_transfer){
                        $this.val(null);
                    }
                }
                i++;
            });

            if (exitButton) {
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                return false;
            }

            blockPage("Aguarde...");

            var bank = JSON.parse($("#statement_bank").val());
            var company_id = $("#company_id").val();

            //Prepara objeto para enviar para a API.
            let arraySave = [{
                id: this.record.id,
                data_movimento: this.record.data_movimento,
                type: "N", // C para conciliar , N para inserir novo registro na SE5,
                action_id: this.record.action_id,
                company_id: this.record.company_id,
                numero_documento: this.record.numero_documento,
                banco: bank.code,
                agencia: bank.agency,
                numcon: bank.account,
                tp_lancamento: $("#statement_tp_lancamento").val(), //Tipo de lançamento (C - Crédito, D - Débito)
                bank_transfer: $("#bank_transfer").val(), //banco para transferência
                financials: [{
                    e5_data: moment(
                        $("#statement_date > input").val(),
                        "DD/MM/YYYYY"
                    ).format("YYYYMMDD"),
                    e5_filial: "",
                    e5_filorig: "",
                    e5_banco: bank.code,
                    e5_agencia: bank.agency,
                    e5_conta: bank.account,
                    e5_documen: "",
                    e5_recpag: $("#statement_tp_lancamento").val() == "C" ? "R" : "P",
                    e5_valor: numeral().unformat($("#statement_value").val()),
                    e5_idorig: "",
                    e5_tipo: "",
                    e5_prefixo: (prefix ? prefix : ''),
                    e5_numero: "",
                    e5_parcela: "",
                    e5_clifor: "",
                    e5_loja: "",
                    e5_situaca: "",
                    e5_tipodoc: "",
                    e5_seq: "",
                    e5_naturez: $("#statement_ed_codigo").val() || "",
                    e5_ccd: $("#statement_ctt_custo").val() || "",
                    e5_ccc: $("#statement_ctt_custo").val() || "",
                    e5_clvldb: $("#statement_cth_clvl").val() || "",
                    e5_clvlcr: $("#statement_cth_clvl").val() || "",
                    e5_itemd: $("#statement_ctd_item").val() || "",
                    e5_itemc: $("#statement_ctd_item").val() || "",
                    ed_descric: "",
                    e5_histor: $("#statement_historico").val(),
                    TABLE_COMPANY_CODE: "",
                    recno: ""
                }]
            }];

            $.ajax({
                method: "POST",
                url: this.$store.state.API_BASE + "conciliation/conciliation",
                headers: {
                    token: this.$session.get("token")
                },
                data: {
                    arrayData: JSON.stringify(arraySave)
                }
            })
            .done(result => {
                if (result.error == false) {
                    this.record.status = 1;
                    this.conciliationrecord.conciliation_record_movimento_bancario.push(
                      arraySave[0].financials[0]
                    );

                    this.conciliationrecord.conciliation_record_movimento_bancario[0].recno = String(
                        result.recno
                    );

                    $("#modalViewInformation").modal("hide");
                    showNotification(
                        "Sucesso",
                        "Dados conciliados com sucesso!",
                        "success"
                    );

                    if(this.thisStatementconciliation) {
                        this.thisStatementconciliation.loadBankbalance();
                    }

                    unblockPage();
                } else {
                    showNotification("Atenção", result.error, "danger");
                    return false;
                }
            })
            .fail(error => {
                unblockPage();
                console.log(error);
                showNotification("Opss!", "Não foi possível salvar dados!", "danger");
            });
        }
    }
};
</script>

<style scoped>

</style>
