import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Cadastro de Motivo de Baixa"}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCardTitle,[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"6","xs":"12"}},[_c('list-add-button',{on:{"click":_vm.add}})],1)],1),_c(VCardText,{staticClass:"pa-6 pt-0"},[_c(VDataTable,{staticClass:"elevation-0 pa-3",attrs:{"headers":_vm.headers,"loading":_vm.loadAcquittanceTable,"items":_vm.acquittanceReasonTable,"hide-default-footer":"","disable-pagination":"","group-by":"name"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"12"}},[_c(VIcon,{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_c('span',[_vm._v(" "+_vm._s(group))])],1)]}},{key:"item.acquittanceDetailStatus",fn:function(ref){
var item = ref.item;
return [(item.acquittanceDetailStatus === 'I')?_c(VChip,{staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" Inativo ")]):_c(VChip,{staticClass:"ma-2",attrs:{"small":"","color":"green","text-color":"white"}},[_vm._v(" Ativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-edit-button',{on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }