<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'block' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
	            <div class="row" id="frmBlock">
	            	<input type="hidden" name="company_group_id" id="company_group_id" v-model="company_group_id">
	                <div class="col-md-12">
	                    <div class="form-group">
	                        <label>Empresa</label>
	                        <select class="form-control select2 hide" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;">
	                            <option value="">Todas</option>
	                            <option v-for="company in companys" :value="company.id" :key="company.id">{{ company.name }}</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-6">
	                    <div class="form-group">
	                        <label>Grupo de usuário</label>
	                        <select class="form-control select2 hide" field-name="Groupo de usuário" id="user_group_id" name="user_group_id" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option v-for="userGroup in userGroups" :value="userGroup.id" :key="userGroup.id">{{ userGroup.description }}</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-6">
	                    <div class="form-group">
	                        <label>Tipo</label>
	                        <select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="A">Acrescimo</option>
	                            <option value="D">Decrescimo</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Limite máximo</label>
	                        <input type="text" class="form-control" field-name="Limite máximo" id="max_limit" name="max_limit" required>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Operador</label>
	                        <select class="form-control select2 hide" field-name="Operador" id="operator" name="operator" style="width: 100%;">
	                            <option value="">Selecione...</option>
	                            <option value="%">% (Percentual a.m.)</option>
	                            <option value="$">$ (Valor)</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tipo de calculo</label>
	                        <select class="form-control select2 hide" field-name="Tipo de calculo" id="calculate_type" name="calculate_type" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="M">Ao mes</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Data (qtde)</label>
	                        <input type="text" class="form-control" field-name="Data (qtde)" id="date_quantity" name="date_quantity" required>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Operador</label>
	                        <select class="form-control select2 hide" field-name="Operador" id="date_operator" name="date_operator" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="D">Dias</option>
	                            <option value="M">Meses</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tipo de bloqueio</label>
	                        <select class="form-control select2 hide" field-name="Tipo de bloqueio" id="block_type" name="block_type" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="B">Bloqueia</option>
	                            <option value="C">Cancela liberação</option>
	                        </select>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
            	companys: [],
            	userGroups: [],
            	company_group_id: this.$session.get('company_group_id')
            }
        },

        mounted: function () {
            this.loadCompanys();
            this.loadUserGroups();
            this.loadMethod();
        },

        methods: {
            loadCompanys: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(this.$store.state.API_BASE+'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {
	                    this.companys = result.body.data
		            	unblockPage();
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                ).bind(this)
            },

            loadUserGroups: function () {
                this.$http.get(this.$store.state.API_BASE+'usergroup/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {
	                    this.userGroups = result.body.data
		            	unblockPage();
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                ).bind(this)
            },

            loadMethod: function () {
            	var self = this;

				$(".select2").select2();
				$("#cnpj").mask("00.000.000/0000-00");
				$("#phone").mask("(00) 00000-0000");
				$("#zipcode").mask("0000-000");

				$("#btn-save").click(function(){
					var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
		            var btn = $(this);

		            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					$.each($('input, select', '#frmBlock'),function(){
					    var $this = $(this);

					    if($.trim($this.val()) == '' && $this.attr('required') == 'required'){
					        exitButton = true;
					        fieldName = $this.attr('field-name');

					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }else{
					        exitButton = false;
					        arrayData[i] = {};
					        arrayData[i][this.id] = this.value;
					    }

					    i++;
					});

					if(exitButton){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado!', 'warning');
					    return false;
					}

					$.ajax({
						method: "POST",
						url: self.$store.state.API_BASE+'block/new',
                        headers: {
                            'token': self.$session.get('token')
                        },
						data: {
							arrayData: JSON.stringify(arrayData)
						}
					}).done(function(result) {
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

						if(result.error == false){
							showNotification('Sucesso', 'Novo bloqueio registrado!','success');

							$.each($('input', '#frmBlock'),function(){
								this.value = '';
							});
						}else{
							showNotification('Atenção',result.error, 'danger');
							return false;
						}
					}).fail(function(error){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            }
        }
    }
</script>
