<template>
  <v-row>
    <v-col>
			<breadcrumbs page-title="Configurações CNAB"/>
      <v-card class="elevation-0">
        <v-card-title>
          <v-col cols="12" lg="3" md="3" sm="12" xs="12">
            <v-select 
              v-model="configs.filterStatus"
              filled
              dense
              hide-details
              placeholder="Seleciona o status"
              :items="filterStatusItems"/>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="12" xs="12">
            <v-select 
              v-model="configs.filterOperation"
              filled
              dense
              hide-details
              :items="filterOperationItems"/>
          </v-col>
        	<v-col cols="12" lg="4" md="3" sm="12" xs="12">
              <v-text-field
              filled
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Pesquisar"
              v-model="configs.filterTerm"
            />
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="12" xs="12">
						<list-add-button @click="add"/>
          </v-col>
        </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <table class="table table-striped m-table">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Operação</th>
                    <th>Descrição</th>
                    <th>Tamanho</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cnabRemittance in listData" v-bind:key="cnabRemittance.id">
                    <td style="vertical-align: middle;">
                      <v-chip
                      v-if="cnabRemittance.type == 0"
                      dense
                      small
                      color="error"
                      >
                      Pagar
                    </v-chip>
                    <v-chip
                      v-else
                      dense
                      small
                      color="green"
                      text-color="white">
                      Receber
                    </v-chip>
                    </td>
                    <td style="vertical-align: middle;">
                      <span v-if="cnabRemittance.operation == 0">Remessa</span>
                      <span v-else>Retorno</span>
                    </td>
                    <td style="vertical-align: middle;">{{ cnabRemittance.description }}</td>
                    <td style="vertical-align: middle;">{{ cnabRemittance.size }}</td>
                    <td class="m--align-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">	
                          <v-btn
                            fab
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="duplicateCnab(cnabRemittance)"
                            elevation="0"
                          >
                            <v-icon>
                              mdi-content-copy
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Duplicar</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">	
                          <v-btn
                            fab
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="$router.push(`cnabremittance/edit/${cnabRemittance.id}`)"
                            elevation="0"
                          >
                            <v-icon>
                              mdi-square-edit-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">	
                          <v-btn
                            fab
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="remove(cnabRemittance)"
                            elevation="0"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Remover</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <cnab-replicate ref="CnabReplicate"></cnab-replicate>
            </v-col>
          </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import CnabReplicate from "./cnab-replicate.vue";
import Breadcrumbs from '@/components/defaults/breadcrumbs.vue';
import ListAddButton from '@/components/buttons/ListAddButton.vue';

export default {
  components: {
    CnabReplicate,
    Breadcrumbs,
		ListAddButton,
  },
  name: "cnabremittance",
  data() {
    return {
      cnabRemittances: [],
      configs: {
        filterTerm: "",
        filterStatus: "",
        filterOperation: ""
      },
      filterOperationItems: [
        {
          text: 'Todas as operações',
          value: '',
        },
        {
          text: 'Remessa',
          value: '0',
        },
        {
          text: 'Retorno',
          value: '1',
        },
      ],
      filterStatusItems: [
        {
          text: "Todos os tipos",
          value: "",
        },
        {
          text: "Pagar",
          value: "0",
        },
        {
          text: "Receber",
          value: "1",
        },
      ]
    };
  },

  computed: {
    listData() {
      let dataRet = this.cnabRemittances;

      if (this.configs.filterStatus) {
        dataRet = _.filter(dataRet, data => data.type == this.configs.filterStatus);
      }

      if (this.configs.filterOperation) {
        dataRet = _.filter(dataRet, data => data.operation == this.configs.filterOperation);
      }

      if (this.configs.filterTerm.toLowerCase()) {
        dataRet = _.filter(
          dataRet,
          list => list.description.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0
          || list.size.toString().toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0
        );
      }

      return dataRet;
    }
    
  },
  mounted: function() {
    this.loadCnabRemittances();
  },
  methods: {
    loadCnabRemittances: function() {
      blockPage("Carregando os dados...");

      this.$http
        .get(this.$store.state.API_BASE + "cnabremittance/listAll", {
          headers: { token: this.$session.get("token") }
        })
        .then(
          result => {
            this.cnabRemittances = result.body.data;
            unblockPage();
          },
          error => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    duplicateCnab: function(cnabRemittance) {
      // console.log(this.$session); 
      this.$refs.CnabReplicate.openModal(cnabRemittance);
      /*
                console.log('company_group_id: ' + this.$session.get('company_group_id'));

                if (confirm("Deseja realmente duplicar a configuração de CNAB?")){

                    var database = prompt("Informe o nome da base de dados", "fluxocaixa");
                    
                    if(!database){
                        alert("Dados inválidos!");
                        return;
                    }

                    this.processDuplicateCnab(cnabRemittance.id, this.$session.get('company_group_id'));
                }
                */
    },
    /*
     *   REMOVE
     *   Funcao padrao de comunicacao para remocao
     *   o padrao usado pode ser encontrado em bankconfig.vue
     */
    remove: function(cnabRemittance) {
      Swal.fire({
        title: "Atenção!",
        text: "Deseja realmente excluir?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#5867dd",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        focusCancel: true
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(this.$store.state.API_BASE + "cnabremittance/delete/" + cnabRemittance.id, {
              headers: { token: this.$session.get("token") }
            })
            .then(
              result => {
                showNotification("Sucesso", "Registro removido com sucesso", "success");
                this.cnabRemittances.splice(this.cnabRemittances.indexOf(cnabRemittance), 1);
              },
              error => {
                showNotification("Atenção", error.body.message, "danger");
              }
            );
        }
      });
    },
    add() {
      this.$router.push('/cnabremittance/new');
		},	
  }
};
</script>
