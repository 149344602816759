<template>
  <v-container :class="['pa-0', { darkmode: isDarkMode }]" fluid>
    <v-row class="header ma-0 px-8 py-4" justify="end">
      <custom-button @click="openModalsFn()" label="Enviar" />
    </v-row>
    <v-data-table
      class="pa-8"
      show-select
      disable-filtering
      disable-sort
      disable-pagination
      hide-default-footer
      hide-default-header
      :items="items"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <template v-for="group in items">
            <tr class="group-row" :key="group.description">
              <td class="text-left pr-0">
                <div class="m-checkbox-inline">
                  <label
                    class="m-checkbox m-checkbox--success"
                    style="margin-bottom:0px;color:white;"
                  >
                    <input
                      type="checkbox"
                      :checked="selectedGroups.indexOf(group) > -1"
                      @change="checkGroup(group)"
                    />
                    <strong>{{ group.description }}</strong>
                    <span style="height: 15px;width: 15px;background-color: white;"></span>
                  </label>
                </div>
              </td>
              <td class="text-right text-no-wrap pl-0">
                <strong>{{ selectedGroupValue(group.description) }}</strong>
              </td>
              <td><strong>Vencimento</strong></td>
              <td><strong>Portador</strong></td>
              <td><strong>Status</strong></td>
              <td><strong>Ações</strong></td>
            </tr>
            <tr v-for="item in group.items" :key="item.description">
              <td class="text-left pr-0">
                <div class="m-checkbox-inline">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <label
                        :class="[
                          'm-checkbox',
                          'm-checkbox--success',
                          { 'label-disabled': isDisabled(item) },
                        ]"
                        style="margin-bottom:0px;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <input
                          type="checkbox"
                          :checked="isItemSelected(item, group)"
                          :disabled="isDisabled(item)"
                          @change="checkItem(item, group)"
                        />
                        {{ item.fantasyName }} - {{ item.companyName }} - {{ item.prefixCode }} -
                        {{ item.documentNumber }}
                        {{ item.quoteNumber ? ` - ${item.quoteNumber}` : '' }} -
                        {{ item.documentType }}
                        <span style="height: 15px;width: 15px;background-color: white;"></span>
                      </label>
                    </template>
                    <span v-if="billingApproval && item.isLiberated === 'false'">
                      Esse item exige liberação prévia para ser enviado!
                    </span>
                    <span v-else>
                      Razão Social: {{ item.companyName }} - CNPJ:
                      {{ item.registrationNumber | VMask($getConst('MASK_CNPJ')) }}
                    </span>
                  </v-tooltip>
                </div>
              </td>
              <td class="text-right">{{ item.balanceValue | formatNumber }}</td>
              <td>{{ item.fluxDate | formatDate }}</td>
              <td class="text-right">{{ item.bankCode }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="iconValue(item.bankingSituationDescription, 'color')"
                      v-bind="attrs"
                      v-on="on"
                      @click="showOccurrencesModal(item)"
                    >
                      {{ iconValue(item.bankingSituationDescription, 'icon') }}
                    </v-icon>
                    <v-icon
                      class="occurrencesIcon"
                      color="warning"
                      v-if="showOccurrencesIcon(item)"
                      v-bind="attrs"
                      v-on="on"
                      @click="showOccurrencesModal(item)"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ iconValue(item.bankingSituationDescription, 'title') }}</span>
                </v-tooltip>
              </td>
              <td>
                <item-menu
                  :item="item"
                  :showOccurrencesModal="showOccurrencesModal"
                  @showDetailsModal="showDetailsModal(item)"
                />
              </td>
            </tr>
          </template>
          <tr class="group-row">
            <td class="text-left">
              Total Geral ({{ totalItems }} título{{ checkPlural(totalItems) }})
            </td>
            <td class="text-right">{{ totalItemsValue | formatNumber }}</td>
            <td colspan="4"></td>
          </tr>
          <tr class="group-row">
            <td class="text-left">
              Total Selecionado ({{ selectedItems.length }} título{{
                checkPlural(selectedItems.length)
              }})
            </td>
            <td class="text-right">{{ totalSelectedItemsValue | formatNumber }}</td>
            <td colspan="4"></td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <generate-cnab @success="onSuccess" ref="GenerateCnab"></generate-cnab>
    <occurrences-modal
      :show="occurrencesModalVisibility"
      :item="selectedItem"
      @close="closeOccurrencesModal"
    />

    <view-information ref="ViewInformation"></view-information>

    <details-modal :show="detailsModalVisibility" :item="selectedItem" @close="closeDetailsModal" />
  </v-container>
</template>

<script>
/* eslint-disable no-undef */
import Swal from 'sweetalert2';
import CustomButton from '@/components/buttons/CustomButton.vue';
import GenerateCnab from '../../../resources/components/generate-cnab.vue';
import OccurrencesModal from './components/OccurrencesModal.vue';
import DetailsModal from './components/DetailsModal.vue';
import ItemMenu from './components/ItemMenu.vue';
import ViewInformation from '../../../resources/components/view-information.vue';

export default {
  name: 'BillingRemittanceTable',
  components: {
    CustomButton,
    GenerateCnab,
    OccurrencesModal,
    ItemMenu,
    DetailsModal,
    ViewInformation,
  },
  props: {
    items: Array,
    remittanceLimit: Number,
    billingApproval: Boolean,
    initialDate: String,
    finalDate: String,
  },
  data() {
    return {
      header: [
        { text: 'Descrição', value: 'description', align: 'left' },
        { text: 'Valor', value: 'service', align: 'center' },
        { text: 'Portador', value: 'version', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Ações', value: 'actions', align: 'center' },
      ],
      selectedGroups: [],
      selectedItems: [],
      statusIcons: {
        SUCCESS: { icon: 'mdi-check-circle-outline', color: 'green', title: 'Registro confirmado' },
        SUCCESS_WITH_OCCURRENCES: {
          icon: 'mdi-check-circle-outline',
          color: 'green',
          title: 'Registro confirmado',
        },
        ERROR: { icon: 'mdi-close-circle-outline', color: 'red', title: 'Inclusão rejeitada' },
        NOT_SENT: {
          icon: 'mdi-arrow-top-right-thin-circle-outline',
          color: 'yellow',
          title: 'Não Enviado',
        },
        SENT: {
          icon: 'mdi-arrow-top-right-thin-circle-outline',
          color: '',
          title: 'Enviado ao banco',
        },
        CANCELED: { icon: 'mdi-cancel', color: 'red', title: 'Cancelado' },
        PAID: { icon: 'mdi-cash-multiple', color: 'green', title: 'Pago' },
        PAID_WITHOUT_GENERATE_DOCUMENT: {
          icon: 'mdi-cash-multiple',
          color: 'green',
          title: 'Pago sem Gerar Documento',
        },
        UNDEFINED: { icon: 'mdi-alert-outline', color: 'grey', title: 'Inconsistência no título' },
      },
      occurencesIcons: ['SUCCES_WITH_OCCURRENCES', 'PAID_WITHOUT_GENERATE_DOCUMENT'],
      occurrencesModalVisibility: false,
      detailsModalVisibility: false,
      selectedItem: {},
    };
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    totalSelectedItemsValue() {
      return this.selectedItems.reduce((prev, item) => prev + numeral(item.balanceValue), 0);
    },
    totalItems() {
      return this.items.reduce((prev, group) => prev + group.items.length, 0);
    },
    totalItemsValue() {
      return this.items.reduce(
        // eslint-disable-next-line max-len
        (prev, group) => prev + group.items.reduce((itemPrev, item) => itemPrev + numeral(item.balanceValue), 0),
        0,
      );
    },
  },
  methods: {
    showOccurrencesIcon(item) {
      return (
        this.occurencesIcons.indexOf(item.bankingSituationDescription) > -1
      );
    },
    isItemSelected(item, group) {
      return this.selectedItems.some(
        // eslint-disable-next-line max-len
        (selectedItem) => selectedItem.keyId === item.keyId && selectedItem.group === group.description,
      );
    },
    isDisabled(item) {
      const DISABLED_SITUATIONS = [
        'SUCCESS',
        'SUCCESS_WITH_OCCURRENCES',
        'SENT',
        'CANCELED',
        'PAID',
      ];
      if (
        DISABLED_SITUATIONS.indexOf(item.bankingSituationDescription) > -1
        || item.borderoNumber > 0
      ) {
        return true;
      }
      if (this.billingApproval && item.isLiberated === 'false') {
        return true;
      }
      if (item.documentType == 'RA' || item.documentType == 'NCC') {
        return true;
      }
      return false;
    },
    iconColor(item) {
      return item.occurrencesTotal > 0
        ? 'red'
        : this.statusIcons[item.bankingSituationDescription].color;
    },
    checkPlural(length) {
      if (length <= 1) {
        return '';
      }
      return 's';
    },
    iconValue(situation, key) {
      if (this.statusIcons[situation] && this.statusIcons[situation][key]) {
        return this.statusIcons[situation][key];
      }
      return '';
    },
    showLimitAlert(remittanceLimit) {
      Swal.fire({
        title: 'Limite máximo alcançado! ',
        html: `</br>A quantidade estabelecida pelo ERP é de ${remittanceLimit} itens por remessa`,
        width: '800px',
        confirmButtonText: 'Ok',
        allowEscapeKey: true,
        confirmButtonColor: 'rgb(52, 191, 163)',
        customClass: {
            confirmButton: 'receipt-swal-btn',
        },
        focusConfirm: true,
        focusDeny: false,
        showCloseButton: true,
      }).then((result) => {
        if (result.isDismissed == true && (result.dismiss == 'backdrop' || result.dismiss == 'esc')) {
          return false;
        }
        return true;
       });
    },
    checkGroup(group) {
      const index = this.selectedGroups.indexOf(group);
      if (index > -1) {
        this.selectedItems = this.selectedItems.filter((item) => item.group !== group.description);
        this.selectedGroups.splice(index, 1);
        return;
      }

      this.selectedGroups.push(group);
      group.items.every((item) => {
        const itemIndex = this.selectedItems.findIndex(
          (element) => element.keyId === item.keyId && element.group === group.description,
        );
        if (itemIndex <= -1 && !this.isDisabled(item)) {
          if (this.selectedItems.length == this.remittanceLimit && this.remittanceLimit > 0) {
            this.showLimitAlert(this.remittanceLimit);
            return false;
          }
          this.selectedItems.push({ ...item, group: group.description });
        }
        return true;
      });
    },
    checkItem(item, group) {
      const index = this.selectedItems.findIndex(
        (element) => element.keyId === item.keyId && element.group === group.description,
      );

      if (index > -1) {
        const groupIndex = this.selectedGroups.indexOf(group);
        if (groupIndex > -1) {
          this.selectedGroups.splice(groupIndex, 1);
        }

        this.selectedItems.splice(index, 1);
        return;
      }

      if (this.selectedItems.length == this.remittanceLimit && this.remittanceLimit > 0) {
        this.showLimitAlert(this.remittanceLimit);
        return;
      }

      this.selectedItems.push({ ...item, group: group.description });
    },
    selectedGroupValue(groupDescription) {
      const value = this.selectedItems.reduce(
        // eslint-disable-next-line max-len
        (prev, item) => (item.group === groupDescription ? numeral(item.balanceValue) + prev : prev),
        0,
      );
      return numeral(value).format('0,0.00');
    },
    showOccurrencesModal(item) {
      blockPage('Carregando...');
      const data = {
        companyId: item.companyId,
        clientCode: item.clientCode,
        branchCode: item.branchCode,
        storeCode: item.storeCode,
        bankCode: item.bankCode,
        documentNumber: item.documentNumber,
        documentParcel: item.quoteNumber,
        billingMethodType: item.billingMethodType,
        sequentialNumber: item.sequentialNumber,
      };
      this.$http
        .post(`${this.$store.state.API_BASE}bankremittancetransaction/listbydocument`, data, {
          headers: { token: this.$session.get('token') },
        })
        .then(
          (result) => {
            this.selectedItem = { ...item, occurrences: result.body.data || [] };
            this.occurrencesModalVisibility = true;
            unblockPage();
          },
          (error) => {
            this.$helpers.defaultCatchError(error);
            unblockPage();
          },
        );
    },
    closeOccurrencesModal() {
      this.selectedItem = {};
      this.occurrencesModalVisibility = false;
    },
    showDetailsModal(item) {
      this.$refs.ViewInformation.openModal({
        operation: 'viewInformation',
        type: 'CR',
        id: item.sequentialNumber,
        date: item.fluxDate,
      });
    },
    closeDetailsModal() {
      this.selectedItem = {};
      this.detailsModalVisibility = false;
    },
    openModalsFn() {
      const countSelected = this.selectedItems.length;

      if (countSelected > this.remittanceLimit && this.remittanceLimit > 0) {
        this.showLimitAlert(this.remittanceLimit);
        return;
      }

      let totalSelected = 0;
      const cnabGroupId = 0;
      const dataDescriptions = [];
      const totalSaldoDiff = 0;
      const dataKeys = [];
      const dataIds = [];
      const typeCnab = 'CR';
      const companyId = [parseInt($('#company_id').val(), 10)];

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        totalSelected += numeral(element.balanceValue);
        dataDescriptions.push({
          description: element.companyName,
          levelThreeDescription: `${element.prefixCode} - ${element.documentNumber} - ${element.documentType}`,
          lvlDescription: 'REMESSACOB',
          value: numeral(element.balanceValue).format('0,0.00'),
          ID: element.ID,
          cod_bar: element.barCode,
          payment_method: null,
          bank: {
            A2_BANCO: element.banco,
            A2_AGENCIA: element.agencia,
            A2_ZDIGAG: element.digag,
            A2_NUMCON: element.conta,
            A2_ZDIGCTA: element.digcta,
          },
        });
        dataKeys.push(
          JSON.stringify({
            TABLE_COMPANY_CODE: element.companyCode,
            E1_FILIAL: element.branchCode,
            E1_NUM: element.documentNumber,
            E1_PARCELA: element.quoteNumber,
            E1_PREFIXO: element.prefixCode,
            E1_TIPO: element.documentType,
            E1_CLIENTE: element.clientCode,
            E1_LOJA: element.storeCode,
            ID_INNCASH: element.sequentialNumber,
          }),
        );
        dataIds.push(JSON.stringify(element.key_id));
      }

      this.$refs.GenerateCnab.openModal({
        totalSelected,
        cnabGroupId,
        dataDescriptions,
        countSelected,
        totalSaldoDiff,
        companyId,
        dataKeys,
        dataIds,
        typeCnab,
        initialReferenceDate: this.initialDate,
        finalReferenceDate: this.finalDate,
      });
    },
    onSuccess() {
      this.selectedGroups = [];
      this.selectedItems = [];
      this.$emit('success');
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper {
  overflow: unset;
  color: black !important;
}
.v-data-table td {
  vertical-align: top;
  padding-top: 0.65rem !important;
}
.v-data-table td,
.v-data-table label {
  font-size: 14px !important;
  text-align: center;
}
.v-data-table .label-disabled {
  color: grey !important;
  cursor: not-allowed;
}
.v-data-table label {
  text-align: left;
}
.v-data-table th {
  font-size: 1rem !important;
}
.v-data-table tr.group-row {
  background-color: #498cc5 !important;
  color: #fff !important;
  font-weight: 600;
}
.v-data-table tr.group-row label {
  color: #fff !important;
  font-weight: 600;
}
.v-data-table tr.group-row:hover {
  background-color: #498cc5 !important;
  color: #fff !important;
  font-weight: 600;
}
.v-data-table tr.group-row .v-simple-checkbox i {
  background: #fff;
  background-clip: content-box;
  padding: 5px;
}
.v-data-table tr.group-row strong {
  font-size: 1rem;
}
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.occurrencesIcon {
  font-size: 16px !important;
  margin-top: -10px;
  margin-left: -10px;
}
.v-tooltip__content {
  background: #fff;
  color: black;
  opacity: 1 !important;
  box-shadow: 0 0 15px 1px rgb(113 106 202 / 20%);
}
.theme--dark .v-tooltip__content {
  background: #525252 !important;
  color: white;
  box-shadow: none;
}
</style>
