import VueRouter from 'vue-router';
import OldInncashLayout from '@oldinncash/views/app/app.vue';
import NewInncashLayout from '@/components/navigation/NewInncashLayout.vue';

import AppView from '@/components/navigation/AppView.vue';
import Login from '@/views/login/Login.vue';
import TokenHandler from '@/views/tokenHandler/TokenHandler.vue';

import oldInncashRoutes from './old_inncash.routes';
import newInncashRoutes from './new_inncash.routes';

const routes = [
	{
		path: '/',
		component: AppView,
		children: [
			{
				path: '/',
				name: 'home',
				component: OldInncashLayout,
				children: oldInncashRoutes,
			},
			{
				path: '/',
				name: 'new',
				component: NewInncashLayout,
				children: newInncashRoutes,
			},
		],
	},
	{ path: '/login', name: 'login', component: Login },
	{ path: '/logintoken/:auto/:thirdtoken', name: 'logintoken', component: Login },
	{ path: '/:token', name: 'TokenHandler', component: TokenHandler },
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
