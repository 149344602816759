<template>
  <v-dialog v-model="showProviderSelect" max-width="900" persistent>
    <v-card>
      <v-btn icon dark @click="close">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>

      <v-container>
        <v-form ref="form">
          <v-combobox
            label="Selecione o fornecedor"
            @input="update"
            item-value="id"
            :rules="[required]"
            :filter="filter"
            :loading="loading"
            return-object
            :item-text="item => `${item.a2_nome} - (${formatCnpj(item.a2_cgc)})`"
            :items="providers"
          >
          </v-combobox>

          <v-row align="end" justify="end">
            <v-col cols="12" class="text-right">
              <v-btn @click="select" color="primary">Selecionar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Provider",
  props: {
    companyId: Number | String,
    showProviderSelect: Boolean,
    item: Array
  },

  mounted() {
    this.payment = this.item;
  },

  data() {
    return {
      loading: false,
      provider: "",
      providers: [],

      payment: {}
    };
  },
  mounted() {
    this.loadProviders();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    required(value) {
      console.log(value);
      if (value instanceof Array && value.length == 0) {
        return 'Obrigatório.';
      }
      return !!value || 'Obrigatório.';
    },

    loadProviders() {
      this.loading = true;

      this.$http
        .get(this.$store.state.API_BASE + "provider/listAll", {
          headers: {
            token: this.$session.get("token")
          },
          params: {
            search: "",
            company_id: $("#company_id").val() || "",
            limit: 0
          }
        })
        .then(
          result => {
            this.loading = false;
            this.providers = result.body.data;
          },
          error => {
            this.loading = false;
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
          }
        );
    },
    formatCnpj(value) {
      value = value || "";
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    },

    filter(item, queryText, itemText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);

      return (
        item.a2_nome
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },

    select() {

      if (!this.$refs.form.validate()) return;

      const payment = this.item.map(element => {
        element.provider = this.provider;

        return element;
      });

      this.$emit("save", payment);
      this.close();
    },

    update(item) {
      if (!this.$refs.form.validate()) return;

      this.provider = item;
    }
  }
};
</script>

<style scoped>
.update-warning {
  color: #868e96;
  font-size: 1rem;
}
</style>
