import { render, staticRenderFns } from "./CollapseDivTwo.vue?vue&type=template&id=4b44e453&scoped=true&"
import script from "./CollapseDivTwo.vue?vue&type=script&lang=js&"
export * from "./CollapseDivTwo.vue?vue&type=script&lang=js&"
import style0 from "./CollapseDivTwo.vue?vue&type=style&index=0&id=4b44e453&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b44e453",
  null
  
)

export default component.exports